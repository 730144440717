import React from 'react'
import styles from './Settings.module.css'
import Form from './Form/Form'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

const Settings = () => {

    const navigate = useNavigate()
    const user = JSON.parse(localStorage.getItem('profile'))
    const { t } = useTranslation();


    if (!user) {
        navigate('/login')
    }


    return (
        <div className={styles.pageContainer}>

            <section className={styles.hero}>
                <h1 className='text-gray-500'>{t('profileTitle')}</h1>
                <div className={styles.paragraph}>
                    <p>{t('profileDesc')}</p>
                </div>
            </section>
            <section className={styles.stat}>
                <Form user={user} />
            </section>

        </div>
    )
}

export default Settings
