/* eslint-disable */
import React, { useState, useEffect } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { TextField, Grid, Input, InputLabel, Select } from '@mui/material';
import DatePicker from './DatePicker'
import Autocomplete from '@mui/material/Autocomplete';

import { useDispatch } from 'react-redux'
import { updateExpense } from '../../actions/expenseActions';
import { useTranslation } from 'react-i18next';
// import { useSnackbar } from 'react-simple-snackbar/dist';

const styles = (theme) => ({
    root: {
        margin: 0,
        // padding: theme.spacing(2),
        backgroundColor: '#1976D2',
        marginLeft: 0,
    },
    closeButton: {
        position: 'absolute',
        // right: theme.spacing(1),
        // top: theme.spacing(1),
        color: 'white',
    },
});


const DialogTitle = ((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className="" {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className="" onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = (theme) => ({
    root: {
        // padding: theme.spacing(4),
    },
})(MuiDialogContent);

const DialogActions = (theme) => ({
    root: {
        margin: 0,
        // padding: theme.spacing(1),
    },
})(MuiDialogActions);

const ExpenseModal = ({ setOpen, open, expense }) => {

    const { t } = useTranslation();
    // const openSnackbar = useSnackbar

    const dispatch = useDispatch()
    //Create a state to add new payment record
    const [payment, setPayment] = useState({
        amountPaid: 0,
        datePaid: new Date(),
        paymentMethod: '',
        note: '',
        paidBy: ''
    })

    //Material ui datepicker
    const [selectedDate, setSelectedDate] = React.useState(new Date());
    //Crate a state to handle the payment records
    const [paymentRecords, setPaymentRecords] = useState([])
    const [method, setMethod] = useState({})
    const [totalAmountReceived, setTotalAmountReceived] = useState(0)
    const [updatedExpense, setUpdatedExpense] = useState({})


    useEffect(() => {
        setPayment({ ...payment, paymentMethod: method })
    }, [method])

    useEffect(() => {
        setPayment({ ...payment, datePaid: selectedDate })
    }, [selectedDate])

    useEffect(() => {
        if (expense) {
            setPayment({ ...payment, amountPaid: Number(expense.total) - Number(expense.totalAmountReceived), paidBy: expense?.provider?.name })
        }
    }, [expense])

    useEffect(() => {
        if (expense?.paymentRecords) {
            setPaymentRecords(expense?.paymentRecords)

        }
    }, [expense])

    //Get the total amount paid
    useEffect(() => {
        let totalReceived = 0
        for (var i = 0; i < expense?.paymentRecords?.length; i++) {
            totalReceived += Number(expense?.paymentRecords[i]?.amountPaid)
            setTotalAmountReceived(totalReceived)
        }
    }, [expense, payment])



    useEffect(() => {
        setUpdatedExpense({
            ...expense, status: (Number(totalAmountReceived) + Number(payment.amountPaid))
                >=
                expense?.total ? 'Paid' : 'Partial',
            paymentRecords: [...paymentRecords, payment],
            totalAmountReceived: Number(totalAmountReceived) + Number(payment.amountPaid)
        })
    }, [payment, paymentRecords, totalAmountReceived, expense])


    const handleSubmitPayment = (e) => {
        e.preventDefault()
        dispatch(updateExpense(expense._id, updatedExpense))
            .then(() => {
                handleClose()
                window.location.reload()
                // openSnackbar("Compra actualizada con éxito")
            })
        // clear()
    }

    const clear = () => {
    }

    const handleClose = () => {
        setOpen(false);
    };


    const paymentMethods = [
        { name: 'Transferencia Bancaria' },
        { name: 'Efectivo' },
        { name: 'Cuenta Corriente' },
        { name: 'Tarjeta de Crédito' },
        { name: 'Mercado Pago' },
        { name: 'Paypal' },
        { name: 'Otros' },
    ]


    return (
        <div>
            <form >
                <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth >
                    <DialogTitle id="customized-dialog-title" onClose={handleClose} style={{ paddingLeft: '20px', color: 'white' }}>
                        Cargar Pago
                    </DialogTitle>
                    <div style={{ padding: '10px' }}>
                        {/* <DatePicker selectedDate={selectedDate} setSelectedDate={setSelectedDate} /> */}

                        <div style={{ marginLeft: '10px', marginBottom: '20px' }}>
                            <InputLabel>{t('paymentDate')}</InputLabel>
                            <Input
                                type='date'
                                id="date"
                                label={t('dueDate')}
                                value={selectedDate}
                                onChange={async (e) => {
                                    setSelectedDate(e.target.value)
                                }}
                            />
                        </div>

                        <TextField
                            type="number"
                            name="amountPaid"
                            label="Monto Abonado"
                            fullWidth
                            style={{ padding: 10 }}
                            variant="outlined"
                            onChange={(e) => setPayment({ ...payment, amountPaid: e.target.value })}
                            value={payment.amountPaid}
                        />

                        <Grid item fullWidth style={{ marginTop: '10px' }}>
                            <InputLabel style={{ marginLeft: '10px' }}>Método de Pago</InputLabel>
                            <select
                                id="combo-box-demo"
                                style={{ width: '96%', marginLeft: '10px', height: '60px', backgroundColor:'transparent' }}
                                value={method || ''}
                                onChange={(event, value) => {
                                    setMethod(event.target.value)
                                }}
                            >
                                {paymentMethods?.map((method, index) =>
                                    <option key={index} value={method.name} style={{ cursor: 'pointer' }}>
                                        {method.name}
                                    </option>
                                )}
                            </select>
                        </Grid>

                        <TextField
                            type="text"
                            name="note"
                            label="Nota"
                            fullWidth
                            style={{ padding: 10 }}
                            variant="outlined"
                            onChange={(e) => setPayment({ ...payment, note: e.target.value })}
                            value={payment.note}
                        />

                    </div>
                    {/* <DialogActions> */}
                    <Button autoFocus onClick={handleSubmitPayment} variant="contained" style={{ margin: '10px' }} >
                        {t('submitButton')}
                    </Button>
                    {/* </DialogActions> */}
                </Dialog>
            </form>
        </div>
    );
}

export default ExpenseModal