import { combineReducers } from 'redux'

import invoices from './invoices'
import clients from './clients'
import auth from './auth'
import profiles from './profiles'
import providers from './providers'
import stocks from './stocks'
import expenses from './expenses'
import cashboxes from './cashboxes'

export default combineReducers({ invoices, clients, auth, profiles, providers, stocks, expenses, cashboxes })