import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux'
import styles from './Stocks.module.css'
// import moment from 'moment'
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Container from '@mui/material/Container'
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { Button, Input, Select, TextField } from '@mui/material';
// import { useSnackbar } from 'react-simple-snackbar'
import { useTranslation } from 'react-i18next';
import { useNavigate, Link } from 'react-router-dom'

import { deleteStock } from '../../actions/stockActions';
import SpreadSheet from '../Spreadsheet';
import axios from 'axios';
import AddStock from './AddStock';

const useStyles1 = (theme) => ({
  root: {
    flexShrink: 0,
    // marginLeft: theme.spacing(2.5),

  },
});

function TablePaginationActions(props) {

  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};



const useStyles2 = theme => ({
  table: {
    minWidth: 500,
  },

  tablecell: {
    fontSize: '16px'
  }
});


const Stocks = ({ setOpen, setCurrentId }) => {

  const { t } = useTranslation();
  const classes = useStyles2();
  const [page, setPage] = React.useState(0);
  // eslint-disable-next-line 
  // const [openSnackbar, closeSnackbar] = useSnackbar()
  const user = JSON.parse(localStorage.getItem('profile'))
  const paidUser = user?.result?.paid
  const navigate = useNavigate();
  const [percentage, setPercentage] = useState();
  const userId = user?.result?._id

  const dispatch = useDispatch()
  const [newList, setNewList] = useState('');
  const [lists, setLists] = useState([]);
  const [selectedList, setSelectedList] = useState('');
  const [stocks, setStocks] = useState([]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API}/stocklists/${userId}`)
      .then(response => setLists(response.data))
      .catch(error => console.error('Error fetching lists:', error));
  }, []);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API}/stocklists/stocks/${selectedList}`)
      .then(response => setStocks(response.data))
      .catch(error => console.error('Error fetching lists:', error));
  }, [selectedList])

  const rows = stocks
  const [rowsPerPage, setRowsPerPage] = useState(stocks.length);
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows?.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const handleEdit = (selectedInvoice) => {
    setOpen((prevState) => !prevState)
    setCurrentId(selectedInvoice)
  }

  if (!user) {
    navigate('/login')
  }


  if (user && paidUser === 'none') {
    navigate('/my-plan')
  }


  const tableStyle = { width: 160, fontSize: 14, cursor: 'pointer', borderBottom: 'none', padding: '8px', textAlign: 'center' }
  const headerStyle = { borderBottom: 'none', textAlign: 'center' }

  const bulkUpdatePrices = () => {
    axios.patch(`${process.env.REACT_APP_API}/stocks`, { percentage, userId, selectedList })
      .then(response => {
        // openSnackbar('Precios actualizados con éxito');
        window.location.reload()
        // Optionally, you might want to refetch the data to show updated prices
      })
      .catch(error => console.error('Error actualizando precios:', error));
  };

  const addNewList = () => {
    axios.post(`${process.env.REACT_APP_API}/stocklists`, { name: newList, userId })
      .then(response => {
        // openSnackbar('Lista creada con éxito');
        window.location.reload()
      })
      .catch(error => console.error('Error creando lista:', error));
  }

  const deleteList = () => {
    axios.delete(`${process.env.REACT_APP_API}/stocklists/${selectedList}`)
      .then(response => {
        // openSnackbar('Lista eliminada con éxito');
        window.location.reload()
      })
      .catch(error => console.error('Error eliminando lista:', error));
  }


  return (
    <div className={styles.pageLayout} style={{ marginBottom: '100px' }}>
      <Container style={{ width: '85%' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h2 style={{ marginBottom: '10px' }} className='text-gray-600 font-bold text-xl'>Listas de Precios</h2>
          {/* <button className={styles.addButton}>+ Agregar Stock</button>  */}
        </div>

        <div style={{ display: 'flex', marginBottom: '40px', marginTop: '20px' }}>
          <Input onChange={(e) => setNewList(e.target.value)} placeholder='Nombre...' value={newList || ''} style={{ marginLeft: '5px', marginTop: '-10px' }} />
          <button onClick={addNewList} className={styles.button} style={{ marginBottom: '-2px', marginLeft: '5px' }}>Crear Lista</button>
        </div>

        <div style={{ display: 'flex', marginBottom: '20px', justifyContent: 'space-between' }}>
          <div>
            <p value="" disabled style={{ marginBottom: '10px' }} className='text-gray-600 font-bold text-md'>Selecciona una lista existente</p>
            <select className='bg-white text-black' onChange={(e) => {
              setSelectedList(e.target.value)
              localStorage.setItem('listId', e.target.value)
            }}
              value={selectedList || ''}
              style={{ marginLeft: '5px', marginTop: '-10px' }}
            >
              {lists?.map(list => (
                <option key={list._id} value={list._id} style={{ cursor: 'pointer' }}>{list.name}</option>
              ))}
            </select>
          </div>
          <div style={{ display: 'flex', marginBottom: '25px' }}>
            <Input
              type="number"
              placeholder="Incremento %"
              value={percentage}
              onChange={(e) => setPercentage(parseFloat(e.target.value))}
              style={{ marginRight: '10px' }}
            />
            <button onClick={bulkUpdatePrices} className={styles.button}>Aplicar Incremento</button>
            <button onClick={deleteList} className={styles.button} style={{ marginLeft: '10px' }}>Eliminar Lista</button>
          </div>
        </div>
        <TableContainer component={Paper} elevation={0}>
          <Table className={classes.table} aria-label="custom pagination table">

            <TableHead>
              <TableRow>
                <TableCell style={{ ...headerStyle, width: '10px' }}>{t('number')}</TableCell>
                <TableCell style={headerStyle}>Código</TableCell>
                <TableCell style={headerStyle}>{t('name')}</TableCell>
                <TableCell style={headerStyle}>{t('details')}</TableCell>
                <TableCell style={headerStyle}>{t('provider')}</TableCell>
                <TableCell style={headerStyle}>{t('price')}</TableCell>
                <TableCell style={headerStyle}>{t('quantity')}</TableCell>
                <TableCell style={headerStyle}>{t('edit')}</TableCell>
                <TableCell style={headerStyle}>{t('delete')}</TableCell>

              </TableRow>
            </TableHead>

            <TableBody>
              {(rowsPerPage > 0
                ? rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : rows
              ).map((row, index) => (
                <TableRow key={row._id} styel={{ cursor: 'pointer' }} >
                  <TableCell style={{ ...tableStyle, width: '10px' }}>{index + 1}</TableCell>
                  <TableCell style={tableStyle}>{row.code}</TableCell>
                  <TableCell style={tableStyle} scope="row" > <Button style={{ textTransform: 'none' }}  > {row.name} </Button></TableCell>
                  <TableCell style={tableStyle}>{row.details}</TableCell>
                  <TableCell style={tableStyle}>{row.provider}</TableCell>
                  <TableCell style={tableStyle}>$ {row.price}</TableCell>
                  <TableCell style={tableStyle}>{row.quantity}</TableCell>
                  <TableCell style={{ ...tableStyle, width: '10px' }}>
                    <IconButton onClick={() => handleEdit(row._id)}>
                      <BorderColorIcon style={{ width: '20px', height: '20px' }} />
                    </IconButton>
                  </TableCell>
                  <TableCell style={{ ...tableStyle, width: '10px' }}>
                    <IconButton onClick={() => {
                      dispatch(deleteStock(row._id))
                      window.location.reload()
                    }}
                    >
                      <DeleteOutlineRoundedIcon style={{ width: '20px', height: '20px' }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                  colSpan={6}
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
        <SpreadSheet selectedList={selectedList} />
      </Container>
    </div>
  );
}

export default Stocks