import React from 'react'

const FAQs = () => {
    return (
        <div className="px-8 py-10 mx-auto md:px-12 lg:px-32 max-w-7xl text-gray-600">
            <div className="grid grid-cols-1 gap-6 lg:gap-12 lg:grid-cols-2">
                <div className="text-center lg:text-left">
                    <div>
                        <p className="text-4xl font-semibold tracking-tighter text-gray-900">
                            Preguntas Frecuentes
                        </p>
                        <p className="mt-4 text-base font-medium text-gray-500 text-pretty italic">
                            Respuestas a preguntas comunes sobre la plataforma
                        </p>
                    </div>
                </div>
                <div className='w-full lg:col-span-2'>
                    {faqs?.map((faq, index) => {
                        return (
                            <div key={index} className="collapse collapse-arrow join-item border-base-300 border italic">
                                <input type="radio" name="my-accordion-4" />
                                <div className="collapse-title text-xl font-medium">{faq.question}</div>
                                <div className="collapse-content">
                                    <p>{faq.answer}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

const faqs = [
    {
        question: 'Si registro mis transacciones en la plataforma, ¿estoy obligado a facturarlas?',
        answer: 'No. Las transacciones registradas en la plataforma no tienen relación con tus facturaciones y/o cuestiones tributarias.'
    },
    {
        question: '¿Mis transacciones registradas en la plataforma son de carácter público?',
        answer: 'No, son privadas. Ningún organismo público o tercero tienen acceso a los datos de tu negocio/empresa.'
    },
    {
        question: 'Todavía no finalizó mi período de prueba gratis (14 días) y no me convenció el producto. ¿Qué hago?',
        answer: 'Solamente tenés que cancelar tu suscripción para que no se efectúe ningún cobro.'
    },
    {
        question: 'Finalizó mi período de prueba gratis (14 días) y no me convenció el producto. ¿Ahora qué?',
        answer: 'Contactate con nosotros y te reembolsamos el valor total del pago. Tené en cuenta que el reclamo deberá ser durante las 24 horas posteriores a la finalización del período de prueba gratis, de otra manera será considerado inválido.'
    },
    {
        question: 'Tengo mis listas de precios en formato Excel (.xslx, .csv). ¿Qué hago?',
        answer: 'Seguí las instrucciones en la aplicación y las vas a poder subir/importar sin problemas.'
    },
]

export default FAQs