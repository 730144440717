/* eslint-disable */
import React, { useState, useEffect } from 'react'
import Stocks from './Stocks'
import AddStock from './AddStock'
import { getStocksByUser } from '../../actions/stockActions'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import NoData from '../svgIcons/NoData'
import Spinner from '../Spinner/Spinner'
import axios from 'axios'


const StockList = () => {

  const navigate = useNavigate()
  const location = useLocation()
  const [open, setOpen] = useState(false)
  const [currentId, setCurrentId] = useState(null)
  const dispatch = useDispatch()
  const user = JSON.parse(localStorage.getItem('profile'))
  const { stocks } = useSelector((state) => state.stocks)
  const isLoading = useSelector(state => state.stocks.isLoading)
  const listId = localStorage.getItem('listId')

  useEffect(() => {
    dispatch(getStocksByUser({ search: user?.result?._id || user.result.googleId }));
  }, [location, dispatch])

  if (!user) {
    navigate('/login')
  }


  if (isLoading) {
    return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', paddingTop: '20px' }}>
      <Spinner />
    </div>
  }

  if (stocks.length === 0) {
    return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', paddingTop: '20px', margin: '80px' }}>
      <NoData />
      <p style={{ padding: '40px', color: 'gray', textAlign: 'center' }}>No hay stock todavía. Por favor carga productos para poder verlos.</p>

    </div>
  }

  return (
    <div>
      <AddStock
        openStockForm={open}
        setOpenStockForm={setOpen}
        currentId={currentId}
        setCurrentId={setCurrentId}
        selectedList={listId}
      />
      <Stocks
        open={open}
        setOpen={setOpen}
        currentId={currentId}
        setCurrentId={setCurrentId}
      />
    </div>
  )
}

export default StockList