import React, { useEffect, useState } from 'react'
import styles from './Home.module.css'
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Pricing from '../Pricing';
import ProblemSection from './problem';
import SolutionSection from './solution';
import { Features } from './features.tsx';
import FAQs from './faqs.js';
import { FooterCta } from './FooterCta/index.tsx';
import { AvatarCirclesDemo } from './HeroSocialProof/index.js';
import Prices from '../Pricing/newPricing.js';

const Home = () => {

    const { t, i18n } = useTranslation();
    const location = useLocation()
    const lngs = {
        en: { nativeName: 'English' },
        es: { nativeName: 'Spanish' }
    };
    const navigate = useNavigate();

    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')))

    useEffect(() => {
        setUser(JSON.parse(localStorage.getItem('profile')))
    }, [location])

    if (user) {
        navigate('/dashboard')
    }

    return (
        <div className={styles.pageContainer}>

            <section className={styles.hero}>
                {/* <h1>Easiest invoicing for freelancers and small businesses</h1> */}
                <h1 style={{ marginTop: '20px' }} className='text-2xl font-bold'>{t('hero')}</h1>
                <div className={styles.paragraph}>
                    <p className="text-lg italic">{t('herospan')}</p>
                </div>
                <div className={styles.imgContainer}>
                    <img src="/hero-img1.png" alt="invoicing-app" height="400px" />
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <button onClick={() => navigate('/login')} className="btn btn-wide text-lg text-white rounded-lg">{t('heroCta')}</button>
                </div>
            </section>

            <div className='flex justify-center mb-10'>
                <AvatarCirclesDemo />
            </div>

            <div style={{ backgroundColor: '#F6ECE9' }}>
                <ProblemSection />
            </div>

            <div>
                <SolutionSection />
                {/* <div style={{border:'2px solid black', borderRadius:'10px', margin:'auto', width:'1000px'}}>
                    <img src="/dashboard.png" height="500px" width="900px" alt="dashboard-image" />
                </div> */}
            </div>

            <div >
                <Features />
            </div>

            <div style={{ backgroundColor: '#F6ECE9' }}>
                {/* <h1 style={{ textAlign: 'center', paddingTop: '80px' }} className='text-3xl font-bold text-gray-700'>Planes</h1>
                <Pricing /> */}
                <Prices />
            </div>

            <div className='flex justify-center'>
                <FAQs />
            </div>

            <div style={{ backgroundColor: '#F6ECE9', color: 'black' }}>
                <FooterCta />
            </div>

        </div>
    )
}

export default Home
