import React, { useEffect, useState } from 'react';
import styles from '../Dashboard.module.css'
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment'
import { Container, List, ListItem, ListItemText, ListItemAvatar, Avatar, Typography, Divider } from '@mui/material';
import { toCommas } from '../../../utils/utils';
import { Bag, Check } from '../Icons';

const MercadoPagoTab = () => {
    const location = useLocation();
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
    const [txs, setTxs] = useState([]);
    const [expenseTxs, setExpenseTxs] = useState([]);

    useEffect(() => {
        setUser(JSON.parse(localStorage.getItem('profile')));
    }, [location]);

    const userToken = user?.result?.mpAccessToken;

    useEffect(() => {
        const fetchTransactions = async () => {
            try {
                const response = await axios.get('https://api.mercadopago.com/v1/payments/search?criteria=desc&collector.id=me', {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                });
                // console.log(response.data);
                setTxs(response.data.results); // Assuming 'results' is the array you need
            } catch (error) {
                console.error('Error getting transactions:', error);
            }
        };

        const fetchExpenseTransactions = async () => {
            try {
                const response = await axios.get('https://api.mercadopago.com/v1/payments/search?criteria=desc&payer.id=me', {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                });
                // console.log(response.data);
                setExpenseTxs(response.data.results); // Assuming 'results' is the array you need
            } catch (error) {
                console.error('Error getting transactions:', error);
            }
        };
        fetchExpenseTransactions();
        fetchTransactions();
    }, [userToken]); // Add userToken as a dependency

    const filteredTxs = txs.filter(tx => tx.payer.email)

    let totalAmount = 0
    for (let i = 0; i < txs.length; i++) {
        totalAmount += txs[i].transaction_amount
    }

    let totalExpenses = 0
    for (let i = 0; i < expenseTxs.length; i++) {
        totalExpenses += expenseTxs[i].transaction_amount
    }

    return (
        <Container>
            <section className='text-gray-500 mt-10'>
                <ul className={styles.autoGrid}>
                    <li className={styles.listItem} style={{ backgroundColor: '#1976d2', color: 'white' }}>
                        <div>
                            <p>$ {toCommas(totalAmount.toFixed(2))}</p>
                            <h2 style={{ color: 'white' }}>Total Recibido</h2>
                        </div>
                        <div>
                            <Check />
                        </div>
                    </li>

                    <li className={styles.listItem} >
                        <div>
                            <p>$ {toCommas(totalExpenses.toFixed(2))}</p>
                            <h2>Total Gastado</h2>
                        </div>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#EA3323"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M7 11v2h10v-2H7zm5-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" /></svg>
                        </div>
                    </li>
                </ul>
            </section>

            <section className='p-10'>
                <h2 className='text-center text-gray-600 text-lg font-bold'>Ventas (últimas 30 transacciones)</h2>
                <List>
                    {filteredTxs.map((tx, index) => (
                        <React.Fragment key={index}>
                            <ListItem alignItems="flex-start" className="flex align-center justify-between">
                                <ListItemAvatar>
                                    <Avatar className='uppercase' style={{ backgroundColor: '#1976D2' }}>
                                        {tx?.payer?.email.charAt(0)}
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={tx?.payer?.email}
                                    secondary={
                                        <React.Fragment>
                                            <Typography
                                                component="span"
                                                variant="body2"
                                                className=""
                                                color="textPrimary"
                                            >
                                                {tx?.point_of_interaction?.type === 'SUBSCRIPTIONS' ? 'Suscripción' : 'Transferencia Recibida'}
                                            </Typography>
                                        </React.Fragment>
                                    }
                                />
                                <div>
                                    <Typography variant="body2" className="text-green-600">
                                        + ${tx.transaction_amount.toFixed(2)}
                                    </Typography>
                                    <Typography variant="caption" className="">
                                        {moment(tx.date_created).utc().format('DD/MM/YYYY')}
                                    </Typography>
                                </div>
                            </ListItem>
                            {index < txs.length - 1 && <Divider variant="inset" component="li" />}
                        </React.Fragment>
                    ))}
                </List>
            </section>
        </Container>
    );
};

export default MercadoPagoTab;