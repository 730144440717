import React from 'react'
import ChatBot from 'react-chatbotify'
import OpenAI from "openai";
import axios from 'axios';

const ChatBotWindow = () => {

    let apiKey = 'sk-proj-AMZFwVV7clVOmKT2sPSiT3BlbkFJkCfTrhBkxrujXge57vPp'
    let modelType = "gpt-4o-mini";
    let hasError = false;
    const tavilyApiKey = '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'

    const call_openai = async (params) => {
        try {
            const openai = new OpenAI({
                apiKey: apiKey,
                dangerouslyAllowBrowser: true // required for testing on browser side, not recommended
            });

            const chatCompletion = await openai.chat.completions.create({
                // conversation history is not shown in this example as message length is kept to 1
                messages: [{ role: 'user', content: params.userInput }],
                model: modelType,
            });

            await params.injectMessage(chatCompletion.choices[0].message.content);
        } catch (error) {
            await params.injectMessage("Hubo un error. Intentalo de nuevo.");
            hasError = true;
        }
    }

    const call_tavily = async (params) => {
        try {
            const response = await axios.post('https://api.tavily.com/search', {
                api_key: tavilyApiKey,
                query: params.userInput,
                search_depth: 'advanced'
            });

            const rawResults = response.data.results.map(result => result.content).join('\n');
            const links = response.data.results.map(result => result.url).join('\n');
            const rawData = [rawResults, links]
            // Process the raw results with OpenAI
            const processedResults = await process_with_openai(rawData);

            await params.injectMessage(processedResults);
        } catch (error) {
            await params.injectMessage('Hubo un error con la búsqueda. Intentalo de nuevo.');
            hasError = true;
        }
    };

    const process_with_openai = async (rawText) => {
        try {
            const openai = new OpenAI({
                apiKey: apiKey,
                dangerouslyAllowBrowser: true
            });

            const completion = await openai.chat.completions.create({
                messages: [{
                    role: 'user',
                    content: `Please organize and clarify the following information:\n\n${rawText}. Do not leave anything unexplained or out.
                    Do not leave links or urls out, list them at the end of the response.`
                }],
                model: modelType,
            });

            return completion.choices[0].message.content;
        } catch (error) {
            return 'Hubo un error al procesar la información. Intentalo de nuevo.';
        }
    };

    const settings = {
        header: {
            title: 'Buk Bot'
        },
        tooltip: {
            mode: 'off',
            text: ''
        },
        chatButton: {
            icon: '/logo-no-bg.png'
        },
        chatInput: {
            enabledPlaceholderText: 'Escribe algo...'
        },
        chatHistory: {
            disabled: true
        }
    }

    const flow = {
        start: {
            message: 'Hola, ¿qué necesitas hoy? Para una consulta en tiempo real escribe "buscar: ...". Recuerda que mientras más específico seas, mejor. ',
            path: "loop",
            isSensitive: false
        },
        loop: {
            message: async (params) => {
                if (params.userInput.startsWith('buscar: ') || params.userInput.startsWith('buscar:')) {
                    await call_tavily(params);
                } else {
                    await call_openai(params);
                }
            },
            path: () => {
                if (hasError) {
                    return "start"
                }
                return "loop"
            }
        }
    }

    return (
        <div className='relative' id='chatbot-button'>
            <ChatBot flow={flow} settings={settings} />
        </div>
    )
}

export default ChatBotWindow