
import { ALL_PROVIDERS, ADD_NEW_PROVIDER, UPDATE_PROVIDER, DELETE_PROVIDER, FETCH_PROVIDERS_BY_USER, FETCH_PROVIDER, START_LOADING, END_LOADING } from '../actions/constants'

const providers = (state = { isLoading: true, providers: [] }, action) => {
    switch (action.type) {
      case START_LOADING:
        return { ...state, isLoading: true };
      case END_LOADING:
        return { ...state, isLoading: false };
      case ALL_PROVIDERS:
        return {
          ...state,
          providers: action.payload.data,
          currentPage: action.payload.currentPage,
          numberOfPages: action.payload.numberOfPages,
        };
      case FETCH_PROVIDERS_BY_USER:
        return { ...state, providers: action.payload };

      case FETCH_PROVIDER:
        return { ...state, provider: action.payload.provider };
      case ADD_NEW_PROVIDER:
        return { ...state, providers: [...state.providers, action.payload] };
      case UPDATE_PROVIDER:
        return { ...state, providers: state.providers.map((provider) => (provider._id === action.payload._id ? action.payload : provider)) };
      case DELETE_PROVIDER:
        return { ...state, providers: state.providers.filter((provider) => provider._id !== action.payload) };
      default:
        return state;
    }
  };

  export default providers