import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import styles from './Footer.module.css'
import FabButton from '../Fab/Fab'
import ChatBotWindow from '../Chatbot'

const Footer = () => {
    const location = useLocation()
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')))
    const paidUser = user && user.result.paid

    useEffect(() => {
        setUser(JSON.parse(localStorage.getItem('profile')))
    }, [location])

    return (
        <footer className='mt-60'>
            <div className={styles.footerText}>
                <span><a className={styles.link} href="https://pixelware.pro" target="_blank" rel="noopener noreferrer">by Pixelware Pro</a></span>
            </div>
            <div className='flex'>
                {user && paidUser === 'business' && (
                    <div className='flex-row'>
                        <div >
                            <FabButton />
                        </div>
                        <div>
                            <ChatBotWindow />
                        </div>
                    </div>
                )}
            </div>
        </footer>
    )
}

export default Footer
