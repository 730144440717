import * as api from '../api/index'
import { AUTH, CREATE_PROFILE } from './constants'

const user = JSON.parse(localStorage.getItem('profile'))
const paidUser = user?.result?.paid

export const signin = (formData, toast, setLoading, navigate) => async (dispatch) => {

    try {
        //login the user
        const { data } = await api.signIn(formData)
        dispatch({ type: AUTH, data })
        // setLoading(false)
        toast(`Bienvenido`)
        navigate("/dashboard")
        // if (user && paidUser !== 'none') window.location.href = "/dashboard"
        // if (paidUser === 'none') window.location.href = "/my-plan"

    } catch (error) {
        if (error?.response?.data?.message) {
            // console.log(error?.response?.data?.message)
            toast('Usuario o contraseña inválidos')
        }
        setLoading(false)
    }
}

export const signup = (formData, openSnackbar, setLoading) => async (dispatch) => {

    try {
        //Sign up the user
        const { data } = await api.signUp(formData)
        dispatch({ type: AUTH, data })
        const { info } = await api.createProfile({ name: data?.result?.name, email: data?.result?.email, userId: data?.result?._id, phoneNumber: '', businessName: '', contactAddress: '', logo: '', website: '' });
        dispatch({ type: CREATE_PROFILE, payload: info });
        window.location.href = "/my-plan"
        openSnackbar("Te registraste con éxito")

    } catch (error) {
        console.log(error)
        openSnackbar(error?.response?.data?.message)
        setLoading(false)
    }
}



export const forgot = (formData) => async (dispatch) => {
    try {
        await api.forgot(formData)
    } catch (error) {
        console.log(error)
    }
}


export const reset = (formData, navigate, openSnackbar) => async (dispatch) => {

    try {
        await api.reset(formData)
        openSnackbar("Cambiaste tu contraseña con éxito")
        navigate('/dashboard')

    } catch (error) {
        alert(error)
    }
}
