import { Container } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { closeCashbox, getCashboxesByUser } from '../../../actions/cashboxActions';
import { toCommas } from '../../../utils/utils'
import { getInvoicesByUser } from '../../../actions/invoiceActions';

const CashBoxes = () => {
    const user = JSON.parse(localStorage.getItem('profile'));
    const location = useLocation()
    const [open, setOpen] = useState(false)
    const [currentId, setCurrentId] = useState(null)
    const dispatch = useDispatch()
    const { cashboxes, isLoading } = useSelector((state) => state.cashboxes)
    const [currentCashBox, setCurrentCashBox] = useState(null);

    useEffect(() => {
        dispatch(getCashboxesByUser({ search: user?.result?._id || user.result.googleId }));
    }, [location, dispatch])

    const invoices = useSelector(state => state.invoices.invoices)

    // useEffect(() => {
    //   dispatch(getInvoicesByUser({ search: user?.result?._id || user?.result?.googleId }));
    //   // eslint-disable-next-line
    // }, [location])

    const handleCloseCashBox = async (id) => {
        const closedAt = new Date();
        const closeDate = closedAt.toISOString();
        await dispatch(closeCashbox(id, closeDate));
    };

    // const handleDeleteCashBox = async () => {
    //     if (currentCashBox) {
    //       await dispatch(closeCashbox(currentCashBox._id));
    //       setCashBoxes(cashBoxes.filter((box) => box._id !== currentCashBox._id));
    //       setCurrentCashBox(null);
    //     }
    // };

    const cashBoxInvoices = currentCashBox?._id && invoices.filter(invoice => invoice.cashBoxId === currentCashBox?._id)

    let totalSalesAmount = 0;
    for (let i = 0; i < cashBoxInvoices?.length; ++i) {
      const amount = cashBoxInvoices[i].total
      totalSalesAmount += amount
    }

    return (
        <Container>
            <div className="text-gray-600 p-6 rounded-lg">
                <div className="flex justify-between">
                    <h1 className="font-bold text-2xl mb-6">Caja</h1>
                    <div></div>
                </div>
                <div className="mb-6 mt-10">
                    <div className='flex justify-between'>
                        <div className="flex items-center mb-2">
                            <h2 className="text-4xl mr-4">${currentCashBox ? toCommas(totalSalesAmount + currentCashBox.initialAmount + currentCashBox.incomes - currentCashBox.withdraws) : '0,00'}</h2>
                        </div>
                        <div></div>
                    </div>
                    <p>Efectivo de apertura: ${currentCashBox ? toCommas(currentCashBox.initialAmount) : '0,00'}</p>
                    <p>Fecha de apertura: {currentCashBox ? new Date(currentCashBox.createdAt).toLocaleString() : ''}</p>
                    <p>Cajero: {currentCashBox ? currentCashBox.cashier : 'Tu'}</p>
                </div>
                <div className="grid grid-cols-5 gap-4">
                    <div className="bg-gray-300 p-4 rounded-lg text-center">
                        <h3 className="mb-2">VENTAS</h3>
                        <p className="text-2xl">{currentCashBox ? cashBoxInvoices?.length : '0'}</p>
                    </div>
                    <div className="bg-gray-300 p-4 rounded-lg text-center">
                        <h3 className="mb-2">TOTAL</h3>
                        <p className="text-2xl">${currentCashBox ? toCommas(totalSalesAmount + currentCashBox.initialAmount + currentCashBox.incomes - currentCashBox.withdraws) : '0,00'}</p>
                    </div>
                    <div className="bg-gray-300 p-4 rounded-lg text-center">
                        <h3 className="mb-2">COBRADO TOTAL</h3>
                        <p className="text-2xl">${currentCashBox ? toCommas(totalSalesAmount) : '0,00'}</p>
                    </div>
                    <div className="bg-gray-300 p-4 rounded-lg text-center">
                        <h3 className="mb-2">INGRESOS</h3>
                        <p className="text-2xl">${currentCashBox ? toCommas(currentCashBox.incomes) : '0,00'}</p>
                    </div>
                    <div className="bg-gray-300 p-4 rounded-lg text-center">
                        <h3 className="mb-2">RETIROS</h3>
                        <p className="text-2xl">${currentCashBox ? toCommas(currentCashBox.withdraws) : '0,00'}</p>
                    </div>
                    {/* <div className="bg-gray-300 p-4 rounded-lg text-center">
                        <h3 className="mb-2">EFECTIVO</h3>
                        <p className="text-2xl">$0,00</p>
                    </div>
                    <div className="bg-gray-300 p-4 rounded-lg text-center">
                        <h3 className="mb-2">MERCADO PAGO</h3>
                        <p className="text-2xl">$0,00</p>
                    </div>
                    <div className="bg-gray-300 p-4 rounded-lg text-center">
                        <h3 className="mb-2">OTROS MÉTODOS</h3>
                        <p className="text-2xl">$0,00</p>
                    </div> */}
                </div>

                <section className='mt-20'>
                    <h1 className="font-bold text-2xl mb-6">Historial</h1>
                    <div className="overflow-x-auto">
                        <table className="min-w-full bg-gray-300 text-gray-600">
                            <thead>
                                <tr>
                                    <th className="py-2 px-4 border-b border-gray-600">ID</th>
                                    <th className="py-2 px-4 border-b border-gray-600">Cajero</th>
                                    <th className="py-2 px-4 border-b border-gray-600">Apertura</th>
                                    <th className="py-2 px-4 border-b border-gray-600">Cierre</th>
                                    {/* <th className="py-2 px-4 border-b border-gray-600">Ventas</th>
                                    <th className="py-2 px-4 border-b border-gray-600">Total</th> */}
                                    <th className="py-2 px-4 border-b border-gray-600">Estado</th>
                                    <th className="py-2 px-4 border-b border-gray-600">Ver</th>
                                </tr>
                            </thead>
                            <tbody>
                                {cashboxes.length > 0 && cashboxes.map((box) => (
                                    <tr key={box._id}>
                                        <td className="py-2 px-4 border-b border-gray-600">{box?._id}</td>
                                        <td className="py-2 px-4 border-b border-gray-600">{box?.cashier}</td>
                                        <td className="py-2 px-4 border-b border-gray-600">{new Date(box?.createdAt).toLocaleString()}</td>
                                        <td className="py-2 px-4 border-b border-gray-600">{box?.closedAt ? new Date(box.closedAt).toLocaleString() : <div className='text-center'><span>-</span></div>}</td>
                                        {/* <td className="py-2 px-4 border-b border-gray-600">{box?.sales}</td>
                                        <td className="py-2 px-4 border-b border-gray-600">${box?.totalAmount?.toFixed(2)}</td> */}
                                        <td className="py-2 px-4 border-b border-gray-600">{box?.closed ? 'Cerrada' : 'Abierta'}</td>
                                        <td className="py-2 px-4 border-b border-gray-600">
                                            <button className="bg-gray-200 border text-gray-500 px-4 py-2 rounded-md" onClick={() => {
                                                setCurrentCashBox(box)
                                            }}>Ver</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </section>
            </div>
        </Container>
    );
};

export default CashBoxes;