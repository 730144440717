import AvatarCircles from "./avatarCircles.tsx";

const avatarUrls = [
    "https://avatars.githubusercontent.com/u/16860528",
    "https://avatars.githubusercontent.com/u/20110627",
    "/people2.jpg",
    "/people1.jpg",
];

export function AvatarCirclesDemo() {
    return (
        <div className='flex'>
            <AvatarCircles numPeople={10} avatarUrls={avatarUrls} />;
            <span className='text-gray-600 text-sm ml-2'><i>clientes confían <br /> en nosotros</i></span>
        </div>
    )
}
