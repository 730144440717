import React from "react";
import { useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { TypewriterEffect } from "./TypeWriter.tsx";

export function FooterCta() {

  const { t, i18n } = useTranslation();
  const navigate = useNavigate()

  const words = [
    {
      text: t('firstP'),
      className: "text-black"
    },
    {
      text: t('secondP'),
      className: "text-black"
    },
    {
      text: t('thirdP'),
      className: "text-black"
    },
    {
      text: t('fourthP'),
      className: "text-black"
    },
    {
      text: t('fifthP'),
      className: "text-blue-500 dark:text-blue-500",
    },
        {
      text: t('sixthP'),
      className: "text-blue-500 dark:text-blue-500",
    },
    {
      text: t('seventhP'),
      className: "text-blue-500 dark:text-blue-500",
    },
    {
      text: t('eigthP'),
      className: "text-blue-500 dark:text-blue-500",
    },
  ];
  return (
    <div className="flex flex-col items-center justify-center h-[40rem] mb-[-240px]">
      <p className="text-gray-800 text-2xl font-bold mb-10 italic">
        {t('footerP')}
      </p>
      <TypewriterEffect words={words} />
      <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 space-x-0 md:space-x-4 mt-10">
        <button onClick={() => navigate('/login')} className="w-80 h-16 rounded-xl bg-black hover:bg-gray-800 border dark:border-white border-transparent text-white font-bold text-2xl">
          {t('heroCta')}
        </button>
        {/* <a href="mailto:contact@pixelware.pro" className="w-40 px-8 py-2 h-10 rounded-xl bg-white font-bold text-black border border-black text-md">
          {t('contactUs')}
        </a> */}
      </div>
    </div>
  );
}
