import { Container } from '@mui/material'
import axios from 'axios'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

const ProPlan = () => {
    const user = JSON.parse(localStorage.getItem('profile'));
    const userId = user?.result?._id;
    const proPlan = 'pro';
    const navigate = useNavigate();

    if (!user) {
        navigate('/login')
    }

    useEffect(() => {
        if (user) {
            const response = axios.post(`${process.env.REACT_APP_API}/users/plan/pro`, { proPlan: proPlan, id: userId })
                .then(() => {
                    // openSnackbar('Stock agregado con éxito')
                    console.log(response)
                })
                .catch(error => {
                    // openSnackbar('Hubo un error. Por favor revisa los productos e intenta de nuevo.')
                    console.error('Error actualizando el plan:', error)
                })
        }
    }, [])

    return (
        <Container className='w-full h-[100vh]'>
            <div className='flex flex-column text-gray-700'>
                <div className='p-48 m-auto'>
                    <h1 className='mb-2'>¡Gracias por suscribirte! Te recomendamos cerrar tu sesión e iniciar sesión de nuevo para ver los cambios. </h1>
                    <p className='text-center font-bold'>Ir al <Link to="/dashboard" className='text-blue-600'>dashboard</Link></p>
                </div>
            </div>
        </Container>
    )
}

export default ProPlan