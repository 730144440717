/* eslint-disable */
import React, { useState, useEffect } from 'react'
import Providers from './Providers'
import AddProvider from './AddProvider'
import { getProvidersByUser } from '../../actions/providersActions'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import NoData from '../svgIcons/NoData'
import Spinner from '../Spinner/Spinner'


const ProviderList = () => {

    const navigate = useNavigate()
    const location = useLocation()
    const [open, setOpen] = useState(false)
    const [currentId, setCurrentId] = useState(null)
    const dispatch = useDispatch()
    const user = JSON.parse(localStorage.getItem('profile'))
    const { providers } = useSelector((state) => state.providers)
    const isLoading = useSelector(state => state.providers.isLoading)

    useEffect(() => {
        dispatch(getProvidersByUser({ search: user?.result?._id || user.result.googleId }));
    }, [location, dispatch])

    if (!user) {
        navigate('/login')
    }

    if (isLoading) {
        return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', paddingTop: '20px' }}>
            <Spinner />
        </div>
    }

    if (providers.length === 0) {
        return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', paddingTop: '20px', margin: '80px' }}>
            <NoData />
            <p style={{ padding: '40px', color: 'gray', textAlign: 'center' }}>No providers yet. Click the plus icon to add a provider</p>

        </div>
    }

    return (
        <div>
            <AddProvider
                openProviderForm={open}
                setOpenProviderForm={setOpen}
                currentId={currentId}
                setCurrentId={setCurrentId}
            />
            <Providers
                open={open}
                setOpen={setOpen}
                currentId={currentId}
                setCurrentId={setCurrentId}
                providers={providers}
            />
        </div>
    )
}

export default ProviderList