import React, { useEffect, useState, useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { toCommas } from "../../utils/utils";
import './Chart.css'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function Chart({ PaymentHistory, ExpenseHistory, totalReceived, paidExpenses, invoices }) {

  const [soldProductsData, setSoldProductsData] = useState({ series: [], labels: [] });

  const monthlyData = useMemo(() => {
    const monthlyPayments = {};
    const monthlyExpenses = {};

    PaymentHistory.forEach(payment => {
      const month = new Date(payment?.datePaid).toLocaleString('default', { month: 'long', year: 'numeric' });
      monthlyPayments[month] = (monthlyPayments[month] || 0) + payment?.amountPaid;
    });

    ExpenseHistory.forEach(expense => {
      const month = new Date(expense?.paymentRecords[0]?.datePaid).toLocaleString('default', { month: 'long', year: 'numeric' });
      monthlyExpenses[month] = (monthlyExpenses[month] || 0) + expense?.total;
    });

    // Create an array of months and sort it in reverse order
    const labels = Object.keys(monthlyPayments).sort((a, b) => new Date(b) - new Date(a));
    const payments = labels.map(label => monthlyPayments[label] || 0);
    const expenses = labels.map(label => monthlyExpenses[label] || 0);

    return { labels, payments, expenses };
  }, [PaymentHistory, ExpenseHistory]);

  const data = {
    labels: monthlyData.labels,
    datasets: [
      {
        label: 'Ingresos',
        data: monthlyData.payments,
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        barThickness: 25,
      },
      {
        label: 'Gastos',
        data: monthlyData.expenses,
        backgroundColor: 'rgba(255, 99, 132, 0.6)',
        barThickness: 25,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Ingresos y Gastos por Mes',
      },
    },
  };

  const exportToCSV = () => {
    const csvRows = [];
    // Header
    csvRows.push(['Mes', 'Ingresos', 'Gastos']);
    
    // Data rows
    monthlyData.labels.forEach((label, index) => {
      csvRows.push([label, monthlyData.payments[index], monthlyData.expenses[index]]);
    });

    // Create a CSV string
    const csvString = csvRows.map(row => row.join(',')).join('\n');
    
    // Create a blob and download it
    const blob = new Blob([csvString], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', 'data.csv');
    a.click();
    URL.revokeObjectURL(url);
  };

  const handlePrint = () => {
    window.print();
  };
  
  useEffect(() => {
    // Aggregate product quantities sold
    const productQuantities = {};

    invoices.forEach(invoice => {
      invoice.items.forEach(item => {
        if (!productQuantities[item.itemName]) {
          productQuantities[item.itemName] = 0;
        }
        productQuantities[item.itemName] += item.quantity;
      });
    });

    const series = Object.values(productQuantities);
    const labels = Object.keys(productQuantities);

    setSoldProductsData({ series, labels });
  }, [invoices]);

  let paymentDates = [];
  for (let i = 0; i < PaymentHistory.length; i++) {
    const newDate = new Date(PaymentHistory[i].datePaid);
    let localDate = newDate.toISOString();
    paymentDates = [...paymentDates, localDate];
  }

  let paymentReceived = [];
  for (let i = 0; i < PaymentHistory.length; i++) {
    paymentReceived = [...paymentReceived, PaymentHistory[i].amountPaid];
  }

  let expensesDates = [];
  for (let i = 0; i < ExpenseHistory.length; i++) {
    if (ExpenseHistory[i].paymentRecords.length > 0) {
      const newDate = new Date(ExpenseHistory[i].paymentRecords[0].datePaid);
      let localDate = newDate.toISOString();
      expensesDates = [...expensesDates, localDate];
    }
  }

  let expensePaid = [];
  for (let i = 0; i < ExpenseHistory.length; i++) {
    expensePaid.push(ExpenseHistory[i].total);
  }

  // Find the maximum length of the two arrays
  const maxLength = Math.max(paymentReceived.length, expensePaid.length);

  // Pad the shorter array with nulls
  const paddedPaymentReceived = [
    ...paymentReceived,
    ...Array(maxLength - paymentReceived.length).fill(null)
  ];
  const paddedExpensePaid = [
    ...expensePaid,
    ...Array(maxLength - expensePaid.length).fill(null)
  ];

  // Ensure that the paymentDates array matches the maximum length
  const paddedPaymentDates = [
    ...paymentDates,
    ...expensesDates,
    ...Array(maxLength - paymentDates.length).fill(paymentDates[paymentDates.length - 1] || null)
  ];

  const pieSeries = [paidExpenses, totalReceived]
  const balance = totalReceived - paidExpenses

  const pieOptions = {
    chart: {
      type: 'donut',
      zoom: { enabled: true },
      toolbar: { show: true },
    },
    labels: ['Compras', 'Ventas'],
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
      }
    }]
  }

  const productPieOptions = {
    chart: {
      type: 'donut',
      zoom: { enabled: true },
      toolbar: { show: true },
    },
    labels: soldProductsData.labels,
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
      }
    }]
  }

  return (
    <div
      style={{
        backgroundColor: "white",
        textAlign: "center",
        width: "90%",
        margin: "10px auto",
        padding: "10px",
      }}
    >
      <div style={{ marginTop: '50px', marginBottom: '100px' }}>
        <div style={{ marginBottom: '20px' }}>
          <h2 className='text-lg font-bold'>Balance</h2>
          {balance > 0 ? <span className='text-green-500'><b>+{toCommas(balance)}</b></span> : <span style={{ color: 'red' }}><b>{toCommas(balance)}</b></span>}
        </div>
        <ReactApexChart
          options={pieOptions}
          series={pieSeries}
          height={300}
          type="donut"
        />
      </div>

      <div className="chart-container bg-white text-center w-11/12 mx-auto my-2 p-2">
        <div className="print-controls flex flex-row gap-3 mr-[15px] absolute right-20"> 
          <button onClick={exportToCSV} className="mb-4 p-2 bg-blue-500 text-white rounded">Descargar Reporte Excel</button>
          <button onClick={handlePrint} className="mb-4 p-2 bg-blue-500 text-white rounded">Imprimir Reporte</button>
        </div>
        <div className="chart flex">
          <Bar data={data} options={options} />
        </div>
      </div>

      <div style={{ marginTop: '10px', marginBottom: '50px' }}>
        <div style={{ marginBottom: '20px' }}>
          <h2 className='text-lg font-bold'>% Productos Vendidos</h2>
        </div>
        <ReactApexChart
          options={productPieOptions}
          series={soldProductsData.series}
          height={300}
          type="donut"
        />
      </div>

    </div>
  );
}

export default Chart;
