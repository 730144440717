import { Container } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { closeCashbox, getCashboxesByUser } from '../../actions/cashboxActions';
import { toCommas } from '../../utils/utils'
import AddCashBox from './addCashBox';
import { getInvoicesByUser } from '../../actions/invoiceActions';
import AddMoney from './addMoney';
import WithdrawMoney from './withdrawMoney';
import CashRegister from './cashRegister';

const CashBox = () => {
  const user = JSON.parse(localStorage.getItem('profile'));
  const location = useLocation()
  const dispatch = useDispatch()
  const { cashboxes, isLoading } = useSelector((state) => state.cashboxes)
  const [currentCashBox, setCurrentCashBox] = useState(null);
  const [open, setOpen] = useState(false)
  const [currentId, setCurrentId] = useState(null)

  const [openAddMoneyForm, setOpenAddMoneyForm] = useState(false)
  const [openWithdrawMoneyForm, setOpenWithdrawMoneyForm] = useState(false)

  const [initialAmount, setInitialAmount] = useState(0);
  const [addedMoney, setAddedMoney] = useState(0);
  const [withdrawnMoney, setWithdrawnMoney] = useState(0);

  const invoices = useSelector(state => state.invoices.invoices)

  useEffect(() => {
    dispatch(getInvoicesByUser({ search: user?.result?._id || user?.result?.googleId }));
    // eslint-disable-next-line
  }, [location])

  useEffect(() => {
    dispatch(getCashboxesByUser({ search: user?.result?._id || user.result.googleId }));
  }, [location, dispatch])

  useEffect(() => {
    if (cashboxes && cashboxes.length > 0) {
      setCurrentCashBox(cashboxes[cashboxes.length - 1]);
      setCurrentId()
    }
  }, [cashboxes]);

  const cashBoxInvoices = currentCashBox?._id && invoices.filter(invoice => invoice.cashBoxId === currentCashBox?._id)

  let totalSalesAmount = 0;
  for (let i = 0; i < cashBoxInvoices?.length; ++i) {
    const amount = cashBoxInvoices[i].total
    totalSalesAmount += amount
  }

  const handleCloseCashBox = async () => {
    const closedAt = new Date();
    const closeDate = closedAt.toISOString();
    await dispatch(closeCashbox(currentCashBox._id, closeDate));
  };

  const plan = user?.result?.paid

  return (
    <Container>
      {plan === 'pro' || plan === 'business' ?
      
        <div className="text-gray-600 p-6 rounded-lg">
          <div className="flex justify-between">
            <div>
              <h1 className="font-bold text-2xl mb-6">Caja</h1>
            </div>
            <div>
              <button className="text-gray-800 font-lg mr-5 p-2 border border-black rounded-md font-semibold hover:bg-gray-300" onClick={() => setOpen(true)}>Cerrar y Abrir Nueva Caja +</button>
            </div>
          </div>
          <div className="mb-6 mt-10">
            <div className='flex justify-between'>
              <div className="flex items-center mb-2">
                <h2 className="text-4xl mr-4">${currentCashBox ? toCommas(totalSalesAmount + currentCashBox.initialAmount + currentCashBox.incomes - currentCashBox.withdraws) : '0,00'}</h2>
              </div>
              <div>
                <button className="bg-gray-400 text-gray-100 px-4 py-2 mr-2 rounded hover:bg-gray-500" onClick={() => setOpenAddMoneyForm(true)}>Ingresar $</button>
                <button className="bg-gray-400 text-gray-100 px-4 py-2 mr-2 rounded hover:bg-gray-500" onClick={() => setOpenWithdrawMoneyForm(true)}>Retirar $</button>
              </div>
            </div>
            <p>Efectivo de apertura: ${currentCashBox ? toCommas(currentCashBox.initialAmount) : '0,00'}</p>
            <p>Fecha de apertura: {currentCashBox ? new Date(currentCashBox.createdAt).toLocaleString() : ''}</p>
            <p>Cajero: {currentCashBox ? currentCashBox.cashier : 'Tu'}</p>
          </div>
          <div className='mb-20'>
            {/* <CashRegister /> */}
            <CashRegister cashBoxId={currentCashBox?._id} cashier={currentCashBox?.cashier} />
          </div>
          <div className="grid grid-cols-5 gap-4">
            <div className="bg-gray-300 p-4 rounded-lg text-center">
              <h3 className="mb-2">VENTAS</h3>
              <p className="text-2xl">{currentCashBox ? cashBoxInvoices?.length : '0'}</p>
            </div>
            <div className="bg-gray-300 p-4 rounded-lg text-center">
              <h3 className="mb-2">TOTAL</h3>
              <p className="text-2xl">${currentCashBox ? toCommas(totalSalesAmount + currentCashBox.initialAmount + currentCashBox.incomes - currentCashBox.withdraws) : '0,00'}</p>
            </div>
            <div className="bg-gray-300 p-4 rounded-lg text-center">
              <h3 className="mb-2">COBRADO TOTAL</h3>
              <p className="text-2xl">${currentCashBox ? toCommas(totalSalesAmount) : '0,00'}</p>
            </div>
            <div className="bg-gray-300 p-4 rounded-lg text-center">
              <h3 className="mb-2">INGRESOS</h3>
              <p className="text-2xl">${currentCashBox ? toCommas(currentCashBox.incomes) : '0,00'}</p>
            </div>
            <div className="bg-gray-300 p-4 rounded-lg text-center">
              <h3 className="mb-2">RETIROS</h3>
              <p className="text-2xl">${currentCashBox ? toCommas(currentCashBox.withdraws) : '0,00'}</p>
            </div>
            {/* <div className="bg-gray-300 p-4 rounded-lg text-center">
              <h3 className="mb-2">EFECTIVO</h3>
              <p className="text-2xl">$0,00</p>
            </div>
            <div className="bg-gray-300 p-4 rounded-lg text-center">
              <h3 className="mb-2">MERCADO PAGO</h3>
              <p className="text-2xl">$0,00</p>
            </div>
            <div className="bg-gray-300 p-4 rounded-lg text-center">
              <h3 className="mb-2">OTROS MÉTODOS</h3>
              <p className="text-2xl">$0,00</p>
              </div> */}
          </div>
        </div>

        :
        <Container className='w-full h-[100vh]'>
          <div className='text-gray-700 p-5'>Si querés acceder al servicio de cajas por favor actualiza tu plan a Pro o Business.</div>
        </Container>
      }
      <AddCashBox openCashboxForm={open} setOpenCashboxForm={setOpen} currentId={currentId} setCurrentId={setCurrentId} closeCashbox={handleCloseCashBox} />
      <AddMoney openAddMoneyForm={openAddMoneyForm} setOpenAddMoneyForm={setOpenAddMoneyForm} currentId={currentCashBox?._id} setCurrentId={setCurrentId} />
      <WithdrawMoney openWithdrawMoneyForm={openWithdrawMoneyForm} setOpenWithdrawMoneyForm={setOpenWithdrawMoneyForm} currentId={currentCashBox?._id} setCurrentId={setCurrentId} />
    </Container>
  );
};

export default CashBox;