import React from 'react';
import './Ticket.css'
import { Button, Divider } from '@mui/material';
import QRCode from 'qrcode.react';
import { toCommas } from '../../utils/utils';

const Ticket = ({ sale }) => {

    const handlePrint = () => {
        window.print();
    };

    return (
        <div className='ticket-container'>
            <div className="ticket m-auto mb-5">
                <div className="border p-4 rounded-md bg-white">
                    <h5 className="ticket-title bg-gray-900 text-gray-200 text-center mb-2">{sale.businessName}</h5>
                    <p className="text-center mb-2">Ubicación: {sale.location}</p>
                    {/* <Button variant="outlined" color="secondary" className="block mx-auto mb-4">Editar info</Button> */}
                    <Divider className="my-4" />
                    <div className='grid grid-cols-1 text-center mb-2 mt-2'>
                        <p className="ticket-title bg-gray-900 text-gray-200 mb-2">VENTA</p>
                        <p>Caja: #{sale.cashBoxId}</p>
                        <p>Cajero: {sale.cashier}</p>
                        <p>Cliente: {sale.client || '---'}</p>
                        <p className="mb-2">{sale.date}</p>
                        <Divider />
                        {sale.items.map((item, index) => (
                            <div className='my-0'>
                                <p key={index}>{item.itemName} - ${toCommas(item.unitPrice)} x {item.quantity}</p>
                            </div>
                        ))}
                        <Divider />
                    </div>
                    <h3 className='text-center text-gray-700 font-bold mb-2'>ESTADO: PAGO</h3>
                    <h6 className="ticket-title bg-gray-900 text-gray-200 text-center mb-4">TOTAL: ${toCommas(sale.total)}</h6>
                    <div className="flex justify-center mb-2">
                        <QRCode value={sale.qrCodeValue} size={128} />
                    </div>
                    <p className="text-center">www.buk.lat</p>
                </div>
                <div className="print-controls flex justify-between w-full space-x-2 mt-4">
                    <Button variant="contained" className='w-full' color="primary" onClick={handlePrint}>Imprimir</Button>
                    {/* <Button variant="contained" className='w-full' color="primary">Descargar</Button> */}
                </div>
            </div>
        </div>
    );
};

export default Ticket;
