import React, { useState, useEffect, useRef } from 'react';
import styles from '../Stocks/Stocks.module.css'

import Handsontable from 'handsontable';
import { HotTable } from '@handsontable/react';
// import HyperFormula from 'hyperformula';
import * as XLSX from 'xlsx';
import 'handsontable/dist/handsontable.full.css';
import axios from 'axios';
import { Button, Container, Input, Select, TextField } from '@mui/material';
// import { useSnackbar } from 'react-simple-snackbar'
import AddTable from '../Stocks/AddTable';


const SpreadSheet = ({ selectedList }) => {
  const [data, setData] = useState([]);
  const [sheets, setSheets] = useState([]);
  const [currentSheetId, setCurrentSheetId] = useState(null);
  const [sheetName, setSheetName] = useState('');
  const hotTableComponent = useRef(null);
  // const [openSnackbar, closeSnackbar] = useSnackbar()
  const user = JSON.parse(localStorage.getItem('profile'))
  const [openTableForm, setOpenTableForm] = useState(false)
  const userId = user?.result?._id

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API}/sheets?searchQuery=${userId}`)
      .then(response => setSheets(response?.data.data))
      .catch(error => console.error('Error fetching sheets:', error));
  }, []);

  useEffect(() => {
    if (currentSheetId) {
      // Fetch the data for the selected sheet
      axios.get(`${process.env.REACT_APP_API}/sheets/${currentSheetId}`)
        .then(response => setData(response.data.data))
        .catch(error => console.error('Error fetching sheet data:', error));
    }
  }, [currentSheetId]);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const binaryStr = e.target.result;
      const workbook = XLSX.read(binaryStr, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const sheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });
      setData(sheet);
    };

    reader.readAsBinaryString(file);
  };

  const saveData = () => {
    const currentData = hotTableComponent.current.hotInstance.getData();
    if (currentSheetId) {
      axios.patch(`${process.env.REACT_APP_API}/sheets/${currentSheetId}`, { data: currentData })
        .then(() => { })
        .catch(error => console.error('Error saving data:', error));
    } else {
      axios.post(`${process.env.REACT_APP_API}/sheets`, { name: sheetName, data: currentData, userId: userId })
        .then(response => {
          setSheets([...sheets, response.data]);
          setCurrentSheetId(response.data._id);
          // openSnackbar('El archivo fue creado con éxito.')
        })
        .catch(error => console.error('El archivo no pudo ser modificado:', error));
    }
  };

  const createNewSheet = () => {
    setData([]);
    setSheetName('');
    setCurrentSheetId(null);
  };

  const currentTable = hotTableComponent?.current?.hotInstance?.getData();

  const hotSettings = {
    data: data,
    formulas: {
      // engine: HyperFormula,
    },
    rowHeaders: true,
    colHeaders: true,
    contextMenu: true,
    licenseKey: 'non-commercial-and-evaluation',
    colWidths: 180,
  };

  return (
    <Container style={{ width: '100%', marginBottom: '30px', border: '1px solid gray', borderRadius: '10px', padding: '30px', marginTop: '50px' }}>
      <AddTable currentTable={currentTable} openTableForm={openTableForm} setOpenTableForm={setOpenTableForm} selectedList={selectedList} />
      <h2 className='text-gray-600 font-bold text-xl'>Tablas Excel</h2>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', marginBottom: '20px' }}>
        <div>
          <input
            type="file"
            accept=".xlsx, .xls, .csv"
            onChange={handleFileUpload}
            style={{ marginRight: '20px' }}
            // className={styles.selectButton}
            className='text-gray-600 font-bold text-sm rounded-2xl cursor-pointer'
          />
          <Input
            type="text"
            placeholder="Nombre del archivo"
            value={sheetName}
            onChange={(e) => setSheetName(e.target.value)}
            style={{ marginRight: '10px' }}
          />
          <button onClick={saveData} className={styles.button}>Guardar archivo</button>
          <button onClick={setOpenTableForm} className={styles.button} style={{ marginLeft: '5px' }}>Guardar productos</button>
        </div>
        <div>
          <button onClick={createNewSheet} style={{ marginRight: '0px' }} className={styles.button}>Nuevo archivo</button>
        </div>
      </div>

      <div>
        <p value="" disabled className='text-gray-600 font-bold text-md'>Selecciona un archivo existente</p>
        <select className='bg-white text-black' onChange={(e) => setCurrentSheetId(e.target.value)} value={currentSheetId || ''} style={{ marginLeft: '5px', marginTop: '-10px' }}>
          {sheets?.map(sheet => (
            <option key={sheet._id} value={sheet._id} style={{ cursor: 'pointer' }}>{sheet.name}</option>
          ))}
        </select>
      </div>

      <div style={{ marginTop: '20px' }}>
        <HotTable
          ref={hotTableComponent}
          settings={hotSettings}
          width="100%"
          height="auto"
          // style={{ overflow: 'scroll' }}
          preventOverflow='horizontal'
          style={{ zIndex: 10 }}
        />
      </div>
    </Container>
  );
};

export default SpreadSheet;


