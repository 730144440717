import * as api from '../api/index'
import { ADD_NEW_CASHBOX, CLOSE_CASHBOX, DELETE_CASHBOX, END_CASHBOX_LOADING, END_LOADING, FETCH_CASHBOX, FETCH_CASHBOXES_BY_USER, START_CASHBOX_LOADING, START_LOADING, UPDATE_CASHBOX } from './constants';

export const getCashbox = (id) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.fetchCashBox(id);
    dispatch({ type: FETCH_CASHBOX, payload: { cashbox: data } });

  } catch (error) {
    console.log(error);
  }
};


export const getCashboxesByUser = (searchQuery) => async (dispatch) => {
  try {
    dispatch({ type: START_CASHBOX_LOADING })
    const { data: { data } } = await api.fetchCashBoxesByUser(searchQuery)

    dispatch({ type: FETCH_CASHBOXES_BY_USER, payload: data });
    dispatch({ type: END_CASHBOX_LOADING })
  } catch (error) {
    console.log(error.response)

  }
}


export const createCashbox = (cashbox, openSnackbar) => async (dispatch) => {

  try {
    const { data } = await api.createCashBox(cashbox)
    dispatch({ type: ADD_NEW_CASHBOX, payload: data })
    openSnackbar("Caja añadida con éxito")

  } catch (error) {
    console.log(error)
  }
}


export const updateCashboxIncomes = (id, incomes, openSnackbar) => async (dispatch) => {

  const { data } = await api.updateCashBoxIncomes(id, incomes)
  dispatch({ type: UPDATE_CASHBOX, payload: data })
  openSnackbar("Caja actualizada con éxito")
  try {

  } catch (error) {
    console.log(error)
  }
}

export const updateCashboxWithdraws = (id, withdraws, openSnackbar) => async (dispatch) => {

  const { data } = await api.updateCashBoxWithdraws(id, withdraws)
  dispatch({ type: UPDATE_CASHBOX, payload: data })
  openSnackbar("Caja actualizada con éxito")
  try {

  } catch (error) {
    console.log(error)
  }
}

export const deleteCashbox = (id, openSnackbar) => async (dispatch) => {
  try {
    await api.deleteCashBox(id)
    dispatch({ type: DELETE_CASHBOX, payload: id })
    openSnackbar("Caja eliminada con éxito")
  } catch (error) {
    console.log(error)
  }
}

export const closeCashbox = (id, closedAt) => async (dispatch) => {
  try {
    console.log(closedAt)
    const closeBox = await api.closeCashBox(id, closedAt)
    console.log(closeBox)
    dispatch({ type: CLOSE_CASHBOX, payload: id })
  } catch (error) {
    console.log(error)
  }
}