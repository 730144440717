/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

import { useDispatch, useSelector } from 'react-redux'
// import { useSnackbar } from 'react-simple-snackbar'
import { useTranslation } from 'react-i18next';
import { updateCashboxIncomes } from '../../actions/cashboxActions';

const DialogTitle = ((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className="" style={{ margin: '0px', marginLeft: '0px', backgroundColor: '#1976D2' }} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className="" style={{ position: 'absolute', color: 'gray', right: 10, top: 10 }} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});


const AddMoney = ({ setOpenAddMoneyForm, openAddMoneyForm, currentId, setCurrentId }) => {

    const location = useLocation()
    const [incomes, setIncomes] = useState();
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')))
    const dispatch = useDispatch()
    // eslint-disable-next-line 
    // const [openSnackbar, closeSnackbar] = useSnackbar()
    const { t } = useTranslation();

    useEffect(() => {
        setUser(JSON.parse(localStorage.getItem('profile')))
        // setCashboxData({...cashboxData, userId: user?.result?._id})
    }, [location])


    const handleSubmitCashbox = (e) => {
        e.preventDefault();
        dispatch(updateCashboxIncomes(currentId, incomes));
        clear();
        handleClose();
    }

    const clear = () => {
        // setCurrentId(null)
        setIncomes({ incomes: 0 })
    }

    const handleClose = () => {
        setOpenAddMoneyForm(false);
    };

    const inputStyle = {
        display: "block",
        padding: "1.4rem 0.75rem",
        width: "100%",
        fontSize: "0.8rem",
        lineHeight: 1.25,
        color: "#55595c",
        backgroundColor: "#fff",
        backgroundImage: "none",
        backgroundClip: "padding-box",
        borderTop: "0",
        borderRight: "0",
        borderBottom: "1px solid #eee",
        borderLeft: "0",
        borderRadius: "3px",
        transition: "all 0.25s cubic-bezier(0.4, 0, 1, 1)"
    }


    return (
        <div>
            <form >
                <Dialog
                    onClose={(e, reason) => {
                        if (reason !== 'backdropClick') {
                            handleClose()
                        }
                    }}
                    aria-labelledby="customized-dialog-title"
                    open={openAddMoneyForm} fullWidth
                >
                    <DialogTitle id="customized-dialog-title" onClose={handleClose} style={{ paddingLeft: '20px', color: 'gray' }}>
                        Agregar Dinero
                    </DialogTitle>

                    <div className="customInputs" style={{ margin: '10px' }}>
                        <input
                            placeholder="0"
                            style={inputStyle}
                            name='incomes'
                            type='number'
                            onChange={(e) => setIncomes(parseInt(e.target.value))}
                            value={incomes}
                        />
                    </div>

                    <Button autoFocus onClick={handleSubmitCashbox} variant="contained" style={{ margin: '10px', marginTop: '10px', marginBottom: '10px' }} >
                        Ingresar
                    </Button>
                </Dialog>
            </form>
        </div>
    );
}

export default AddMoney