/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

import { useDispatch, useSelector } from 'react-redux'
import { createStock, updateStock } from '../../actions/stockActions'
// import { useSnackbar } from 'react-simple-snackbar'
import { useTranslation } from 'react-i18next';
import axios from 'axios';
// import { withStyles } from '@mui/material';


const styles = (theme) => ({
  root: {
    margin: 0,
    // padding: theme.spacing(2),
    backgroundColor: '#1976D2',
    marginLeft: 0,
  },
  closeButton: {
    position: 'absolute',
    // right: theme.spacing(1),
    // top: theme.spacing(1),
    color: 'white',
  },
});

const DialogTitle = ((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className="" style={{ margin: '0px', marginLeft: '0px', backgroundColor: '#1976D2' }} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className="" style={{ position: 'absolute', color: 'gray', right: 10, top: 10 }} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = (theme) => ({
  root: {
    // padding: theme.spacing(3),
  },
})(MuiDialogContent);

const DialogActions = (theme) => ({
  root: {
    margin: 0,
    // padding: theme.spacing(1),
  },
})(MuiDialogActions);

const AddStock = ({ setOpenStockForm, openStockForm, currentId, setCurrentId, selectedList }) => {
  const location = useLocation()
  const [stockData, setStockData] = useState({ code: '', name: '', details: '', provider: '', price: Number, quantity: Number, userId: '' })
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')))
  const dispatch = useDispatch()
  const stock = useSelector((state) => currentId ? state.stocks.stocks.find((c) => c._id === currentId) : null)
  // eslint-disable-next-line 
  // const [openSnackbar, closeSnackbar] = useSnackbar();
  const { t } = useTranslation();
  const [listId, setListId] = useState(selectedList);

  useEffect(() => {
    if (stock) {
      setStockData(stock)
    }
  }, [stock])

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem('profile')))
    // setClientData({...clientData, userId: user?.result?._id})
  }, [location])


  useEffect(() => {
    var checkId = user?.result?._id
    if (checkId !== undefined) {
      setStockData({ ...stockData, userId: [checkId] })
    } else {
      setStockData({ ...stockData, userId: [user?.result?.googleId] })
    }

  }, [location])


  const handleSubmitStock = (e) => {
    e.preventDefault()
    if (currentId) {
      dispatch(updateStock(currentId, stockData))
    } else {
      // dispatch(createStock(stockData, openSnackbar))
      const listId = localStorage.getItem('listId')
      console.log(listId)
      axios.post(`${process.env.REACT_APP_API}/stocks/${listId}`, stockData)
        .then(response => {
          // openSnackbar('Stock agregado con éxito');
          window.location.reload()
        })
        .catch(error => console.error('Error agregando stock:', error));
    }

    clear()
    handleClose()
  }

  const clear = () => {
    // setCurrentId(null)
    setStockData({ code: '', name: '', details: '', provider: '', price: '', quantity: '', userId: [] })
  }

  const handleClose = () => {
    setOpenStockForm(false);
  };

  const inputStyle = {
    display: "block",
    padding: "1.4rem 0.75rem",
    width: "100%",
    fontSize: "0.8rem",
    lineHeight: 1.25,
    color: "#55595c",
    backgroundColor: "#fff",
    backgroundImage: "none",
    backgroundClip: "padding-box",
    borderTop: "0",
    borderRight: "0",
    borderBottom: "1px solid #eee",
    borderLeft: "0",
    borderRadius: "3px",
    transition: "all 0.25s cubic-bezier(0.4, 0, 1, 1)"
  }


  return (
    <div>
      <form >
        <Dialog
          onClose={(e, reason) => {
            if (reason !== 'backdropClick') {
              handleClose()
            }
          }}
          aria-labelledby="customized-dialog-title"
          open={openStockForm} fullWidth
        >
          <DialogTitle id="customized-dialog-title" onClose={handleClose} style={{ paddingLeft: '20px', color: 'gray' }}>
            {currentId ? t('editStock') : t('newStock')}
          </DialogTitle>
          <div className="customInputs" style={{ margin: '10px' }}>
            <input
              placeholder='Código'
              style={inputStyle}
              name='code'
              type='number'
              onChange={(e) => setStockData({ ...stockData, code: e.target.value })}
              value={stockData.code}
            />

            <input
              placeholder={t('name')}
              style={inputStyle}
              name='name'
              type='text'
              onChange={(e) => setStockData({ ...stockData, name: e.target.value })}
              value={stockData.name}
            />

            <input
              placeholder={t('details')}
              style={inputStyle}
              name='details'
              type='text'
              onChange={(e) => setStockData({ ...stockData, details: e.target.value })}
              value={stockData.details}
            />

            <input
              placeholder={t('provider')}
              style={inputStyle}
              name='provider'
              type='text'
              onChange={(e) => setStockData({ ...stockData, provider: e.target.value })}
              value={stockData.provider}
            />

            <input
              placeholder={t('price')}
              style={inputStyle}
              name='price'
              type='number'
              onChange={(e) => setStockData({ ...stockData, price: e.target.value })}
              value={stockData.price}
            />

            <input
              placeholder={t('quantity')}
              style={inputStyle}
              name='quantity'
              type='number'
              onChange={(e) => setStockData({ ...stockData, quantity: e.target.value })}
              value={stockData.quantity}
            />

          </div>
          {/* <DialogActions> */}
          <Button onClick={handleSubmitStock} variant="contained" style={{ margin: '10px', marginTop: '10px', marginBottom: '10px' }} >
            {t('submitButton')}
          </Button>
          {/* </DialogActions> */}
        </Dialog>
      </form>
    </div>
  );
}

export default AddStock