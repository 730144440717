/* eslint-disable */
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

import { useDispatch } from 'react-redux'
import { createClient } from '../../actions/clientActions'
import { useLocation } from 'react-router-dom';

// import { useSnackbar } from 'react-simple-snackbar'
import { useTranslation } from 'react-i18next';
// import { withStyles } from '@mui/material';

const styles = (theme) => ({
  root: {
    margin: 0,
    // padding: theme.spacing(2),
    backgroundColor: '#1976D2',
    marginLeft: 0,
  },
  closeButton: {
    position: 'absolute',
    // right: theme.spacing(1),
    // top: theme.spacing(1),
    color: 'white',
  },
});

const DialogTitle = ((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className="" style={{ margin: '0px', marginLeft: '0px', backgroundColor: '#1976D2' }} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className="" style={{ position: 'absolute', color: 'gray', right: 10, top: 10 }} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = (theme) => ({
  root: {
    // padding: theme.spacing(4),
  },
})(MuiDialogContent);

const DialogActions = (theme) => ({
  root: {
    margin: 0,
    // padding: theme.spacing(1),
  },
})(MuiDialogActions);

const AddClient = ({ setOpen, open }) => {

  const { t } = useTranslation();
  const location = useLocation()
  const [clientData, setClientData] = useState({ name: '', email: '', phone: '', address: '', userId: [] })
  const dispatch = useDispatch()
  const user = JSON.parse(localStorage.getItem('profile'))
  // eslint-disable-next-line 
  // const [openSnackbar, closeSnackbar] = useSnackbar()


  useEffect(() => {
    var checkId = user?.result?._id
    if (checkId !== undefined) {
      setClientData({ ...clientData, userId: [checkId] })
    } else {
      setClientData({ ...clientData, userId: [user?.result?.googleId] })
    }
  }, [location])


  const handleSubmitClient = (e) => {
    e.preventDefault()
    dispatch(createClient(clientData))

    clear()
    handleClose()
  }

  const clear = () => {
    setClientData({ name: '', email: '', phone: '', address: '', userId: [] })
  }

  const handleClose = () => {
    setOpen(false);
  };


  const inputStyle = {
    display: "block",
    padding: "1.4rem 0.75rem",
    width: "100%",
    fontSize: "0.8rem",
    lineHeight: 1.25,
    color: "#55595c",
    backgroundColor: "#fff",
    backgroundImage: "none",
    backgroundClip: "padding-box",
    borderTop: "0",
    borderRight: "0",
    borderBottom: "1px solid #eee",
    borderLeft: "0",
    borderRadius: "3px",
    transition: "all 0.25s cubic-bezier(0.4, 0, 1, 1)"
  }

  const focus = {
    "input:focus, textarea:focus": { outline: "0", borderBottomColor: "#ffab00" }
  }


  return (
    <div>
      <div>
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth >
          <DialogTitle id="customized-dialog-title" onClose={handleClose} style={{ paddingLeft: '20px', color: 'gray' }}>
            {t('selectNewCustomer')}
          </DialogTitle>


          <div className="customInputs" style={{ margin: '10px' }}>
            <input
              placeholder={t('name')}
              style={inputStyle}
              name={t('name')}
              type='text'
              onChange={(e) => setClientData({ ...clientData, name: e.target.value })}
              value={clientData.name}
            />

            <input
              placeholder="Email"
              style={inputStyle}
              name='email'
              type='text'
              onChange={(e) => setClientData({ ...clientData, email: e.target.value })}
              value={clientData.email}
            />
            <input
              placeholder={t('phone')}
              style={inputStyle}
              name={t('phone')}
              type='text'
              onChange={(e) => setClientData({ ...clientData, phone: e.target.value })}
              value={clientData.phone}
            />
            <input
              placeholder={t('address')}
              style={inputStyle}
              name={t('address')}
              type='text'
              onChange={(e) => setClientData({ ...clientData, address: e.target.value })}
              value={clientData.address}
            />
          </div>

          {/* <DialogActions> */}
          <Button autoFocus onClick={handleSubmitClient} variant="contained" style={{ margin: '10px', marginTop: '10px', marginBottom: '10px' }} >
            {t('submitButton')}
          </Button>
          {/* </DialogActions> */}
        </Dialog>
      </div>
    </div>
  );
}

export default AddClient