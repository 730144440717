import axios from 'axios'

// const API = axios.create({ baseURL: 'http://localhost:5000'})
const API = axios.create({ baseURL: process.env.REACT_APP_API })

API.interceptors.request.use((req) => {
    if (localStorage.getItem('profile')) {
        req.headers.authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`
    }

    return req
})

// export const fetchInvoices =() => API.get('/invoices')
export const fetchInvoice = (id) => API.get(`/invoices/${id}`)
export const addInvoice = (invoice) => API.post('/invoices', invoice)
export const updateInvoice = (id, updatedInvoice) => API.patch(`/invoices/${id}`, updatedInvoice)
export const deleteInvoice = (id) => API.delete(`/invoices/${id}`)
export const fetchInvoicesByUser = (searchQuery) => API.get(`/invoices?searchQuery=${searchQuery.search}`);
// export const fetchInvoicesByCashbox = (searchQuery) => API.get(`/invoices/cashbox/?searchQuery=${searchQuery.search}`);

export const fetchClient = (id) => API.get(`/clients/${id}`);
export const fetchClients = (page) => API.get(`/clients?page=${page}`);
export const addClient = (client) => API.post('/clients', client)
export const updateClient = (id, updatedClient) => API.patch(`/clients/${id}`, updatedClient)
export const deleteClient = (id) => API.delete(`/clients/${id}`)
export const fetchClientsByUser = (searchQuery) => API.get(`/clients/user?searchQuery=${searchQuery.search}`);


export const signIn = (formData) => API.post('/users/signin', formData)
export const signUp = (formData) => API.post('/users/signup', formData)
export const forgot = (formData) => API.post('/users/forgot', formData);
export const reset = (formData) => API.post('/users/reset', formData);

export const fetchProfilesBySearch = (searchQuery) => API.get(`/profiles/search?searchQuery=${searchQuery.search || searchQuery.year || 'none'}`);
export const fetchProfile = (id) => API.get(`/profiles/${id}`)
export const fetchProfiles = () => API.get('/profiles');
export const fetchProfilesByUser = (searchQuery) => API.get(`/profiles?searchQuery=${searchQuery.search}`)
export const createProfile = (newProfile) => API.post('/profiles', newProfile);
export const updateProfile = (id, updatedProfile) => API.patch(`/profiles/${id}`, updatedProfile);
export const deleteProfile = (id) => API.delete(`/profiles/${id}`);

export const fetchProvider = (id) => API.get(`/providers/${id}`);
export const fetchProviders = (page) => API.get(`/providers?page=${page}`);
export const addProvider = (provider) => API.post('/providers', provider)
export const updateProvider = (id, updatedProvider) => API.patch(`/providers/${id}`, updatedProvider)
export const deleteProvider = (id) => API.delete(`/providers/${id}`)
export const fetchProvidersByUser = (searchQuery) => API.get(`/providers/user?searchQuery=${searchQuery.search}`);

export const fetchStock = (id) => API.get(`/stocks/${id}`);
export const fetchStocks = (page) => API.get(`/stocks?page=${page}`);
export const addStock = (stock, listId) => API.post(`/stocks/${listId}`, stock)
export const updateStock = (id, updatedStock) => API.patch(`/stocks/${id}`, updatedStock)
export const deleteStock = (id) => API.delete(`/stocks/${id}`)
export const fetchStocksByUser = (searchQuery) => API.get(`/stocks/user?searchQuery=${searchQuery.search}`);

export const fetchExpense = (id) => API.get(`/expenses/${id}`);
export const fetchExpenses = (page) => API.get(`/expenses?page=${page}`);
export const addExpense = (expense) => API.post('/expenses', expense)
export const updateExpense = (id, updatedExpense) => API.patch(`/expenses/${id}`, updatedExpense)
export const deleteExpense = (id) => API.delete(`/expenses/${id}`)
export const fetchExpensesByUser = (searchQuery) => API.get(`/expenses?searchQuery=${searchQuery.search}`);

export const fetchSheetsByUser = (searchQuery) => API.get(`/sheets/user?searchQuery=${searchQuery.search}`);

export const fetchCashBox = (id) => API.get(`/cashbox/${id}`);
export const fetchCashBoxesByUser = (searchQuery) => API.get(`/cashbox/user?searchQuery=${searchQuery.search}`);
export const fetchCashBoxes = () => API.get('/cashbox');
export const createCashBox = (newCashBox) => API.post('/cashbox', newCashBox);
export const updateCashBoxIncomes = (id, incomes) => API.patch(`/cashbox/incomes/${id}`, { incomes });
export const updateCashBoxWithdraws = (id, withdraws) => API.patch(`/cashbox/withdraws/${id}`, { withdraws });
export const closeCashBox = (id, closedAt) => API.patch(`/cashbox/close/${id}`, { closedAt });
export const deleteCashBox = (id) => API.delete(`/cashbox/${id}`);