import * as api from '../api/index'

import { ADD_NEW_EXPENSE, UPDATE_EXPENSE, DELETE_EXPENSE, FETCH_EXPENSES_BY_USER, FETCH_EXPENSE, START_LOADING, END_LOADING } from './constants'


export const getExpense = (id) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.fetchExpense(id);
    dispatch({ type: FETCH_EXPENSE, payload: { expense: data } });

  } catch (error) {
    console.log(error);
  }
};


export const getExpensesByUser = (searchQuery) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING })
    const { data: { data } } = await api.fetchExpensesByUser(searchQuery)

    dispatch({ type: FETCH_EXPENSES_BY_USER, payload: data });
    dispatch({ type: END_LOADING })
  } catch (error) {
    console.log(error.response)

  }
}


export const createExpense = (expense, openSnackbar) => async (dispatch) => {

  try {
    const { data } = await api.addExpense(expense)
    dispatch({ type: ADD_NEW_EXPENSE, payload: data })
    openSnackbar("Compra añadida con éxito")

  } catch (error) {
    console.log(error)
  }
}


export const updateExpense = (id, expense) => async (dispatch) => {

  const { data } = await api.updateExpense(id, expense)
  dispatch({ type: UPDATE_EXPENSE, payload: data })
  try {

  } catch (error) {
    console.log(error)
  }
}

export const deleteExpense = (id, openSnackbar) => async (dispatch) => {
  try {
    await api.deleteExpense(id)

    dispatch({ type: DELETE_EXPENSE, payload: id })
    openSnackbar("Compra eliminada con éxito")
  } catch (error) {
    console.log(error)
  }
}