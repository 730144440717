
import { ALL_EXPENSES, ADD_NEW_EXPENSE, UPDATE_EXPENSE, DELETE_EXPENSE, FETCH_EXPENSES_BY_USER, FETCH_EXPENSE, START_LOADING, END_LOADING } from '../actions/constants'

const expenses = (state = { isLoading: true, expenses: [] }, action) => {
    switch (action.type) {
      case START_LOADING:
        return { ...state, isLoading: true };
      case END_LOADING:
        return { ...state, isLoading: false };
      case ALL_EXPENSES:
        return {
          ...state,
          expenses: action.payload.data,
          currentPage: action.payload.currentPage,
          numberOfPages: action.payload.numberOfPages,
        };
      case FETCH_EXPENSES_BY_USER:
        return { ...state, expenses: action.payload };

      case FETCH_EXPENSE:
        return { ...state, expense: action.payload.expense };
      case ADD_NEW_EXPENSE:
        return { ...state, expenses: [...state.expenses, action.payload] };
      case UPDATE_EXPENSE:
        return { ...state, expenses: state.expenses.map((expense) => (expense._id === action.payload._id ? action.payload : expense)) };
      case DELETE_EXPENSE:
        return { ...state, expenses: state.expenses.filter((expense) => expense._id !== action.payload) };
      default:
        return state;
    }
  };

  export default expenses