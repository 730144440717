/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

// import { useSnackbar } from 'react-simple-snackbar'
import { useTranslation } from 'react-i18next';
import axios from 'axios';
// import { withStyles } from '@mui/material';


const styles = (theme) => ({
    root: {
        margin: 0,
        // padding: theme.spacing(2),
        backgroundColor: '#1976D2',
        marginLeft: 0,
    },
    closeButton: {
        position: 'absolute',
        // right: theme.spacing(1),
        // top: theme.spacing(1),
        color: 'white',
    },
});

const DialogTitle = ((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className="" style={{ margin: '0px', marginLeft: '0px', backgroundColor: '#1976D2' }} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className="" style={{ position: 'absolute', color: 'gray', right: 10, top: 10 }} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = (theme) => ({
    root: {
        // padding: theme.spacing(3),
    },
})(MuiDialogContent);

const DialogActions = (theme) => ({
    root: {
        margin: 0,
        // padding: theme.spacing(1),
    },
})(MuiDialogActions);

const AddTable = ({ setOpenTableForm, openTableForm, currentId, setCurrentId, currentTable, selectedList }) => {
    const location = useLocation()
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')))
    // eslint-disable-next-line 
    // const [openSnackbar, closeSnackbar] = useSnackbar();
    const { t } = useTranslation();
    const [provider, setProvider] = useState();

    useEffect(() => {
        setUser(JSON.parse(localStorage.getItem('profile')))
        // setClientData({...clientData, userId: user?.result?._id})
    }, [location])

    const handleClose = () => {
        setOpenTableForm(false);
    };

    const inputStyle = {
        display: "block",
        padding: "1.4rem 0.75rem",
        width: "100%",
        fontSize: "0.8rem",
        lineHeight: 1.25,
        color: "#55595c",
        backgroundColor: "#fff",
        backgroundImage: "none",
        backgroundClip: "padding-box",
        borderTop: "0",
        borderRight: "0",
        borderBottom: "1px solid #eee",
        borderLeft: "0",
        borderRadius: "3px",
        transition: "all 0.25s cubic-bezier(0.4, 0, 1, 1)"
    }

    const saveTable = async () => {
        const products = currentTable.map(row => ({
            code: row[0],
            name: row[1],
            details: row[2],
            price: row[3],
            quantity: row[4],
            provider: provider,
            userId: [user?.result?._id]
        }));

        // axios.post(`${process.env.REACT_APP_API}/stocks/tables`, { products })
        //     .then(() => {
        //         openSnackbar('Stock agregado con éxito')
        //         window.location.reload()
        //     })
        //     .catch(error => console.error('Error saving data:', error));

        await axios.post(`${process.env.REACT_APP_API}/stocklists/stocks/${selectedList}`, { products })
            .then(() => {
                // openSnackbar('Stock agregado con éxito')
                window.location.reload()
            })
            .catch(error => {
                // openSnackbar('Hubo un error. Por favor revisa los productos e intenta de nuevo.')
                console.error('Error saving data:', error)
            })
    };


    return (
        <div>
            <form >
                <Dialog
                    onClose={(e, reason) => {
                        if (reason !== 'backdropClick') {
                            handleClose()
                        }
                    }}
                    aria-labelledby="customized-dialog-title"
                    open={openTableForm} fullWidth
                >
                    <DialogTitle id="customized-dialog-title" onClose={handleClose} style={{ paddingLeft: '20px', color: 'white' }}>
                        Agregar múltiples productos
                    </DialogTitle>
                    <div className='p-5'>
                        <p>
                            Para que los productos se carguen correctamente, se debe seguir esta estructura en el archivo Excel:
                            <ul style={{ listStyle: 'none', marginLeft: '10px', fontWeight: 'bold', marginTop: '10px', marginBottom: '20px' }}>
                                <li>1er Columna - Código</li>
                                <li>2da Columna - Nombre</li>
                                <li>3er Columna - Detalles</li>
                                <li>4ta Columna - Precio</li>
                                <li>5ta Columna - Cantidad</li>
                            </ul>
                            ⛔ En las columnas que utilizas, por favor no dejes filas en blanco. <br />
                            ⛔ En la/s columna/s que no utilizas, por favor dejala/s en blanco y respeta la estructura mencionada.
                        </p>
                        <p style={{ marginTop: '20px' }}>Por último, por favor <b>indica el proveedor</b> de los productos:</p>
                        <div className="customInputs">
                            <input
                                placeholder='Proveedor...'
                                style={inputStyle}
                                name='provider'
                                type='text'
                                onChange={(e) => setProvider(e.target.value)}
                                value={provider}
                                required
                            />
                        </div>
                    </div>
                    {/* <DialogActions> */}
                    <Button onClick={saveTable} variant="contained" style={{ margin: '10px', marginTop: '10px', marginBottom: '10px' }} >
                        Cargar productos
                    </Button>
                    {/* </DialogActions> */}
                </Dialog>
            </form>
        </div>
    );
}

export default AddTable