import React, { useEffect, useState } from 'react'
import { Container } from '@mui/material'

const ProblemSection = () => {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <Container component="main" maxWidth="lg" >
            <h1 style={{ textAlign: 'center', marginTop: '60px' }} className='text-3xl font-bold text-gray-600 italic'>
                Tu comercio no se administra solo <br />
                Tu stock y balance no se actualizan automáticamente
            </h1>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '60px',
                    flexDirection: isMobile ? 'column' : 'row',
                }}
            >
                <div style={{ display: 'grid', placeItems: 'flex-start' }}>
                    <div></div>
                    <div style={{ border: '1px solid black', borderRadius: '5px', padding: '5px', marginTop: '10px', backgroundColor: 'rgb(20, 20, 20)', color: 'rgb(231, 225, 225)', fontWeight: 'bold' }}>
                        <span>❌ ¿Tengo stock? ❌</span>
                    </div>
                    <div></div>
                    <div style={{ border: '1px solid black', borderRadius: '5px', padding: '5px', marginTop: '10px', backgroundColor: 'rgb(20, 20, 20)', color: 'rgb(231, 225, 225)', fontWeight: 'bold' }}>
                        <span>❌ ¿Cuánto vendí? ❌</span>
                    </div>
                    {/* <div style={{border: '1px solid black', borderRadius:'5px', padding:'5px', marginTop:'10px'}}>
                        <span>¿?</span>
                    </div> */}
                </div>
                <div>
                    <img alt="problem-img" src="/problem.png" height="600px" width="300px" />
                </div>
                <div style={{ display: 'grid', placeItems: 'flex-start' }}>
                    <div></div>
                    <div style={{ border: '1px solid black', borderRadius: '5px', padding: '5px', marginTop: '10px', backgroundColor: 'rgb(20, 20, 20)', color: 'rgb(231, 225, 225)', fontWeight: 'bold' }}>
                        <span>❌ ¿Balance positivo o negativo? ❌</span>
                    </div>
                    <div></div>
                    <div style={{ border: '1px solid black', borderRadius: '5px', padding: '5px', marginTop: '10px', backgroundColor: 'rgb(20, 20, 20)', color: 'rgb(231, 225, 225)', fontWeight: 'bold' }}>
                        <span>❌ ¿Tengo el comprobante de pago? ❌</span>
                    </div>
                    {/* <div style={{border: '1px solid black', borderRadius:'5px', padding:'5px', marginTop:'10px'}}>
                        <span>¿?</span>
                    </div> */}
                </div>
            </div>
        </Container>
    )
}

export default ProblemSection