/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

import { useDispatch, useSelector } from 'react-redux'
// import { useSnackbar } from 'react-simple-snackbar'
import { useTranslation } from 'react-i18next';
import { createCashbox, updateCashbox } from '../../actions/cashboxActions';

const DialogTitle = ((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className="" style={{ margin: '0px', marginLeft: '0px', backgroundColor: '#1976D2' }} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className="" style={{ position: 'absolute', color: 'gray', right: 10, top: 10 }} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});


const AddCashBox = ({ setOpenCashboxForm, openCashboxForm, currentId, setCurrentId, closeCashbox }) => {

  const location = useLocation()
  const [cashboxData, setCashboxData] = useState({ name: '', cashier: '', initialAmount: Number, incomes: 0, withdraws: 0, totalAmount: 0, sales: 0, cash: 0, mercadoPago: 0, bankTransfer: 0, otherMethods: 0, userId: '' })
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')))
  const dispatch = useDispatch()
  // eslint-disable-next-line 
  // const [openSnackbar, closeSnackbar] = useSnackbar()
  const { t } = useTranslation();

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem('profile')))
    // setCashboxData({...cashboxData, userId: user?.result?._id})
  }, [location])


  useEffect(() => {
    var checkId = user?.result?._id
    if (checkId !== undefined) {
      setCashboxData({ ...cashboxData, userId: [checkId] })
    } else {
      setCashboxData({ ...cashboxData, userId: [user?.result?.googleId] })
    }

  }, [location])


  const handleSubmitCashbox = (e) => {
    e.preventDefault();
    closeCashbox();
    dispatch(createCashbox(cashboxData));

    clear();
    handleClose();
  }

  const clear = () => {
    // setCurrentId(null)
    setCashboxData({ name: '', cashier: '', initialAmount: 0, incomes: 0, withdraws: 0, totalAmount: 0, sales: 0, cash: 0, mercadoPago: 0, bankTransfer: 0, otherMethods: 0, userId: [] })
  }

  const handleClose = () => {
    setOpenCashboxForm(false);
  };

  const inputStyle = {
    display: "block",
    padding: "1.4rem 0.75rem",
    width: "100%",
    fontSize: "0.8rem",
    lineHeight: 1.25,
    color: "#55595c",
    backgroundColor: "#fff",
    backgroundImage: "none",
    backgroundClip: "padding-box",
    borderTop: "0",
    borderRight: "0",
    borderBottom: "1px solid #eee",
    borderLeft: "0",
    borderRadius: "3px",
    transition: "all 0.25s cubic-bezier(0.4, 0, 1, 1)"
  }


  return (
    <div>
      <form >
        <Dialog
          onClose={(e, reason) => {
            if (reason !== 'backdropClick') {
              handleClose()
            }
          }}
          aria-labelledby="customized-dialog-title"
          open={openCashboxForm} fullWidth
        >
          <DialogTitle id="customized-dialog-title" onClose={handleClose} style={{ paddingLeft: '20px', color: 'gray' }}>
            {currentId ? 'Editar Caja' : 'Nueva Caja'}
          </DialogTitle>

          <div className="customInputs" style={{ margin: '10px' }}>
            <input
              placeholder="Efectivo de Apertura"
              style={inputStyle}
              name='initialAmount'
              type='number'
              onChange={(e) => setCashboxData({ ...cashboxData, initialAmount: parseInt(e.target.value) })}
              value={cashboxData.initialAmount}
            />

            <input
              placeholder="Cajero"
              style={inputStyle}
              name='cashier'
              type='text'
              onChange={(e) => setCashboxData({ ...cashboxData, cashier: e.target.value })}
              value={cashboxData.cashier}
            />

          </div>

          <Button autoFocus onClick={handleSubmitCashbox} variant="contained" style={{ margin: '10px', marginTop: '10px', marginBottom: '10px' }} >
            {t('submitButton')}
          </Button>
        </Dialog>
      </form>
    </div>
  );
}

export default AddCashBox