import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import SnackbarProvider from 'react-simple-snackbar'

import Home from './components/Home/Home';
import Invoice from './components/Invoice/Invoice';
import Invoices from './components/Invoices/Invoices';
import InvoiceDetails from './components/InvoiceDetails/InvoiceDetails';
import ClientList from './components/Clients/ClientList';
import NavBar from './components/NavBar/NavBar';
import Login from './components/Login/Login';
import Dashboard from './components/Dashboard/Dashboard';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Settings from './components/Settings/Settings';
import Forgot from './components/Password/Forgot';
import Reset from './components/Password/Reset';
import ProviderList from './components/Providers/ProvidersList';
import StockList from './components/Stocks/StocksList';
import 'moment/locale/es';
import Pricing from './components/Pricing';
import Expense from './components/Expense/Expense';
import Expenses from './components/Expenses/Expenses';
import ExpenseDetails from './components/ExpenseDetails.js/ExpenseDetails';
import Prices from './components/Pricing/newPricing';
import HobbyPlan from './components/planSuccess/hobbyPlan';
import ProPlan from './components/planSuccess/proPlan';
import BusinessPlan from './components/planSuccess/businessPlan';
import Wallets from './components/Wallets';
import MpSuccess from './components/mpSuccess';
import CashBox from './components/CashBox';
// import Billing from './components/Billing';

function App() {
  const user = JSON.parse(localStorage.getItem('profile'));

  return (
    <div>
      <BrowserRouter>
        {/* <SnackbarProvider> */}
        <NavBar />
        <Header />
        <ToastContainer />
        <Routes>
          <Route path="/" element={<Home />} />

          {/* <Route path="/billing" element={<Billing />} /> */}
          <Route path="/invoice" element={<Invoice />} />
          <Route path="/edit/invoice/:id" element={<Invoice />} />
          <Route path="/invoice/:id" element={<InvoiceDetails />} />
          <Route path="/invoices" element={<Invoices />} />

          <Route path="/login" element={<Login />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/customers" element={<ClientList />} />
          <Route path="/forgot" element={<Forgot />} />
          <Route path="/reset/:token" element={<Reset />} />
          <Route path="/providers" element={<ProviderList />} />
          <Route path="/stocks" element={<StockList />} />
          <Route path="/cash-register" element={<CashBox />} />

          <Route path="/my-plan" element={<Prices />} />
          <Route path="/my-plan/hobby" element={<HobbyPlan />} />
          <Route path="/my-plan/pro" element={<ProPlan />} />
          <Route path="/my-plan/business" element={<BusinessPlan />} />

          <Route path="/mp-success" element={<MpSuccess />} />
          <Route path="/my-wallets" element={<Wallets />} />

          <Route path="/expense" element={<Expense />} />
          <Route path="/edit/expense/:id" element={<Expense />} />
          <Route path="/expense/:id" element={<ExpenseDetails />} />
          <Route path="/expenses" element={<Expenses />} />
          <Route path="/new-invoice" element={<Navigate to="/invoice" />} />
        </Routes>
        <Footer />
        {/* </SnackbarProvider> */}
      </BrowserRouter>
    </div>
  );
}

export default App;

