import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Paper, Typography, Container, Grid } from '@mui/material';
import useStyles from './styles';
import Field from '../Login/Field';
import { useParams, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
// import { useSnackbar } from 'react-simple-snackbar'

import { reset } from '../../actions/auth';

const Reset = () => {
  const classes = useStyles();
  const [form, setForm] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { token } = useParams()
  const user = JSON.parse(localStorage.getItem('profile'))
  const { t } = useTranslation();

  const handleSubmit = (e) => {
    e.preventDefault()
    dispatch(reset({ password: form, token: token }, navigate))
  }

  const handleChange = (e) => setForm(e.target.value);
  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => setShowPassword(!showPassword);
  // const [openSnackbar, closeSnackbar] = useSnackbar()

  if (user) navigate('/dashboard')

  return (
    <div style={{ paddingTop: '100px', paddingBottom: '100px' }}>
      <Container component="main" maxWidth="xs">
        <Paper className={classes.paper} variant="outlined">
          <Typography variant="h6" gutter="5" style={{ display: 'flex', justifyContent: 'center' }}>{t('enterNewPassword')}</Typography>
          <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Field name="password" label="Password" handleChange={handleChange} type={showPassword ? 'text' : 'password'} handleShowPassword={handleShowPassword} />
              <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                {t('changePasswordButton')}
              </Button>
            </Grid>
          </form>
        </Paper>
      </Container>
    </div>
  );
}

export default Reset
