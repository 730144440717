import * as api from '../api/index'

import { ADD_NEW_PROVIDER, UPDATE_PROVIDER, DELETE_PROVIDER, FETCH_PROVIDERS_BY_USER, FETCH_PROVIDER, START_LOADING, END_LOADING } from './constants'


export const getProvider = (id) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING });
      const { data } = await api.fetchProvider(id);
      dispatch({ type: FETCH_PROVIDER, payload: { provider: data } });

    } catch (error) {
      console.log(error);
    }
  };


export const getProvidersByUser =(searchQuery) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING })
    const  { data: { data } } = await api.fetchProvidersByUser(searchQuery)
      
      dispatch({ type: FETCH_PROVIDERS_BY_USER, payload: data });
      dispatch({ type: END_LOADING })
    } catch (error) {
      console.log(error.response)
      
    }
  }


export const createProvider =(provider, openSnackbar) => async (dispatch) => {

    try {
        const { data } = await api.addProvider(provider)
        dispatch({ type: ADD_NEW_PROVIDER, payload: data })
        openSnackbar("Proveedor añadido con éxito")

    } catch (error) {
        console.log(error)
    }
}


export const updateProvider =(id, provider, openSnackbar) => async (dispatch) => {

    const { data } = await api.updateProvider(id, provider)
    dispatch({ type: UPDATE_PROVIDER, payload: data })
    openSnackbar("Proveedor actualizado con éxito")
    try {
        
    } catch (error) {
        console.log(error)
    }
}

export const deleteProvider =(id, openSnackbar) => async (dispatch) => {
    try {
        await api.deleteProvider(id)

        dispatch({type: DELETE_PROVIDER, payload: id})
        openSnackbar("Proveedor eliminado con éxito")
    } catch (error) {
        console.log(error)
    }
}