import React, { useState } from 'react'
import Field from './Field'
import useStyles from './styles'
import styles from './Login.module.css'
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google'
import { jwtDecode } from 'jwt-decode'
import { useDispatch } from 'react-redux'
import { useNavigate, Link } from 'react-router-dom'
import { signup, signin } from '../../actions/auth'
import { Avatar, Button, Paper, Grid, Typography, Container } from '@mui/material'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { createProfile } from '../../actions/profile'
// import { useSnackbar } from 'react-simple-snackbar'
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify'



const initialState = { firstName: '', lastName: '', email: '', password: '', confirmPassword: '', profilePicture: '', bio: '' }

const Login = () => {

  const { t } = useTranslation();
  const classes = useStyles();
  const [formData, setFormData] = useState(initialState)
  const [isSignup, setIsSignup] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [showPassword, setShowPassword] = useState(false);
  // eslint-disable-next-line 
  // const [openSnackbar, closeSnackbar] = useSnackbar()
  const user = JSON.parse(localStorage.getItem('profile'))
  const [loading, setLoading] = useState(false)

  const handleShowPassword = () => setShowPassword(!showPassword);
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (isSignup) {
      dispatch(signup(formData, toast, setLoading))
    } else {
      dispatch(signin(formData, toast, setLoading, navigate))
    }
    setLoading(true)
  }


  const switchMode = () => {
    setIsSignup((prevState) => !prevState)
  }

  const googleSuccess = async (res) => {
    const result = jwtDecode(res.credential);
    const token = res?.credential;
    dispatch(createProfile({ name: result?.name, email: result?.email, userId: result?.jti, phoneNumber: '', businessName: '', contactAddress: '', logo: result?.picture, website: '' }))

    try {
      dispatch({ type: "AUTH", data: { result, token } })

      window.location.href = '/dashboard'

    } catch (error) {
      console.log(error)
    }
  }
  const googleError = (error) => {
    console.log(error)
    console.log("Google Sign In was unseccassful. Try again later")
  }

  if (user) {
    navigate('/dashboard')
  }

  return (
    <Container component="main" maxWidth="xs" style={{ marginBottom: '30px', marginTop: '20px' }}>
      <Paper className={classes.paper} elevation={2}>
        <div style={{ marginBottom: '20px', textAlign: 'center', paddingTop: '10px' }}>
          <Avatar className={classes.avatar} style={{ margin: 'auto' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">{isSignup ? t('signUp') : t('signIn')}</Typography>
        </div>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {isSignup && (
              <>
                <Field name="firstName" label={t('name')} handleChange={handleChange} autoFocus half />
                <Field name="lastName" label={t('lastName')} handleChange={handleChange} half />
              </>
            )}
            <Field name="email" label="Email" handleChange={handleChange} type="email" />
            <Field name="password" label={t('password')} handleChange={handleChange} type={showPassword ? 'text' : 'password'} handleShowPassword={handleShowPassword} />
            {isSignup && <Field name="confirmPassword" label={t('repeatPassword')} handleChange={handleChange} type="password" />}
          </Grid>
          <div className={styles.buttons}>
            <div >
              {/* <button className={styles.submitBtn}> { isSignup ? 'Sign Up' : 'Sign In' }</button> */}
              {/* <ProgressButton>{ isSignup ? 'Sign Up' : 'Sign In' }</ProgressButton> */}
              {loading ? <div style={{ textAlign: 'center' }}> <CircularProgress /> </div>
                :
                <button className={styles.loginBtn} >{isSignup ? t('signUp') : t('signIn')}</button>
              }

            </div>
            <div className={styles.option}>
              <span>o</span>
            </div>
            {/* <div> 
                  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                    <GoogleLogin
                      onSuccess={googleSuccess}
                      onError={googleError}
                      text='continue_with'
                      useOneTap
                      auto_select
                      state_cookie_domain='single_host_origin'
                    />
                  </GoogleOAuthProvider>
                </div> */}
          </div>
          <Grid container style={{ display: 'flex', justifyContent: 'center' }}>
            <Grid item>
              <Button onClick={switchMode}>
                {isSignup ? t('hasAccount') : t('noAccount') + " " + t('signUp')}
              </Button>
            </Grid>
          </Grid>
          <Link to="/forgot"><p style={{ textAlign: 'center', color: '#1d7dd6', marginTop: '20px' }}>{t('forgottenPassword')}</p></Link>
        </form>
      </Paper>
    </Container>
  )
}

export default Login
