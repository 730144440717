import React, { useState, useEffect } from 'react'
import styles from './Expense.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { toCommas } from '../../utils/utils'

import IconButton from '@mui/material/IconButton';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
// import DateFnsUtils from '@date-io/date-fns';
// import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
// import { makeStyles } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import { Container, Grid, Input } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import SaveIcon from '@mui/icons-material/Save';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import { initialState } from '../../initialState'
import currencies from '../../currencies.json'
import { createExpense, getExpense, updateExpense } from '../../actions/expenseActions';
import { getProvidersByUser } from '../../actions/providersActions'
import ExpenseType from './ExpenseType';
import axios from 'axios'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import AddProvider from '../Providers/AddProvider'
// import { useSnackbar } from 'react-simple-snackbar'
import { getStocksByUser } from '../../actions/stockActions'


const useStyles = (theme) => ({
    root: {
        display: 'flex',
        '& > *': {
            // margin: theme.spacing(1),
        },
    },
    large: {
        // width: theme.spacing(12),
        // height: theme.spacing(12),
    },
    table: {
        minWidth: 650,
    },

    headerContainer: {
        // display: 'flex'
        // paddingTop: theme.spacing(1),
        // paddingLeft: theme.spacing(5),
        // paddingRight: theme.spacing(1),
    }
});

const Expense = () => {

    const { t } = useTranslation();
    const location = useLocation()
    const [expenseData, setExpenseData] = useState(initialState)
    const [rates, setRates] = useState(0)
    const [vat, setVat] = useState(0)
    const [currency, setCurrency] = useState(currencies[10].value)
    const [subTotal, setSubTotal] = useState(0)
    const [total, setTotal] = useState(0)
    const today = new Date();
    const [selectedDate, setSelectedDate] = useState();
    const [provider, setProvider] = useState(null)
    const [paymentMethod, setPaymentMethod] = useState('');
    const [type, setType] = useState('')
    const [status, setStatus] = useState('')
    const { id } = useParams()
    const providers = useSelector((state) => state.providers.providers)
    const { expense } = useSelector((state) => state.expenses); //check this line - expense or expenses?
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const user = JSON.parse(localStorage.getItem('profile'))
    const paidUser = user?.result?.paid
    // const [openSnackbar, closeSnackbar] = useSnackbar()
    const stocks = useSelector((state) => state.stocks.stocks)


    useEffect(() => {
        getTotalCount()
        // eslint-disable-next-line
    }, [location])


    const getTotalCount = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/expenses/count?searchQuery=${user?.result?._id}`);
            //Get total count of expense from the server and increment by one to serialized numbering of expense
            setExpenseData({ ...expenseData, expenseNumber: (Number(response.data) + 1).toString().padStart(3, '0') })
        } catch (error) {
            console.error(error);
        }
    }


    useEffect(() => {
        dispatch(getStocksByUser({ search: user?.result?._id || user.result.googleId }));
    }, [dispatch])

    useEffect(() => {
        dispatch(getExpense(id));
        // eslint-disable-next-line
    }, [id]);

    useEffect(() => {
        dispatch(getProvidersByUser({ search: user?.result._id || user?.result?.googleId }));
        // eslint-disable-next-line
    }, [dispatch]);


    useEffect(() => {
        if (expense) {
            //Automatically set the default expense values as the ones in the expense to be updated
            setExpenseData(expense)
            setRates(expense.rates)
            setProvider(expense.client)
            setType(expense.type)
            setStatus(expense.status)
            setSelectedDate(expense.dueDate)
            setPaymentMethod(expense.paymentMethod)
        }
    }, [expense])


    useEffect(() => {
        if (type === 'Receipt') {
            setStatus('Paid')
        } else {
            setStatus('Unpaid')
        }
    }, [type])

    const defaultProps = {
        options: currencies,
        getOptionLabel: (option) => option.label
    };

    const providersProps = {
        options: providers,
        getOptionLabel: (option) => option.name
    };


    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const handleRates = (e) => {
        setRates(e.target.value)
        setExpenseData((prevState) => ({ ...prevState, tax: e.target.value }))
    }

    // Change handler for dynamically added input field
    const handleChange = (index, e) => {
        const values = [...expenseData.items]
        values[index][e.target.name] = e.target.value
        setExpenseData({ ...expenseData, items: values })

    }

    const handleAutocompleteChange = (index, newValue) => {
        const values = [...expenseData.items];
        values[index].itemName = newValue.name; // or use the appropriate property from your `stocks` data
        values[index].itemId = newValue._id; // if you need to store the ID
        setExpenseData({ ...expenseData, items: values });
    };

    useEffect(() => {
        //Get the subtotal
        const subTotal = () => {
            var arr = document.getElementsByName("amount");
            var subtotal = 0;
            for (var i = 0; i < arr.length; i++) {
                if (arr[i].value) {
                    subtotal += +arr[i].value;
                }
                // document.getElementById("subtotal").value = subtotal;
                setSubTotal(subtotal)
            }
        }

        subTotal()

    }, [expenseData])


    useEffect(() => {
        const total = () => {

            //Tax rate is calculated as (input / 100 ) * subtotal + subtotal 
            const overallSum = rates / 100 * subTotal + subTotal
            //VAT is calculated as tax rates /100 * subtotal
            setVat(rates / 100 * subTotal)
            setTotal(overallSum)


        }
        total()
    }, [expenseData, rates, subTotal])


    const handleAddField = (e) => {
        e.preventDefault()
        setExpenseData((prevState) => ({ ...prevState, items: [...prevState.items, { itemName: '', unitPrice: Number, quantity: Number, discount: '', amount: Number }] }))
    }

    const handleRemoveField = (index) => {
        const values = expenseData.items
        values.splice(index, 1)
        setExpenseData((prevState) => ({ ...prevState, values }))
    }


    const handleSubmit = async (e) => {
        e.preventDefault()
        if (expense) {
            dispatch(updateExpense(expense._id, {
                ...expenseData,
                subTotal: subTotal,
                total: total,
                vat: vat,
                rates: rates,
                currency: currency,
                dueDate: selectedDate,
                provider,
                paymentMethod,
                type: type,
                status: status,
            },))
            navigate(`/expense/${expense._id}`)
        } else {

            dispatch(createExpense(
                {
                    ...expenseData,
                    subTotal: subTotal,
                    total: total,
                    vat: vat,
                    rates: rates,
                    currency: currency,
                    dueDate: selectedDate,
                    expenseNumber: `${expenseData.expenseNumber < 100 ?
                        (Number(expenseData.expenseNumber)).toString().padStart(3, '0')
                        : Number(expenseData.expenseNumber)
                        }`,
                    provider,
                    paymentMethod,
                    type: type,
                    status: status,
                    paymentRecords: [],
                    creator: [user?.result?._id || user?.result?.googleId]
                },

            ))
                .then(() => window.location.href = "/expenses")
        }
    }

    const classes = useStyles()
    const [open, setOpen] = useState(false);

    const CustomPaper = (props) => {
        return <Paper elevation={3} {...props} />;
    };


    if (!user) {
        navigate('/login')
    }

    if (user && paidUser === 'none') {
        navigate('/my-plan')
    }

    const paymentMethods = [
        'Transferencia Bancaria',
        'Efectivo',
        'Cuenta Corriente',
        'Tarjeta de Crédito',
        'Mercado Pago',
        'Paypal',
        'Otros',
    ]


    return (

        <div className={styles.expenseLayout}>
            <form onSubmit={handleSubmit} className="mu-form">
                <AddProvider setOpenProviderForm={setOpen} openProviderForm={open} />
                <Container className={classes.headerContainer}>

                    <Grid container justifyContent="space-between" >
                        <Grid item>
                            {/* <Avatar alt="Logo" variant='square' src="" className={classes.large} /> */}
                        </Grid>
                        <Grid item>
                            <ExpenseType type={type} setType={setType} />
                            {/* <h1>Recibo</h1> */}
                            {t('invoiceNumber')} #:
                            <div style={{
                                marginTop: '15px',
                                width: '100px',
                                padding: '8px',
                                display: 'inline-block',
                                backgroundColor: '#f4f4f4',
                                outline: '0px solid transparent'
                            }}
                                contenteditable="true"
                                onInput={e => setExpenseData({
                                    ...expenseData, expenseNumber: e.currentTarget.textContent
                                })
                                }
                            >
                                <span style={{
                                    width: '40px',
                                    color: 'black',
                                    padding: '15px',
                                }}
                                    contenteditable="false"> {expenseData.expenseNumber}</span>
                                <br />
                            </div>
                        </Grid>
                    </Grid >
                </Container>
                <Divider />
                <Container>
                    <Grid container justifyContent="space-between" style={{ marginTop: '40px' }} >
                        <Grid item style={{ width: '50%' }}>
                            <Container>
                                <Typography variant="overline" style={{ color: 'gray', paddingRight: '3px' }} gutterBottom>{t('billTo')}</Typography>


                                {provider && (
                                    <>
                                        <Typography variant="subtitle2" gutterBottom>{provider.name}</Typography>
                                        <Typography variant="body2" >{provider.email}</Typography>
                                        <Typography variant="body2" >{provider.phone}</Typography>
                                        <Typography variant="body2">{provider.address}</Typography>
                                        <Button color="primary" size="small" style={{ textTransform: 'none' }} onClick={() => setProvider(null)}>Cambiar</Button>
                                    </>
                                )}
                                <div style={provider ? { display: 'none' } : { display: 'block' }}>
                                    <Autocomplete
                                        {...providersProps}
                                        PaperComponent={CustomPaper}
                                        renderInput={(params) => <TextField {...params}
                                            required={!expense && true}
                                            label='Seleccionar Proveedor'
                                            margin="normal"
                                            variant="outlined"
                                        />}
                                        value={providers?.name}
                                        onChange={(event, value) => setProvider(value)}

                                    />

                                </div>
                                {!provider &&
                                    <>
                                        <Grid item style={{ paddingBottom: '10px' }}>
                                            <Chip
                                                avatar={<Avatar>+</Avatar>}
                                                label='Nuevo Proveedor'
                                                onClick={() => setOpen(true)}
                                                variant="outlined"
                                            />
                                        </Grid>
                                    </>
                                }
                            </Container>
                        </Grid>

                        <Grid item style={{ marginRight: 20, textAlign: 'right' }}>
                            <Typography variant="overline" style={{ color: 'gray' }} gutterBottom>{t('status')}</Typography>
                            <Typography variant="h6" gutterBottom style={{ color: (type === t('receipt') ? 'green' : 'red') }}>{(type === t('receipt') ? t('paid') : t('unpaid'))}</Typography>
                            {/* <Typography variant="h6" gutterBottom style={{ color: 'green' }}>Pago</Typography> */}
                            <Typography variant="overline" style={{ color: 'gray' }} gutterBottom>{t('date')}</Typography>
                            <Typography variant="body2" gutterBottom>{moment().format("MMM Do YYYY")}</Typography>
                            <Typography variant="overline" style={{ color: 'gray' }} gutterBottom>{t('dueDate')}</Typography>
                            <Typography variant="body2" gutterBottom>{selectedDate ? moment(selectedDate).format("MMM Do YYYY") : ''}</Typography>
                            <Typography variant="overline" gutterBottom>{t('priceAmount')}</Typography>
                            <Typography variant="h6" gutterBottom>{currency} {toCommas(total)}</Typography>
                        </Grid>
                    </Grid>
                </Container>


                <div>

                    <TableContainer component={Paper} className="tb-container">
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('item')}</TableCell>
                                    <TableCell>{t('quantity')}</TableCell>
                                    <TableCell >{t('price')}</TableCell>
                                    <TableCell >{t('discount')}</TableCell>
                                    <TableCell >{t('totalPrice')}</TableCell>
                                    <TableCell >{t('action')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {expenseData.items.map((itemField, index) => (
                                    <TableRow key={index}>
                                        <TableCell scope="row" style={{ width: '40%' }}>
                                            <Autocomplete
                                                options={stocks}
                                                getOptionLabel={(option) => option.name}
                                                onChange={(event, newValue) => handleAutocompleteChange(index, newValue)}
                                                renderInput={(params) => (
                                                    <TextField {...params} label='Elegir Producto' variant="outlined" />
                                                )}
                                                style={{ marginTop: '20px' }}
                                            />
                                            {/* <InputBase style={{ width: '100%' }} outline="none" sx={{ ml: 1, flex: 1 }} type="text" name="itemName" onChange={e => handleChange(index, e)} value={itemField.itemName} placeholder={t('itemDesc')} /> */}
                                        </TableCell>
                                        <TableCell align="right"> <InputBase sx={{ ml: 1, flex: 1 }} type="number" name="quantity" onChange={e => handleChange(index, e)} value={itemField.quantity} placeholder="0" /> </TableCell>
                                        <TableCell align="right"> <InputBase sx={{ ml: 1, flex: 1 }} type="number" name="unitPrice" onChange={e => handleChange(index, e)} value={itemField.unitPrice} placeholder="0" /> </TableCell>
                                        <TableCell align="right"> <InputBase sx={{ ml: 1, flex: 1 }} type="number" name="discount" onChange={e => handleChange(index, e)} value={itemField.discount} placeholder="0" /> </TableCell>
                                        <TableCell align="right"> <InputBase sx={{ ml: 1, flex: 1 }} type="number" name="amount" onChange={e => handleChange(index, e)} value={(itemField.quantity * itemField.unitPrice) - (itemField.quantity * itemField.unitPrice) * itemField.discount / 100} disabled /> </TableCell>
                                        <TableCell align="right">
                                            <IconButton onClick={() => handleRemoveField(index)}>
                                                <DeleteOutlineRoundedIcon style={{ width: '20px', height: '20px' }} />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div className={styles.addButton}>
                        <button onClick={handleAddField}>+</button>
                    </div>
                </div>

                <div className={styles.expenseSummary}>
                    <div className={styles.summary}>{t('invoiceSummary')}</div>
                    <div className={styles.summaryItem}>
                        <p>{t('subTotal')}:</p>
                        <h4>{subTotal}</h4>
                    </div>
                    <div className={styles.summaryItem}>
                        <p>{t('vat')}:</p>
                        <h4>{vat}</h4>
                    </div>
                    <div className={styles.summaryItem}>
                        <p>{t('finalPrice')}</p>
                        <h4 style={{ color: "black", fontSize: "18px", lineHeight: "8px" }}>{currency} {toCommas(total)}</h4>
                    </div>

                </div>


                <div className={styles.toolBar}>
                    <Container>
                        <Grid container >

                            <Grid item style={{ marginTop: '16px', marginRight: 10 }}>
                                <TextField
                                    type="text"
                                    step="any"
                                    name="rates"
                                    id="rates"
                                    value={rates}
                                    onChange={handleRates}
                                    placeholder="e.g 10"
                                    label={t('taxRates')}

                                />
                            </Grid>

                            <Grid item style={{ marginRight: 10, marginTop: '17px' }} >
                                <Input
                                    type='date'
                                    id="date"
                                    label={t('dueDate')}
                                    value={selectedDate}
                                    onChange={(e) => handleDateChange(e.target.value)}
                                    style={{ border: '1px solid gray', borderRadius: '3px', height: '53px', padding: '5px' }}
                                />
                            </Grid>

                            <Grid item style={{ width: 270, marginRight: 10 }}>
                                <Autocomplete
                                    {...defaultProps}
                                    id="debug"
                                    debug
                                    renderInput={(params) => <TextField {...params}
                                        label={t('selectCurrency')}
                                        margin="normal"
                                    />}
                                    value={currency.value}
                                    onChange={(event, value) => setCurrency(value.value)}
                                />
                            </Grid>
                        </Grid>

                    </Container>
                </div>
                <div className={styles.note}>
                    <h4 className='text-gray-500'>{t('paymentInfo')}</h4>
                    <textarea
                        style={{ border: 'solid 1px #d6d6d6', padding: '10px' }}
                        placeholder={t('paymentInfoPlaceholder')}
                        onChange={(e) => setExpenseData({ ...expenseData, notes: e.target.value })}
                        value={expenseData.notes}
                        className='bg-white'
                    />
                </div>

                {/* <button className={styles.submitButton} type="submit">Save and continue</button> */}
                <Grid container justifyContent="center">
                    <Button
                        variant="contained"
                        style={{ justifyContentContent: 'center', marginTop: '25px' }}
                        type="submit"
                        color="primary"
                        size="large"
                        className={classes.button}
                        startIcon={<SaveIcon />}
                    >
                        {t('submitButton')}
                    </Button>
                </Grid>
            </form>
        </div>
    )
}

export default Expense
