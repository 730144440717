import React from 'react'
import styles from './Pricing.module.css';
import { useNavigate } from 'react-router-dom';
import MercadoPago from './mpLogo';

const Prices = () => {

    const user = JSON.parse(localStorage.getItem('profile'));
    const paidUser = user?.result?.paid
    const navigate = useNavigate();

    return (
        <div className='lg:h-[100vh] mb-20'>
            {user && paidUser !== 'none' &&
                <section>
                    <div class="px-8 py-16 mx-auto md:px-12 lg:px-32 max-w-7xl">
                        <h2 className='text-xl text-gray-600 font-bold'>Mi plan: <b className='text-black uppercase'>{paidUser}</b></h2>
                    </div>
                </section>
            }
            <section className='md:py-0 lg:py-10'>
                <div class="px-8 mx-auto md:px-12 lg:px-32 max-w-7xl">
                    <div>
                        <h1 class="text-4xl font-semibold tracking-tighter text-gray-900">
                            Planes
                            <span class="text-gray-600"> para todas las necesidades</span>
                        </h1>
                        <p class="mt-4 text-base font-medium text-gray-500 italic">
                            Elige el plan que mejor se adapte a tu negocio.
                            <span class="md:block">Opciones flexibles para particulares y pequeños y medianos comercios.</span>
                        </p>
                        {/* <div class="md:max-w-sm">
                                <div class="inline-flex w-full border rounded-lg mt-6 overflow-hidden bg-chalk p-0.5 z-0">
                                    <button class="block w-full px-8 py-2 text-xs font-medium transition border border-transparent rounded-md bg-gray-100 text-black" type="button">
                                        Mensual
                                    </button>
                                    <button class="block w-full px-8 py-2 text-xs font-medium transition border border-transparent rounded-md" type="button">
                                        Anual
                                    </button>
                                </div>
                            </div> */}
                    </div>
                    <div class="">
                        <div class="grid gap-2 p-2 mt-4 overflow-hidden border md:grid-cols-2 rounded-3xl lg:grid-cols-3">
                            <div class="flex flex-col justify-between h-full p-8 bg-white border shadow-2xl rounded-2xl">
                                <div>
                                    <span class="text-lg font-medium text-black">Hobby</span>
                                    <div class="mt-4">
                                        <span class="text-xl text-black">
                                            {/* <span x-text="annual ? '$7.00' : '$15.00'">$10.000 / USD 10</span> */}
                                            <span x-text="annual ? '$7.00' : '$15.00'">$10.000</span>
                                        </span>
                                        <span class="text-sm font-medium text-gray-500">
                                            /m (IVA incluido)
                                            <span x-show="annual" style={{ "display": "none" }}>
                                                (billed anually)
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div>
                                    <p class="mt-12 text-lg font-medium text-black">Incluye</p>
                                    <ul class="gap-4 mt-4 space-y-3 text-sm font-medium text-gray-500 list-none lg:gap-6" role="list">
                                        <li class="flex items-center gap-2">
                                            ⏤ <span> Clientes Ilimitados </span>
                                        </li>
                                        <li class="flex items-center gap-2">
                                            ⏤ <span> Proveedores Ilimitados </span>
                                        </li>
                                        <li class="flex items-center gap-2">
                                            ⏤ <span> Lista de Precios y Stock </span>
                                        </li>
                                        <li class="flex items-center gap-2">
                                            ⏤ <span> Compras y Ventas </span>
                                        </li>
                                        <li class="flex items-center gap-2">
                                            ⏤ <span> Seguimiento de Ingresos y Gastos </span>
                                        </li>

                                        <li class="flex items-center gap-2">
                                            ⏤ <span> Cargas masivas e ilimitadas de productos con archivos Excel </span>
                                        </li>

                                        <li class="flex items-center gap-2">
                                            ⏤ <span> Reportes en formato Excel </span>
                                        </li>
                                    </ul>
                                    <div class="flex w-full mt-6">
                                        {user ?
                                            <div className='flex flex-column'>
                                                <span className='text-center mb-2 text-gray-500 text-sm'>Pagar con</span>
                                                <div>
                                                    <a className={styles.button} href="https://www.mercadopago.com.ar/subscriptions/checkout?preapproval_plan_id=2c938084904f7caf019057af1164033c" name="MP-payButton"><span className='flex flex-row gap-1'>MercadoPago (Argentina) <MercadoPago /></span></a>
                                                    {/* <div style={{ marginTop: '20px' }} id="paypal-button-container-P-22P106889V735800RM2QADRY"></div> */}
                                                </div>
                                            </div>
                                            :
                                            <button onClick={() => navigate('/login')} class="text-white inline-flex items-center justify-center w-full h-12 gap-3 px-5 py-3 font-medium duration-200 bg-gray-500 rounded-xl hover:bg-gray-400 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500" aria-describedby="planDescription" aria-label="Button" type="button">
                                                Empezar
                                            </button>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div class="flex flex-col justify-between h-full p-8 bg-white border shadow-2xl rounded-2xl">
                                <div>
                                    <span class="text-lg font-medium text-black">Pro</span>
                                    <div class="mt-4">
                                        <span class="text-xl text-black">
                                            {/* <span x-text="annual ? '$15.00' : '$30.00'">$20.000 / USD 20</span> */}
                                            <span x-text="annual ? '$15.00' : '$30.00'">$20.000</span>
                                        </span>
                                        <span class="text-sm font-medium text-gray-500">
                                            /m (IVA incluido)
                                            <span x-show="annual" style={{ "display": "none" }}>
                                                (billed anually)
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div>
                                    <p class="mt-12 text-lg font-bold text-green-500">14 días de prueba gratis</p>
                                    <p class="mt-12 text-lg font-medium text-black">Incluye</p>
                                    <ul class="gap-4 mt-4 space-y-3 text-sm font-medium text-gray-500 list-none lg:gap-6" role="list">
                                        <li class="flex items-center gap-2">
                                            <span>Todas las funcionalidades de Hobby más:</span>
                                        </li>

                                        <li class="flex items-center gap-2">
                                            ⏤ <span> Soporte por Email Lun/Vie </span>
                                        </li>


                                        <li class="flex items-center gap-2">
                                            ⏤ <span> Vinculación con Mercado Pago </span>
                                        </li>


                                        <li class="flex items-center gap-2">
                                            ⏤ <span> Sistema de Cajas </span>
                                        </li>

                                    </ul>
                                    <div class="flex w-full mt-6">
                                        {user ?
                                            <div className='flex flex-column'>
                                                <span className='text-center mb-2 text-gray-500 text-sm'>Pagar con</span>
                                                <div>
                                                    <a className={styles.button} href="https://www.mercadopago.com.ar/subscriptions/checkout?preapproval_plan_id=2c93808490edce280190ef98f7340059" name="MP-payButton"><span className='flex flex-row gap-1'>MercadoPago (Argentina) <MercadoPago /></span></a>
                                                    {/* <div style={{ marginTop: '20px' }} id="paypal-button-container-P-22P106889V735800RM2QADRY"></div> */}
                                                </div>
                                            </div>
                                            :
                                            <button onClick={() => navigate('/login')} class="text-white inline-flex items-center justify-center w-full h-12 gap-3 px-5 py-3 font-medium duration-200 bg-gray-800 rounded-xl hover:bg-gray-700 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500" aria-describedby="planDescription" aria-label="Button" type="button">
                                                Empezar
                                            </button>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div class="flex flex-col justify-between h-full p-8 bg-white border shadow-2xl rounded-2xl">
                                <div>
                                    <span class="text-lg font-medium text-black">Business</span>
                                    <div class="mt-4">
                                        <span class="text-xl text-black">
                                            {/* <span x-text="annual ? '$30.00' : '$60.00'">$40.000 / USD 40</span> */}
                                            <span x-text="annual ? '$30.00' : '$60.00'">$40.000</span>
                                        </span>
                                        <span class="text-sm font-medium text-gray-500">
                                            /m (IVA incluido)
                                            <span x-show="annual" style={{ "display": "none" }}>
                                                (billed anually)
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div>
                                    <p class="mt-12 text-lg font-bold text-green-500">14 días de prueba gratis</p>
                                    <p class="mt-12 text-lg font-medium text-black">Incluye</p>
                                    <ul class="gap-4 mt-4 space-y-3 text-sm font-medium text-gray-500 list-none lg:gap-6" role="list">
                                        <li class="flex items-center gap-2">
                                            <span>Todas las funcionalidades de Pro más:</span>
                                        </li>

                                        <li class="flex items-center gap-2">
                                            ⏤ <span> Línea de Soporte Dedicado 24/7 </span>
                                        </li>

                                        <li class="flex items-center gap-2">
                                            ⏤ <span> Buk AI: consultas sobre tu rubro en tiempo real </span>
                                        </li>

                                        <li class="flex items-center gap-2">
                                            ⏤ <span> Funcionalidades personalizadas </span>
                                        </li>
                                    </ul>
                                    <div class="flex w-full mt-6">
                                        {user ?
                                            <div className='flex flex-column'>
                                                <span className='text-center mb-2 text-gray-500 text-sm'>Pagar con</span>
                                                <div>
                                                    <a className={styles.button} href="https://www.mercadopago.com.ar/subscriptions/checkout?preapproval_plan_id=2c93808490edce740190effc186d0079" name="MP-payButton"><span className='flex flex-row gap-1'>MercadoPago (Argentina) <MercadoPago /></span></a>
                                                    {/* <div style={{ marginTop: '20px' }} id="paypal-button-container-P-22P106889V735800RM2QADRY"></div> */}
                                                </div>
                                            </div>
                                            :
                                            <button onClick={() => navigate('/login')} class="text-white inline-flex items-center justify-center w-full h-12 gap-3 px-5 py-3 font-medium duration-200 bg-gray-500 rounded-xl hover:bg-gray-400 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500" aria-describedby="planDescription" aria-label="Button" type="button">
                                                Empezar
                                            </button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Prices