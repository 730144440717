import { ADD_NEW_CASHBOX, ALL_CASHBOXES, CLOSE_CASHBOX, DELETE_CASHBOX, END_LOADING, FETCH_CASHBOX, FETCH_CASHBOXES_BY_USER, START_LOADING, UPDATE_CASHBOX } from "../actions/constants";

const cashboxes = (state = { isLoading: true, cashboxes: [] }, action) => {
    switch (action.type) {
      case START_LOADING:
        return { ...state, isLoading: true };
      case END_LOADING:
        return { ...state, isLoading: false };
      case ALL_CASHBOXES:
        return {
          ...state,
          cashboxes: action.payload.data,
          currentPage: action.payload.currentPage,
          numberOfPages: action.payload.numberOfPages,
        };
      case FETCH_CASHBOXES_BY_USER:
        return { ...state, cashboxes: action.payload };

      case FETCH_CASHBOX:
        return { ...state, cashboxe: action.payload.cashboxe };
      case ADD_NEW_CASHBOX:
        return { ...state, cashboxes: [...state.cashboxes, action.payload] };
      case UPDATE_CASHBOX:
        return { ...state, cashboxes: state.cashboxes.map((cashbox) => (cashbox._id === action.payload._id ? action.payload : cashbox)) };
      case DELETE_CASHBOX:
        return { ...state, cashboxes: state.cashboxes.filter((cashbox) => cashbox._id !== action.payload) };
      case CLOSE_CASHBOX:
        return { ...state, cashboxes: state.cashboxes.filter((cashbox) => cashbox._id !== action.payload) };
      default:
        return state;
    }
  };

  export default cashboxes