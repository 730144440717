import React, { useEffect, useState } from 'react'
import { toCommas } from '../../utils/utils'
import styles from './Dashboard.module.css'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { getInvoicesByUser } from '../../actions/invoiceActions'
import Empty from '../svgIcons/Empty'
import Chart from './Chart'
import Donut from './Donut'
import moment from 'moment'
import { Check, Pie, Bag, Card, Clock, Frown } from './Icons'
import Spinner from '../Spinner/Spinner'
import { getExpensesByUser } from '../../actions/expenseActions'
import ReactChart from './ReactChart'
import MercadoPagoTab from './MercadoPago'
import { Tabs, Tab, Box } from '@mui/material';
import CashBoxes from './Cashboxes'


const Dashboard = () => {

    const { t } = useTranslation();
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const user = JSON.parse(localStorage.getItem('profile'))
    const paidUser = user?.result?.paid
    const { invoices, isLoading } = useSelector((state) => state?.invoices)
    const { expenses, _ } = useSelector((state) => state?.expenses)
    // const unpaid = invoices?.filter((invoice) => (invoice.status === 'Unpaid') || (invoice.status === 'Partial'))
    const overDue = invoices?.filter((invoice) => invoice.dueDate <= new Date().toISOString())
    const [tabIndex, setTabIndex] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    let ExpenseHistory = [];
    for (let i = 0; i < expenses.length; i++) {
        if (expenses[i].paymentRecords && Array.isArray(expenses[i].paymentRecords)) {
            expenses[i].paymentRecords.forEach(recordArray => {
                let validDate = new Date(recordArray.datePaid);
                if (isNaN(validDate.getTime())) {
                } else {
                    recordArray.datePaid = validDate.toISOString(); // Normalize the date format if valid
                    ExpenseHistory.push(recordArray);
                }
            });
        }
    }

    //sort expense navigate by date
    const sortExpenseHistoryByDate = ExpenseHistory.sort(function (a, b) {
        var c = new Date(a.datePaid);
        var d = new Date(b.datePaid);
        return d - c;
    });

    let PaymentHistory = [];
    for (let i = 0; i < invoices.length; i++) {
        if (invoices[i].paymentRecords && Array.isArray(invoices[i].paymentRecords)) {
            invoices[i].paymentRecords.forEach(recordArray => {
                let validDate = new Date(recordArray.datePaid);
                if (isNaN(validDate.getTime())) {
                } else {
                    recordArray.datePaid = validDate.toISOString(); // Normalize the date format if valid
                    PaymentHistory.push(recordArray);
                }
            });
        }
    }

    //sort payment navigate by date
    const sortnavigateByDate = PaymentHistory.sort(function (a, b) {
        var c = new Date(a.datePaid);
        var d = new Date(b.datePaid);
        return d - c;
    });


    let totalPaid = 0
    for (let i = 0; i < invoices.length; i++) {
        if (invoices[i].totalAmountReceived !== undefined) {
            totalPaid += invoices[i].totalAmountReceived
        }
    }

    let totalAmount = 0
    for (let i = 0; i < invoices.length; i++) {
        totalAmount += invoices[i].total
    }

    let totalExpense = [];
    for (let i = 0; i < expenses.length; i++) {
        totalExpense.push(expenses[i].total);
    }

    const totalExpenses = totalExpense.reduce((accumulator, currentValue) => {
        return accumulator + currentValue;
    }, 0);

    let paidExpenses = 0;
    for (let i = 0; i < expenses.length; i++) {
        if (expenses[i].totalAmountReceived !== undefined) {
            paidExpenses += expenses[i].totalAmountReceived
        }
    }


    useEffect(() => {
        dispatch(getInvoicesByUser({ search: user?.result._id || user?.result?.googleId }));
        // eslint-disable-next-line
    }, [location, dispatch]);

    useEffect(() => {
        dispatch(getExpensesByUser({ search: user?.result?._id || user?.result?.googleId }));
        // eslint-disable-next-line
    }, [location])


    const unpaidInvoice = invoices?.filter((invoice) => invoice.status === 'Unpaid')
    const paid = invoices?.filter((invoice) => invoice.status === 'Paid')
    const partial = invoices?.filter((invoice) => invoice.status === 'Partial')

    const unpaidExpenses = expenses?.filter((expense) => expense.status === 'Unpaid')
    const paidExpense = expenses?.filter((expense) => expense.status === 'Paid')
    const partialExpense = expenses?.filter((expense) => expense.status === 'Partial')
    const overDueExpenses = expenses?.filter((expense) => expense.dueDate <= new Date().toISOString())

    if (!user) {
        navigate('/login')
    }

    if (user && paidUser === 'none') {
        navigate('/my-plan')
    }


    if (isLoading) {
        return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', paddingTop: '20px' }}>
            <Spinner />
        </div>
    }

    if (invoices.length === 0) {
        return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', paddingTop: '20px' }}>
            {/* <Spinner /> */}
            <Empty />
            <p style={{ padding: '40px', color: 'gray' }}>{t('invoicesError')}</p>
        </div>
    }


    return (
        <div className={styles.pageContainer}>
            <Tabs value={tabIndex} onChange={handleTabChange} centered>
                <Tab label="General" />
                <Tab label="Cajas" />
                <Tab label="Mercado Pago" />
            </Tabs>
            <Box hidden={tabIndex !== 0}>
                {/* General Tab Content */}
                <section className='text-gray-500 mt-10'>
                    <ul className={styles.autoGrid}>
                        <li className={styles.listItem} style={{ backgroundColor: '#1976d2', color: 'white' }}>
                            <div>
                                <p>$ {toCommas(totalPaid)}</p>
                                <h2 style={{ color: 'white' }}>Total Recibido</h2>
                            </div>
                            <div>
                                <Check />
                            </div>
                        </li>

                        <li className={styles.listItem} >
                            <div>
                                <p>$ {toCommas(totalAmount - totalPaid)}</p>
                                <h2>{t('pendingAmount')}</h2>
                            </div>
                            <div>
                                <Pie />
                            </div>
                        </li>

                        <li className={styles.listItem} >
                            <div>
                                <p>$ {toCommas(totalAmount)}</p>
                                <h2>{t('totalAmount')}</h2>
                            </div>
                            <div>
                                <Bag />
                            </div>
                        </li>

                        <li className={styles.listItem} >
                            <div>
                                <p>$ {toCommas(totalExpenses)}</p>
                                <h2>Gastos Totales</h2>
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#EA3323"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M7 11v2h10v-2H7zm5-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" /></svg>
                            </div>
                        </li>

                        <li className={styles.listItem} >
                            <div>
                                <p>$ {toCommas(paidExpenses)}</p>
                                <h2>Gastos Saldados</h2>
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#EA3323"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M7 11v2h10v-2H7zm5-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" /></svg>
                            </div>
                        </li>
                    </ul>
                </section>
                {PaymentHistory.length !== 0 && (
                    <section>
                        <Chart PaymentHistory={PaymentHistory} ExpenseHistory={expenses} totalReceived={totalPaid} paidExpenses={paidExpenses} invoices={invoices} />
                        {/* <Donut /> */}
                        {/* <ReactChart PaymentHistory={PaymentHistory} ExpenseHistory={ExpenseHistory} /> */}
                    </section>
                )}
                <section className='text-gray-500' style={{ marginTop: '100px' }}>
                    <h1 style={{ textAlign: 'center', marginBottom: '50px' }} className='text-2xl font-bold text-gray-500'>Ventas</h1>
                    <ul className={styles.autoGrid}>

                        <li className={styles.listItem} style={{ backgroundColor: '#1976d2', color: 'white' }}>
                            <div>
                                <p>$ {toCommas(totalPaid)}</p>
                                <h2 style={{ color: 'white' }}>Total Recibido</h2>
                            </div>
                            <div>
                                <Check />
                            </div>
                        </li>

                        <li className={styles.listItem} >
                            <div>
                                <p>$ {toCommas(totalAmount - totalPaid)}</p>
                                <h2>{t('pendingAmount')}</h2>
                            </div>
                            <div>
                                <Pie />
                            </div>
                        </li>

                        <li className={styles.listItem} >
                            <div>
                                <p>$ {toCommas(totalAmount)}</p>
                                <h2>{t('totalAmount')}</h2>
                            </div>
                            <div>
                                <Bag />
                            </div>
                        </li>

                        <li className={styles.listItem} >
                            <div>
                                <p>{invoices.length}</p>
                                <h2>{t('totalInvoices')}</h2>
                            </div>
                            <div>
                                <Card />
                            </div>
                        </li>


                        <li className={styles.listItem} style={{ backgroundColor: '#206841', color: 'white' }}>
                            <div>
                                <p>{paid.length}</p>
                                <h2 style={{ color: 'white' }}>{t('paidInvoices')}</h2>
                            </div>
                            <div>
                                <Check />
                            </div>
                        </li>

                        <li className={styles.listItem} >
                            <div>
                                <p>{partial.length}</p>
                                <h2>{t('partiallyPaidInvoices')}</h2>
                            </div>
                            <div>
                                <Pie />
                            </div>
                        </li>

                        <li className={styles.listItem} >
                            <div>
                                <p>{unpaidInvoice.length}</p>
                                <h2>{t('unpaidInvoices')}</h2>
                            </div>
                            <div>
                                <Frown />
                            </div>
                        </li>

                        <li className={styles.listItem} >
                            <div>
                                <p>{overDue.length}</p>
                                <h2>{t('overdue')}</h2>
                            </div>
                            <div>
                                <Clock />
                            </div>
                        </li>

                    </ul>

                    <section>
                        <h1 style={{ textAlign: 'center', padding: '30px' }}>{PaymentHistory.length ? '' : 'No hay ventas aún'}</h1>
                        <div>
                            <div className={styles.table}>

                                <table>
                                    <tbody>
                                        {PaymentHistory.length !== 0 && (
                                            <tr>
                                                <th style={{ padding: '15px' }}></th>
                                                <th style={{ padding: '15px' }}>{t('paidBy')}</th>
                                                <th style={{ padding: '15px' }}>{t('datePaid')}</th>
                                                <th style={{ padding: '15px' }}>{t('amountPaid')}</th>
                                                <th style={{ padding: '15px' }}>{t('paymentMethod')}</th>
                                                <th style={{ padding: '15px' }}>{t('note')}</th>
                                            </tr>
                                        )}

                                        {sortnavigateByDate.slice(-10).map((record) => {
                                            return (
                                                <tr className={styles.tableRow} key={record._id}>
                                                    <td><button>{record?.paidBy?.charAt(0)}</button></td>
                                                    <td>{record.paidBy}</td>
                                                    <td>{moment(record.datePaid).utc().format('DD/MM/YYYY')}</td>
                                                    <td><h3 style={{ fontSize: '14px' }} className='font-bold text-green-500'>$ {toCommas(record.amountPaid)}</h3></td>
                                                    <td>{record.paymentMethod ? record.paymentMethod : 'Caja'}</td>
                                                    <td>{record.note}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </section>

                </section>

                <section className='text-gray-500' style={{ marginTop: '100px' }}>
                    <h1 style={{ textAlign: 'center', marginBottom: '50px' }} className='text-2xl font-bold text-gray-500'>Compras</h1>
                    <ul className={styles.autoGrid}>

                        <li className={styles.listItem} >
                            <div>
                                <p>$ {toCommas(totalExpenses)}</p>
                                <h2>Gastos Totales</h2>
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#EA3323"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M7 11v2h10v-2H7zm5-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" /></svg>
                            </div>
                        </li>

                        <li className={styles.listItem} >
                            <div>
                                <p>$ {toCommas(paidExpenses)}</p>
                                <h2>Gastos Saldados</h2>
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#EA3323"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M7 11v2h10v-2H7zm5-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" /></svg>
                            </div>
                        </li>

                        <li className={styles.listItem} >
                            <div>
                                <p>{expenses.length}</p>
                                <h2>{t('totalInvoices')}</h2>
                            </div>
                            <div>
                                <Card />
                            </div>
                        </li>


                        <li className={styles.listItem} style={{ backgroundColor: '#206841', color: 'white' }}>
                            <div>
                                <p>{paidExpense.length}</p>
                                <h2 style={{ color: 'white' }}>{t('paidInvoices')}</h2>
                            </div>
                            <div>
                                <Check />
                            </div>
                        </li>

                        <li className={styles.listItem} >
                            <div>
                                <p>{partialExpense.length}</p>
                                <h2>{t('partiallyPaidInvoices')}</h2>
                            </div>
                            <div>
                                <Pie />
                            </div>
                        </li>

                        <li className={styles.listItem} >
                            <div>
                                <p>{unpaidExpenses.length}</p>
                                <h2>{t('unpaidInvoices')}</h2>
                            </div>
                            <div>
                                <Frown />
                            </div>
                        </li>

                        <li className={styles.listItem} >
                            <div>
                                <p>{overDueExpenses.length}</p>
                                <h2>{t('overdue')}</h2>
                            </div>
                            <div>
                                <Clock />
                            </div>
                        </li>

                    </ul>

                    <section>
                        <h1 style={{ textAlign: 'center', padding: '30px' }}>{expenses.length ? '' : 'No hay compras aún'}</h1>
                        <div>
                            <div className={styles.table}>

                                <table>
                                    <tbody>
                                        {expenses.length !== 0 && (
                                            <tr>
                                                <th style={{ padding: '15px' }}></th>
                                                <th style={{ padding: '15px' }}>Pagado a</th>
                                                <th style={{ padding: '15px' }}>{t('datePaid')}</th>
                                                <th style={{ padding: '15px' }}>{t('amountPaid')}</th>
                                                <th style={{ padding: '15px' }}>{t('paymentMethod')}</th>
                                                <th style={{ padding: '15px' }}>{t('note')}</th>
                                            </tr>
                                        )}

                                        {sortExpenseHistoryByDate.slice(-10).map((record) => {
                                            return (
                                                <tr className={styles.tableRow} key={record._id}>
                                                    <td><button>{record?.paidBy?.charAt(0)}</button></td>
                                                    <td>{record.paidBy}</td>
                                                    <td>{moment(record.datePaid).utc().format('DD/MM/YYYY')}</td>
                                                    <td><h3 style={{ fontSize: '14px' }} className='font-bold text-red-500'>$ {toCommas(record.amountPaid)}</h3></td>
                                                    <td>{record.paymentMethod}</td>
                                                    <td>{record.note}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </section>
                </section>
            </Box>

            <Box hidden={tabIndex !== 1}>
                {/* Mercado Pago Tab Content */}
                <CashBoxes />
            </Box>
            <Box hidden={tabIndex !== 2}>
                <section className='text-gray-500 mt-10'>
                    <MercadoPagoTab />
                </section>
            </Box>

        </div>
    )
}

export default Dashboard
