import React from 'react';
// import { makeStyles } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { t } from 'i18next';

const useStyles = (theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
});

const InvoiceType = ({ type, setType }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);


  const handleChange = (event) => {
    setType(event.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <p style={{ marginBottom: '-10px', paddingTop: '10px', color: 'gray' }}>{t('selectType')}</p>
      <Button style={{ lineSpacing: 1, fontSize: 35, fontWeight: 700 }} onClick={handleClickOpen}>{type ? type : t('invoice')}</Button>
      <Dialog disableBackdropClick disableEscapeKeyDown open={open} onClose={handleClose}>
        {/* <DialogTitle>Fill the form</DialogTitle> */}
        <DialogContent>
          <div className={classes.container}>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-dialog-select-label">{t('selectButton')}</InputLabel>
              <Select
                labelId="demo-dialog-select-label"
                id="demo-dialog-select"
                value={type}
                onChange={handleChange}
                input={<Input />}
              >
                <MenuItem value={t('invoice')}>{t('invoice')}</MenuItem>
                <MenuItem value={t('receipt')}>{t('receipt')}</MenuItem>
                {/* <MenuItem value="Estimate">Estimate</MenuItem>
                <MenuItem value="Bill">Bill</MenuItem>
                <MenuItem value="Quotation">Quotation</MenuItem> */}
              </Select>
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('cancel')}
          </Button>
          <Button onClick={handleClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default InvoiceType