import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { Button, Paper, Typography, Container, Grid } from '@mui/material';
import useStyles from './styles';
import Field from '../Login/Field';
import { forgot } from '../../actions/auth';
import styles from './Password.module.css'
import { useTranslation } from 'react-i18next';


const Forgot = () => {
  const classes = useStyles();
  const navigate = useNavigate()
  const [form, setForm] = useState("");
  const [step, setStep] = useState(0)
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem('profile'))
  const { t } = useTranslation();

  const handleSubmit = (e) => {
    e.preventDefault()
    dispatch(forgot({ email: form }))
    window.navigator.onLine ? setStep(1) : setStep(2)
  }

  const handleChange = (e) => setForm(e.target.value);

  if (user) navigate('/dashboard')

  return (
    <div style={{ paddingTop: '100px', paddingBottom: '100px' }}>
      <Container component="main" maxWidth="xs">
        <Paper className={classes.paper} variant="outlined">
          {step === 0 && (
            <div className='h-[30vh]'>
              <Typography variant="h6" gutter="5" style={{ display: 'flex', justifyContent: 'center', marginTop: '15px' }}>{t('enterEmailAddress')}</Typography>
              <form className="m-10" noValidate autoComplete="off" onSubmit={handleSubmit}>
                <div className='flex flex-column gap-3'>
                  <Field name="email" label="Email" handleChange={handleChange} type="email" className="mb-5" />
                  <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}> {t('submitEmail')} </Button>
                </div>
              </form>
            </div>
          )}

          {step === 1 && (
            <div>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}> <i className="fas fa-check-circle" style={{ fontSize: '55px', color: '#3e6947' }}></i></div>
              <br />
              <p>{t('emailSent')}</p>
              <div className={styles.buttons}>
                <button className={styles.button} onClick={() => navigate('/')}>{t('backHomeButton')}</button>
                <button className={styles.button} onClick={() => setStep(0)}>{t('resendLinkButton')}</button>
              </div>
            </div>
          )}

          {step === 2 && (
            <div>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}> <i className="fas fa-check-circle" style={{ fontSize: '55px', color: '#3e6947' }}></i></div>
              <br />
              <p>Please check your internet connection and try again</p>
              <div className={styles.buttons}>
                <button className={styles.button} onClick={() => navigate('/')}>Back to home</button>
                <button className={styles.button} onClick={() => setStep(0)}>Resend link</button>
              </div>
            </div>
          )}
        </Paper>
      </Container>
    </div>

  );
}

export default Forgot
