/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

import { useDispatch, useSelector } from 'react-redux'
import { createProvider, updateProvider } from '../../actions/providersActions'
// import { useSnackbar } from 'react-simple-snackbar'
import { useTranslation } from 'react-i18next';
// import { withStyles } from '@mui/material';

const styles = (theme) => ({
  root: {
    margin: 0,
    // padding: theme.spacing(2),
    backgroundColor: '#1976D2',
    marginLeft: 0,
  },
  closeButton: {
    position: 'absolute',
    // right: theme.spacing(1),
    // top: theme.spacing(1),
    color: 'white',
  },
});

const DialogTitle = ((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className="" style={{ margin: '0px', marginLeft: '0px', backgroundColor: '#1976D2' }} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className="" style={{ position: 'absolute', color: 'gray', right: 10, top: 10 }} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = (theme) => ({
  root: {
    // padding: theme.spacing(3),
  },
})(MuiDialogContent);

const DialogActions = (theme) => ({
  root: {
    margin: 0,
    // padding: theme.spacing(1),
  },
})(MuiDialogActions);

const AddProvider = ({ setOpenProviderForm, openProviderForm, currentId, setCurrentId }) => {

  const location = useLocation()
  const [providerData, setProviderData] = useState({ name: '', email: '', phone: '', address: '', products: 0, userId: '' })
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')))
  const dispatch = useDispatch()
  const provider = useSelector((state) => currentId ? state.providers.providers.find((c) => c._id === currentId) : null)
  // eslint-disable-next-line 
  // const [openSnackbar, closeSnackbar] = useSnackbar()
  const { t } = useTranslation();


  useEffect(() => {
    if (provider) {
      setProviderData(provider)
    }
  }, [provider])

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem('profile')))
    // setProviderData({...providerData, userId: user?.result?._id})
  }, [location])


  useEffect(() => {
    var checkId = user?.result?._id
    if (checkId !== undefined) {
      setProviderData({ ...providerData, userId: [checkId] })
    } else {
      setProviderData({ ...providerData, userId: [user?.result?.googleId] })
    }

  }, [location])


  const handleSubmitProvider = (e) => {
    e.preventDefault()
    if (currentId) {
      dispatch(updateProvider(currentId, providerData))
    } else {
      dispatch(createProvider(providerData))
    }

    clear()
    handleClose()
  }

  const clear = () => {
    // setCurrentId(null)
    setProviderData({ name: '', email: '', phone: '', address: '', products: 0, userId: [] })
  }

  const handleClose = () => {
    setOpenProviderForm(false);
  };

  const inputStyle = {
    display: "block",
    padding: "1.4rem 0.75rem",
    width: "100%",
    fontSize: "0.8rem",
    lineHeight: 1.25,
    color: "#55595c",
    backgroundColor: "#fff",
    backgroundImage: "none",
    backgroundClip: "padding-box",
    borderTop: "0",
    borderRight: "0",
    borderBottom: "1px solid #eee",
    borderLeft: "0",
    borderRadius: "3px",
    transition: "all 0.25s cubic-bezier(0.4, 0, 1, 1)"
  }


  return (
    <div>
      <form >
        <Dialog
          onClose={(e, reason) => {
            if (reason !== 'backdropClick') {
              handleClose()
            }
          }}
          aria-labelledby="customized-dialog-title"
          open={openProviderForm} fullWidth
        >
          <DialogTitle id="customized-dialog-title" onClose={handleClose} style={{ paddingLeft: '20px', color: 'gray' }}>
            {currentId ? t('editProvider') : t('newProvider')}
          </DialogTitle>

          <div className="customInputs" style={{ margin: '10px' }}>
            <input
              placeholder={t('name')}
              style={inputStyle}
              name='name'
              type='text'
              onChange={(e) => setProviderData({ ...providerData, name: e.target.value })}
              value={providerData.name}
            />

            <input
              placeholder="Email"
              style={inputStyle}
              name='email'
              type='text'
              onChange={(e) => setProviderData({ ...providerData, email: e.target.value })}
              value={providerData.email}
            />

            <input
              placeholder={t('phone')}
              style={inputStyle}
              name='phone'
              type='text'
              onChange={(e) => setProviderData({ ...providerData, phone: e.target.value })}
              value={providerData.phone}
            />

            <input
              placeholder={t('address')}
              style={inputStyle}
              name='address'
              type='text'
              onChange={(e) => setProviderData({ ...providerData, address: e.target.value })}
              value={providerData.address}
            />
          </div>

          {/* <DialogActions> */}
          <Button autoFocus onClick={handleSubmitProvider} variant="contained" style={{ margin: '10px', marginTop: '10px', marginBottom: '10px' }} >
            {t('submitButton')}
          </Button>
          {/* </DialogActions> */}
        </Dialog>
      </form>
    </div>
  );
}

export default AddProvider