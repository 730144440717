import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { jwtDecode } from 'jwt-decode'
import styles from './Header.module.css'
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
// import { makeStyles } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import { GB } from 'country-flag-icons/react/3x2'
import { ES } from 'country-flag-icons/react/3x2'
// import axios from 'axios'


const useStyles = (theme) => ({
  root: {
    display: 'flex',
  },
  paper: {
    // marginRight: theme.spacing(2),
  },
});



const Header = () => {
  const dispatch = useDispatch()
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')))
  const navigate = useNavigate()
  const location = useLocation()
  const { t, i18n } = useTranslation();
  const englishFlag = <GB title="English" style={{ height: '20px', width: '20px' }} />
  const spanishFlag = <ES title="Spanish" style={{ height: '20px', width: '20px' }} />
  const lngs = {
    en: { nativeName: englishFlag },
    es: { nativeName: spanishFlag }
  };


  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem('profile')))
  }, [location])


  const logout = () => {
    dispatch({ type: 'LOGOUT' })
    navigate('/')
    setUser(null)
  }


  useEffect(() => {
    const token = user?.token
    // setUser(JSON.parse(localStorage.getItem('profile')))
    //If token expires, logout the user
    if (token) {
      const decodedToken = jwtDecode(token)
      if (decodedToken.exp * 1000 < new Date().getTime()) logout()
    }
    // eslint-disable-next-line
  }, [location, user]) //when location changes, set the user

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };


  const openLink = (link) => {
    navigate(`/${link}`)
    setOpen(false);
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  if (!user) return (
    <div className={styles.header2}>
      <span style={{ cursor: 'pointer' }} onClick={() => navigate('/')}>BUK</span>
      {/* <div>
        {Object.keys(lngs).map((lng) => (
          <button key={lng} style={{ border: 'none', marginLeft: '5px' }} type="submit" onClick={() => i18n.changeLanguage(lng)}>
            {lngs[lng].nativeName}
          </button>
        ))}
      </div> */}
      <button onClick={() => navigate('/login')} className="btn text-[16px] text-white lg:w-[120px] mr-5 rounded-3xl">{t('login')}</button>
    </div>
  )

  return (
    <div className={styles.header}>
      {/* <div>
        {Object.keys(lngs).map((lng) => (
          <button key={lng} style={{ border: 'none', marginLeft: '5px' }} type="submit" onClick={() => i18n.changeLanguage(lng)}>
            {lngs[lng].nativeName}
          </button>
        ))}
      </div> */}
      <div className={classes.root}>
        <div>
          <Button
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
          >
            <Avatar style={{ backgroundColor: '#1976D2' }}>{user?.result?.name?.charAt(0)}</Avatar>
          </Button>
          <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
              >
                <Paper elevation={3}>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown} >
                      <MenuItem onClick={() => openLink('settings')}>{t('settings')}</MenuItem>
                      <MenuItem onClick={() => logout()}>{t('logout')}</MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </div>


    </div>
  )
}

export default Header
