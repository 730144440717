import React, { useState, useEffect } from 'react'
import styles from '../Invoice/Invoice.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { toCommas } from '../../utils/utils'

import IconButton from '@mui/material/IconButton';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import { Container, Grid, Input } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import SaveIcon from '@mui/icons-material/Save';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import { initialState } from '../../initialState'
import currencies from '../../currencies.json'
import { createInvoice, getInvoicesByCashbox, getInvoicesByUser, updateInvoice } from '../../actions/invoiceActions';
import { getClientsByUser } from '../../actions/clientActions'
import axios from 'axios'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { getStocksByUser } from '../../actions/stockActions'

import { toast } from 'react-toastify'
import Ticket from '../Ticket'

const useStyles = (theme) => ({
    root: {
        display: 'flex',
        '& > *': {
        },
    },
    large: {

    },
    table: {
        minWidth: 650,
    },

    headerContainer: {
    }
});

const CashRegister = ({ cashBoxId, cashier }) => {

    const { t } = useTranslation();
    const location = useLocation()
    const [invoiceData, setInvoiceData] = useState(initialState)
    const [rates, setRates] = useState(0)
    const [vat, setVat] = useState(0)
    const [currency, setCurrency] = useState(currencies[10].value)
    const [subTotal, setSubTotal] = useState(0)
    const [total, setTotal] = useState(0)

    const [client, setClient] = useState(null)
    const [sale, setSale] = useState(null);

    const { id } = useParams()
    const clients = useSelector((state) => state.clients.clients)
    const { invoice } = useSelector((state) => state.invoices);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const user = JSON.parse(localStorage.getItem('profile'))
    const paidUser = user?.result?.paid
    const stocks = useSelector((state) => state.stocks.stocks)

    useEffect(() => {
        dispatch(getClientsByUser({ search: user?.result._id || user?.result?.googleId }));
        // eslint-disable-next-line
    }, [dispatch]);

    useEffect(() => {
        dispatch(getStocksByUser({ search: user?.result?._id || user.result.googleId }));
    }, [dispatch])

    const defaultProps = {
        options: currencies,
        getOptionLabel: (option) => option.label
    };

    const clientsProps = {
        options: clients,
        getOptionLabel: (option) => option.name
    };

    const handleRates = (e) => {
        setRates(e.target.value)
        setInvoiceData((prevState) => ({ ...prevState, tax: e.target.value }))
    }

    // Change handler for dynamically added input field
    const handleChange = (index, e) => {
        const values = [...invoiceData.items]
        values[index][e.target.name] = e.target.value
        setInvoiceData({ ...invoiceData, items: values })

    }

    const handleAutocompleteChange = (index, newValue) => {
        const values = [...invoiceData.items];
        values[index].itemName = newValue?.name; // or use the appropriate property from your `stocks` data
        values[index].itemId = newValue?._id; // if you need to store the ID
        setInvoiceData({ ...invoiceData, items: values });
    };

    const handleInputChange = (index, event, value) => {
        const values = [...invoiceData.items];
        if (value) {
            values[index].itemName = value;
            values[index].itemId = null;
        }
        setInvoiceData({ ...invoiceData, items: values });
    };


    useEffect(() => {
        //Get the subtotal
        const subTotal = () => {
            var arr = document.getElementsByName("amount");
            var subtotal = 0;
            for (var i = 0; i < arr.length; i++) {
                if (arr[i].value) {
                    subtotal += +arr[i].value;
                }
                // document.getElementById("subtotal").value = subtotal;
                setSubTotal(subtotal)
            }
        }

        subTotal()

    }, [invoiceData])


    useEffect(() => {
        const total = () => {

            //Tax rate is calculated as (input / 100 ) * subtotal + subtotal 
            const overallSum = rates / 100 * subTotal + subTotal
            //VAT is calculated as tax rates /100 * subtotal
            setVat(rates / 100 * subTotal)
            setTotal(overallSum)

        }
        total()
    }, [invoiceData, rates, subTotal])


    const handleAddField = (e) => {
        e.preventDefault()
        setInvoiceData((prevState) => ({ ...prevState, items: [...prevState.items, { itemName: '', unitPrice: Number, quantity: Number, discount: '', amount: Number }] }))
    }

    const handleRemoveField = (index) => {
        const values = invoiceData.items
        values.splice(index, 1)
        setInvoiceData((prevState) => ({ ...prevState, values }))
    }


    const handleSubmit = async (e) => {
        e.preventDefault()
        if (invoice) {
            dispatch(updateInvoice(invoice._id, {
                ...invoiceData,
                subTotal: subTotal,
                total: total,
                vat: vat,
                rates: rates,
                currency: currency,
                client,
            },))
            navigate(`/invoice/${invoice._id}`)
        } else {

            await dispatch(createInvoice(
                {
                    ...invoiceData,
                    subTotal: subTotal,
                    total: total,
                    vat: vat,
                    rates: rates,
                    currency: currency,
                    invoiceNumber: `${invoiceData.invoiceNumber < 100 ?
                        (Number(invoiceData.invoiceNumber)).toString().padStart(3, '0')
                        : Number(invoiceData.invoiceNumber)
                    }`,
                    client: client || '',
                    paymentRecords: [{
                        amountPaid: total,
                        datePaid: new Date(),
                        paymentMethod: '',
                        note: '',
                        paidBy: client?.name || ''
                    }],
                    status: 'Paid',
                    cashBoxId: cashBoxId,
                    creator: [user?.result?._id || user?.result?.googleId],
                    totalAmountReceived: total
                }
            )).then((action => {
                const newInvoiceId = action.data._id;
                const newSale = {
                    id: newInvoiceId,
                    businessName: user?.userProfile?.businessName,
                    location: user?.userProfile?.contactAddress,
                    cashBoxId: cashBoxId,
                    cashier: cashier || 'ADMIN',
                    client: client?.name || '---',
                    date: new Date().toLocaleString(),
                    items: invoiceData.items,
                    status: 'Paid',
                    total: total,
                    qrCodeValue: `https://www.buk.lat/invoice/${newInvoiceId}`, // replace with actual value
                };
                setSale(newSale);
            }))
        }
    }

    const classes = useStyles()
    const [open, setOpen] = useState(false);

    const CustomPaper = (props) => {
        return <Paper elevation={3} {...props} />;
    };


    if (!user) {
        navigate('/login')
    }


    if (user && paidUser === 'none') {
        navigate('/my-plan')
    }


    return (

        <div className='border rounded-md border-gray-700'>
            <form onSubmit={handleSubmit} className="mu-form">
                <Divider />
                <Container>
                    <Grid container justifyContent="space-between" style={{ marginTop: '40px' }} >
                        <Grid item style={{ width: '50%' }}>
                            <Container>
                                <Typography variant="overline" style={{ color: 'gray', paddingRight: '3px' }} gutterBottom>cliente (opcional)</Typography>


                                {client && (
                                    <>
                                        <Typography variant="subtitle2" gutterBottom>{client.name}</Typography>
                                        <Typography variant="body2" >{client.email}</Typography>
                                        <Typography variant="body2" >{client.phone}</Typography>
                                        <Typography variant="body2">{client.address}</Typography>
                                        <Button color="primary" size="small" style={{ textTransform: 'none' }} onClick={() => setClient(null)}>Cambiar</Button>
                                    </>
                                )}
                                <div style={client ? { display: 'none' } : { display: 'block' }}>
                                    <Autocomplete
                                        {...clientsProps}
                                        PaperComponent={CustomPaper}
                                        renderInput={(params) => <TextField {...params}
                                            // required={!invoice && true}
                                            label={t('selectCustomer')}
                                            margin="normal"
                                            variant="outlined"
                                        />}
                                        value={clients?.name}
                                        onChange={(event, value) => setClient(value)}

                                    />

                                </div>
                                {!client &&
                                    <>
                                        <Grid item style={{ paddingBottom: '10px' }}>
                                            <Chip
                                                avatar={<Avatar>+</Avatar>}
                                                label={t('selectNewCustomer')}
                                                onClick={() => setOpen(true)}
                                                variant="outlined"
                                            />
                                        </Grid>
                                    </>
                                }
                            </Container>
                        </Grid>
                        <Grid >
                            <div className={styles.summary}>{t('invoiceSummary')}</div>
                            <div className={styles.summaryItem}>
                                <p>{t('subTotal')}:</p>
                                <h4>{subTotal}</h4>
                            </div>
                            <div className={styles.summaryItem}>
                                <p>{t('vat')}:</p>
                                <h4>{vat}</h4>
                            </div>
                            <div className={styles.summaryItem}>
                                <p>{t('finalPrice')}</p>
                                <h4 style={{ color: "black", fontSize: "18px", lineHeight: "8px" }}>{currency} {toCommas(total)}</h4>
                            </div>

                        </Grid>
                    </Grid>
                </Container>


                <div>
                    <TableContainer component={Paper} className="tb-container">
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('item')}</TableCell>
                                    <TableCell>{t('quantity')}</TableCell>
                                    <TableCell >{t('price')}</TableCell>
                                    <TableCell >{t('discount')}</TableCell>
                                    <TableCell >Impuestos(%)</TableCell>
                                    <TableCell >{t('totalPrice')}</TableCell>
                                    <TableCell >{t('action')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {invoiceData.items.map((itemField, index) => (
                                    <TableRow key={index}>
                                        <TableCell scope="row" style={{ width: '40%' }}>
                                            <Autocomplete
                                                options={stocks}
                                                getOptionLabel={(option) => option?.name || ''}
                                                onChange={(event, newValue) => {
                                                    handleAutocompleteChange(index, newValue);
                                                    if (newValue) {
                                                        handleChange(index, { target: { name: 'unitPrice', value: newValue.price } });
                                                    } else {
                                                        handleChange(index, { target: { name: 'unitPrice', value: 0 } });
                                                    }
                                                }}
                                                onInputChange={(event, value) => handleInputChange(index, event, value)}
                                                renderInput={(params) => (
                                                    <TextField {...params} label='Elegir Producto' variant="outlined" />
                                                )}
                                                freeSolo
                                                style={{ marginTop: '20px' }}
                                            />

                                            {/* <InputBase style={{ width: '100%' }} outline="none" sx={{ ml: 1, flex: 1 }} type="text" name="itemName" onChange={e => handleChange(index, e)} value={itemField.itemName} placeholder={t('itemDesc')} /> */}
                                        </TableCell>
                                        <TableCell align="right"> <InputBase sx={{ ml: 1, flex: 1 }} type="number" name="quantity" onChange={e => handleChange(index, e)} value={itemField.quantity} placeholder="0" /> </TableCell>
                                        <TableCell align="right"> <InputBase sx={{ ml: 1, flex: 1 }} type="number" name="unitPrice" onChange={e => handleChange(index, e)} value={itemField.unitPrice} placeholder="0" /> </TableCell>
                                        <TableCell align="right"> <InputBase sx={{ ml: 1, flex: 1 }} type="number" name="discount" onChange={e => handleChange(index, e)} value={itemField.discount} placeholder="0" /> </TableCell>
                                        <TableCell align="right"> <InputBase sx={{ ml: 1, flex: 1 }} type="number" name="rates" onChange={e => handleRates(e)} value={rates} placeholder="0" /> </TableCell>
                                        <TableCell align="right"> <InputBase sx={{ ml: 1, flex: 1 }} type="number" name="amount" onChange={e => handleChange(index, e)} value={(itemField.quantity * itemField.unitPrice) - (itemField.quantity * itemField.unitPrice) * itemField.discount / 100} disabled /> </TableCell>
                                        <TableCell align="right">
                                            <IconButton onClick={() => handleRemoveField(index)}>
                                                <DeleteOutlineRoundedIcon style={{ width: '20px', height: '20px' }} />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div className={styles.addButton}>
                        <button onClick={handleAddField}>+</button>
                    </div>
                </div>


                {/* <div className={styles.toolBar}>
                    <Container >
                        <Grid container >
                            <Grid item style={{ marginTop: '16px', marginRight: 10 }}>
                                <TextField
                                    type="text"
                                    step="any"
                                    name="rates"
                                    id="rates"
                                    value={rates}
                                    onChange={handleRates}
                                    placeholder="e.g 10"
                                    label={t('taxRates')}

                                />
                            </Grid>

                            <Grid item style={{ width: 270, marginRight: 10 }}>
                                <Autocomplete
                                    {...defaultProps}
                                    id="debug"
                                    debug
                                    renderInput={(params) => <TextField {...params}
                                        label={t('selectCurrency')}
                                        margin="normal"
                                    />}
                                    value={currency.value}
                                    onChange={(event, value) => setCurrency(value.value)}


                                />
                            </Grid>
                        </Grid>
                    </Container>
                </div> */}

                <Grid container justifyContent="center" >
                    <Button
                        variant="contained"
                        style={{ justifyContentContent: 'center', marginTop: '25px' }}
                        type="submit"
                        color="primary"
                        size="large"
                        className={classes.button}
                        startIcon={<SaveIcon />}
                        fullWidth
                    >
                        Cargar Venta
                    </Button>
                </Grid>
            </form>

            <div>
            {sale && (<Ticket sale={sale} />)}
    </div>
        </div>
    )
}

export default CashRegister
