import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'es',
    supportedLngs: ['es', 'en'],
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {

          // //metadata
          // webDesc: 'Manage customers, providers, stock and invoicing in one place',

          // //common
          // cancel: 'Cancel',
          // name: 'Name',
          // number: 'Number',
          // date: 'Date',
          // customer: 'Customer',
          // dueDate: 'Due Date',
          // status: 'Status',
          // edit: 'Edit',
          // delete: 'Delete',
          // phone: 'Phone',
          // address: 'Address',
          // newInvoice: 'New Invoice',
          // newProvider: 'New Provider',
          // editProvider: 'Edit Provider',
          // products: 'Products N°',
          // newStock: 'New Stock',
          // editStock: 'Edit Stock',
          // details: 'Details',
          // provider: 'Provider',
          // from: 'From',
          // clients: 'Clients',

          // //login
          // signIn: 'Sign In',
          // signUp: 'Sign Up',
          // password: 'Password',
          // noAccount: "Don't have an account?",
          // forgottenPassword: 'Forgot my password',
          // or: 'or',
          // hasAccount: 'Already have an account? Sign in',
          // lastName: 'Last Name',
          // repeatPassword: 'Repeat password',

          // //navbar
          // login: 'Get started',
          // logout: 'Log Out',

          // //home
          // hero: 'Run your business online',
          // herospan: 'Manage customers, providers, stock, purchases and invoicing in one place',
          // heroCta: 'Get Started',

          // //features
          // system: 'Purchases and Sales Tracking System',
          // paymentProof: 'Invoicing',
          // traceability: 'Traceability',
          // clientsDesc: 'List of clients and their data to register sales to their name.',
          // providersDesc: 'List of providers and their data to register purchases to their name.',
          // stockDesc: 'Automatic stock updates when buying/selling products.',
          // paymentProofDesc: 'Download, print and send invoices.',
          // systemDesc: 'Register of all your transactions on the platform.',
          // traceDesc: 'Statistics tracking dashboard for purchases, sales, products and total balance.',

          // //footer cta
          // contactUs: 'Contact us',
          // footerP: 'Manage everything in one place',
          // firstP: 'Your',
          // secondP: 'business',
          // thirdP: 'online',
          // fourthP: 'at',
          // fifthP: 'your',
          // sixthP: 'fingertips.',
          // seventhP: '',
          // eigthP: '',

          // //sidebar
          // create: 'Create',
          // invoices: 'Invoices',
          // customers: 'Customers',
          // providers: 'Providers',
          // stock: 'Stock',
          // settings: 'Settings',

          // //dashboard
          // invoicesError: 'Nothing to display. Click the plus icon to start creating',
          // paymentReceived: 'Payment Received',
          // pendingAmount: 'Pending Amount',
          // totalAmount: 'Total Amount',
          // totalInvoices: 'Total Invoices',
          // paidInvoices: 'Paid Invoices',
          // partiallyPaidInvoices: 'Partially Paid Invoices',
          // unpaidInvoices: 'Unpaid Invoices',
          // overdue: 'Overdue',
          // recentPayments: 'Recent Payments',
          // noRecentPayments: 'No payments received yet',
          // paidBy: 'Paid By',
          // datePaid: 'Date Paid',
          // amountPaid: 'Amount Paid',
          // note: 'Note',

          // //invoice/receipt creation
          // selectType: 'Select type',
          // selectButton: 'Type',
          // invoice: 'Invoice',
          // receipt: 'Receipt',
          // invoiceNumber: 'Invoice',
          // billTo: 'Bill to',
          // selectCustomer: 'Select Customer',
          // selectNewCustomer: 'New Customer',
          // paid: 'Paid',
          // unpaid: 'Unpaid',
          // priceAmount: 'Amount',
          // item: 'Item',
          // itemDesc: 'Item name or description',
          // quantity: 'Qty',
          // price: 'Price',
          // discount: 'Disc(%)',
          // totalPrice: 'Amount',
          // action: 'Action',
          // invoiceSummary: 'Invoice Summary',
          // subTotal: 'Sub Total',
          // vat: 'VAT(%)',
          // finalPrice: 'Total',
          // taxRates: 'Tax Rates(%)',
          // selectCurrency: 'Select currency',
          // paymentInfo: 'Note/Payment Info',
          // paymentInfoPlaceholder: 'Provide additional details or terms of service',
          // submitButton: 'Save and Continue',

          // //invoice details
          // paymentHistory: 'Payment History',
          // paymentMethod: 'Payment Method',
          // sendInvoice: 'Send to Client',
          // downloadPdf: 'Download PDF',
          // editInvoice: 'Edit Invoice',
          // recordPayment: 'Record Payment',
          // paymentDate: 'Payment Date',

          // //profile settings
          // profileTitle: 'Profile Settings',
          // profileDesc: 'Edit your business profile',
          // editProfileButton: 'Edit Profile',
          // saveProfileChanges: 'Save changes',
          // uploadLogo: 'Upload Logo',
          // businessName: 'Business Name',
          // businessAddress: 'Business Address',

          // //forgot password
          // enterEmailAddress: 'Por favor indica tu email',
          // submitEmail: 'Enviar',
          // emailSent: 'A password reset link has been sent to your email. Please follow the link to reset your password.',
          // backHomeButton: 'Back to home',
          // resendLinkButton: 'Resend link',
          // enterNewPassword: 'Please enter your new password',
          // changePasswordButton: 'Change my password'
        }
      },
      es: {
        translation: {

          //metadata
          webDesc: 'Clientes, proveedores, stock, precios, compras y ventas en un solo lugar',

          //common
          cancel: 'Cancelar',
          name: 'Nombre',
          number: 'Número',
          date: 'Fecha',
          customer: 'Cliente',
          dueDate: 'Fecha de Vencimiento',
          status: 'Estado',
          edit: 'Editar',
          delete: 'Eliminar',
          phone: 'Teléfono/Celular',
          address: 'Dirección',
          newInvoice: 'Nueva Factura',
          newProvider: 'Nuevo Proveedor',
          editProvider: 'Editar Proveedor',
          products: 'N° Productos',
          newStock: 'Agregar Stock',
          editStock: 'Editar Stock',
          details: 'Detalles',
          provider: 'Proveedor',
          from: 'De',
          clients: 'Clientes',

          //login
          signIn: 'Ingresar',
          signUp: 'Registrarse',
          password: 'Contraseña',
          noAccount: '¿No tenes cuenta?',
          forgottenPassword: 'Olvidé mi contraseña',
          or: 'o',
          hasAccount: '¿Ya tenés una cuenta? Ingresar',
          lastName: 'Apellido',
          repeatPassword: 'Repetir contraseña',

          //navbar
          login: 'Ingresar',
          logout: 'Cerrar sesión',

          //hero
          hero: 'Gestiona tu negocio online',
          herospan: 'Clientes, proveedores, cajas, stock, precios, compras y ventas en un solo lugar',
          heroCta: 'Empezar',

          //features
          system: 'Sistema de compras y ventas',
          paymentProof: 'Comprobante de Pago',
          traceability: 'Trazabilidad',
          clientsProviders: 'Clientes y Proveedores',
          clientsProvidersDesc: 'Lista de clientes y proveedores con sus datos para registrar compras y ventas a su nombre.',
          // providersDesc: 'Lista de proveedores y sus datos para llevar registro de tus compras.',
          stockDesc: 'Actualización de stock automática tanto para compras como para ventas. Importación masiva de productos via Excel.',
          paymentProofDesc: 'Comprobantes de compra y venta en un solo lugar, con la posibilidad de descargar como PDF e imprimirlos.',
          systemDesc: 'Registros sobre todas tus transacciones en la plataforma.',
          traceDesc: 'Tablero de estadísticas y seguimiento sobre tus transacciones. Reportes en formato Excel.',

          //footer cta
          contactUs: 'Contactanos',
          footerP: 'Maneja todo desde un solo lugar',
          firstP: 'Tu ',
          secondP: 'negocio ',
          thirdP: 'online',
          fourthP: 'al',
          fifthP: 'alcance',
          sixthP: 'de',
          seventhP: 'tu',
          eigthP: 'mano.',

          //sidebar
          create: 'Agregar',
          invoices: 'Facturación',
          customers: 'Clientes',
          providers: 'Proveedores',
          stock: 'Stock',
          settings: 'Ajustes',

          //dashboard
          invoicesError: 'Nada para mostrar. Agrega facturas para poder verlas',
          paymentReceived: 'Pago Recibido',
          pendingAmount: 'Saldo Pendiente',
          totalAmount: 'Saldo Total',
          totalInvoices: 'Total de Facturas',
          paidInvoices: 'Facturas Pagas',
          partiallyPaidInvoices: 'Facturas Parcialmente Pagas',
          unpaidInvoices: 'Facturas Impagas',
          overdue: 'Vencido',
          recentPayments: 'Pagos Recientes',
          noRecentPayments: 'No hay pagos recientes',
          paidBy: 'Pagado Por',
          datePaid: 'Fecha de Pago',
          amountPaid: 'Monto Abonado',
          note: 'Nota',

          //invoice/receipt creation
          selectType: 'Seleccionar tipo',
          selectButton: 'Tipo',
          invoice: 'Factura',
          receipt: 'Recibo',
          invoiceNumber: 'Factura',
          billTo: 'Para',
          selectCustomer: 'Seleccionar Cliente',
          selectNewCustomer: 'Nuevo Cliente',
          paid: 'Pago',
          unpaid: 'Impago',
          priceAmount: 'Monto',
          item: 'Producto',
          itemDesc: 'Nombre del producto',
          quantity: 'Cantidad',
          price: 'Precio',
          discount: 'Descuento(%)',
          totalPrice: 'Total',
          action: 'Acción',
          invoiceSummary: 'Resumen',
          subTotal: 'Sub Total',
          vat: 'VA(%)',
          finalPrice: 'Precio Final',
          taxRates: 'Impuestos (%)',
          selectCurrency: 'Seleccionar moneda',
          paymentInfo: 'Información Adicional de Pago',
          paymentInfoPlaceholder: 'Detalles adicionales...',
          submitButton: 'Guardar y continuar',

          //invoice/receipt details
          paymentHistory: 'Historial de Pagos',
          paymentMethod: 'Método de Pago',
          sendInvoice: 'Enviar al Cliente',
          downloadPdf: 'Descargar PDF',
          edit: 'Editar',
          recordPayment: 'Cargar Pago',
          paymentDate: 'Fecha de Pago',

          //profile settings
          profileTitle: 'Ajustes del perfil',
          profileDesc: 'Edita los datos de tu negocio/perfil',
          editProfileButton: 'Editar Perfil',
          saveProfileChanges: 'Guardar cambios',
          uploadLogo: 'Subir Logo',
          businessName: 'Nombre de tu Negocio',
          businessAddress: 'Dirección de tu Negocio',

          //forgot/reset password
          enterEmailAddress: 'Por favor indica tu email',
          submitEmail: 'Enviar',
          emailSent: 'Te hemos enviado un link para resetear tu contraseña al mail indicado.',
          backHomeButton: 'Ir al inicio',
          resendLinkButton: 'Reenviar link',
          enterNewPassword: 'Por favor indica tu nueva contraseña',
          changePasswordButton: 'Cambiar mi contraseña'
        }
      }
    }
  });

export default i18n;