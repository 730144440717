import * as api from '../api/index'

import { ADD_NEW_STOCK, UPDATE_STOCK, DELETE_STOCK, FETCH_STOCKS_BY_USER, FETCH_STOCK, START_LOADING, END_LOADING } from './constants'


export const getStock = (id) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.fetchStock(id);
    dispatch({ type: FETCH_STOCK, payload: { client: data } });

  } catch (error) {
    console.log(error);
  }
};


export const getStocksByUser = (searchQuery) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING })
    const { data: { data } } = await api.fetchStocksByUser(searchQuery)

    dispatch({ type: FETCH_STOCKS_BY_USER, payload: data });
    dispatch({ type: END_LOADING })
  } catch (error) {
    console.log(error.response)

  }
}


export const createStock = (stock, openSnackbar, listId) => async (dispatch) => {

  try {
    const { data } = await api.addStock(stock, listId)
    dispatch({ type: ADD_NEW_STOCK, payload: data })
    openSnackbar("Stock añadido con éxito")

  } catch (error) {
    console.log(error)
  }
}


export const updateStock = (id, stock, openSnackbar) => async (dispatch) => {

  const { data } = await api.updateStock(id, stock)
  dispatch({ type: UPDATE_STOCK, payload: data })
  openSnackbar("Stock actualizado con éxito")
  try {

  } catch (error) {
    console.log(error)
  }
}

export const deleteStock = (id, openSnackbar) => async (dispatch) => {
  try {
    await api.deleteStock(id)

    dispatch({ type: DELETE_STOCK, payload: id })
    openSnackbar("Stock eliminado con éxito")
  } catch (error) {
    console.log(error)
  }
}