import React, { useState, useEffect } from 'react'
import styles from './Invoice.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { toCommas } from '../../utils/utils'

import IconButton from '@mui/material/IconButton';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
// import DateFnsUtils from '@date-io/date-fns';
// import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
// import { makeStyles } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import { Container, Grid, Input } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import SaveIcon from '@mui/icons-material/Save';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import { initialState } from '../../initialState'
import currencies from '../../currencies.json'
import { createInvoice, getInvoice, updateInvoice } from '../../actions/invoiceActions';
import { getClientsByUser } from '../../actions/clientActions'
import AddClient from './AddClient';
import InvoiceType from './InvoiceType';
import axios from 'axios'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
// import { useSnackbar } from 'react-simple-snackbar'
import { getStocksByUser } from '../../actions/stockActions'

import { toast } from 'react-toastify'

const useStyles = (theme) => ({
    root: {
        display: 'flex',
        '& > *': {
            // margin: theme.spacing(1),
        },
    },
    large: {
        // width: theme.spacing(12),
        // height: theme.spacing(12),
    },
    table: {
        minWidth: 650,
    },

    headerContainer: {
        // display: 'flex'
        // paddingTop: theme.spacing(1),
        // paddingLeft: theme.spacing(5),
        // paddingRight: theme.spacing(1),
    }
});

const Invoice = () => {

    const { t } = useTranslation();
    const location = useLocation()
    const [invoiceData, setInvoiceData] = useState(initialState)
    const [rates, setRates] = useState(0)
    const [vat, setVat] = useState(0)
    const [currency, setCurrency] = useState(currencies[10].value)
    const [subTotal, setSubTotal] = useState(0)
    const [total, setTotal] = useState(0)
    const [reminderDate, setReminderDate] = useState();
    const [reminderNote, setReminderNote] = useState('');
    const today = new Date();
    const [selectedDate, setSelectedDate] = useState(today.getTime() + 7 * 24 * 60 * 60 * 1000);
    const [client, setClient] = useState(null)
    const [type, setType] = useState('')
    const [status, setStatus] = useState('')
    const { id } = useParams()
    const clients = useSelector((state) => state.clients.clients)
    const { invoice } = useSelector((state) => state.invoices);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const user = JSON.parse(localStorage.getItem('profile'))
    const paidUser = user?.result?.paid
    // const [openSnackbar, closeSnackbar] = useSnackbar()
    const stocks = useSelector((state) => state.stocks.stocks)

    useEffect(() => {
        getTotalCount()
        // eslint-disable-next-line
    }, [location])


    const getTotalCount = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/invoices/count?searchQuery=${user?.result?._id}`);
            //Get total count of invoice from the server and increment by one to serialized numbering of invoice
            setInvoiceData({ ...invoiceData, invoiceNumber: (Number(response.data) + 1).toString().padStart(3, '0') })
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        if (invoice) {
            dispatch(getInvoice(id));
        }
        // eslint-disable-next-line
    }, [id]);

    useEffect(() => {
        dispatch(getClientsByUser({ search: user?.result._id || user?.result?.googleId }));
        // eslint-disable-next-line
    }, [dispatch]);

    useEffect(() => {
        dispatch(getStocksByUser({ search: user?.result?._id || user.result.googleId }));
    }, [dispatch])

    useEffect(() => {
        if (invoice) {
            //Automatically set the default invoice values as the ones in the invoice to be updated
            setInvoiceData(invoice)
            setRates(invoice.rates)
            setClient(invoice.client)
            setType(invoice.type)
            setStatus(invoice.status)
            setSelectedDate(invoice.dueDate)
        }
    }, [invoice])


    useEffect(() => {
        if (type === 'Receipt') {
            setStatus('Paid')
        } else {
            setStatus('Unpaid')
        }
    }, [type])

    const defaultProps = {
        options: currencies,
        getOptionLabel: (option) => option.label
    };

    const clientsProps = {
        options: clients,
        getOptionLabel: (option) => option.name
    };


    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const handleRates = (e) => {
        setRates(e.target.value)
        setInvoiceData((prevState) => ({ ...prevState, tax: e.target.value }))
    }

    // Change handler for dynamically added input field
    const handleChange = (index, e) => {
        const values = [...invoiceData.items]
        values[index][e.target.name] = e.target.value
        setInvoiceData({ ...invoiceData, items: values })

    }

    const handleAutocompleteChange = (index, newValue) => {
        const values = [...invoiceData.items];
        values[index].itemName = newValue.name; // or use the appropriate property from your `stocks` data
        values[index].itemId = newValue._id; // if you need to store the ID
        setInvoiceData({ ...invoiceData, items: values });
    };

    const handleInputChange = (index, event, value) => {
        const values = [...invoiceData.items];
        if (value) {
            values[index].itemName = value;
            values[index].itemId = null;
        }
        setInvoiceData({ ...invoiceData, items: values });
    };


    useEffect(() => {
        //Get the subtotal
        const subTotal = () => {
            var arr = document.getElementsByName("amount");
            var subtotal = 0;
            for (var i = 0; i < arr.length; i++) {
                if (arr[i].value) {
                    subtotal += +arr[i].value;
                }
                // document.getElementById("subtotal").value = subtotal;
                setSubTotal(subtotal)
            }
        }

        subTotal()

    }, [invoiceData])


    useEffect(() => {
        const total = () => {

            //Tax rate is calculated as (input / 100 ) * subtotal + subtotal 
            const overallSum = rates / 100 * subTotal + subTotal
            //VAT is calculated as tax rates /100 * subtotal
            setVat(rates / 100 * subTotal)
            setTotal(overallSum)


        }
        total()
    }, [invoiceData, rates, subTotal])


    const handleAddField = (e) => {
        e.preventDefault()
        setInvoiceData((prevState) => ({ ...prevState, items: [...prevState.items, { itemName: '', unitPrice: Number, quantity: Number, discount: '', amount: Number }] }))
    }

    const handleRemoveField = (index) => {
        const values = invoiceData.items
        values.splice(index, 1)
        setInvoiceData((prevState) => ({ ...prevState, values }))
    }


    const handleSubmit = async (e) => {
        e.preventDefault()
        if (invoice) {
            dispatch(updateInvoice(invoice._id, {
                ...invoiceData,
                subTotal: subTotal,
                total: total,
                vat: vat,
                rates: rates,
                currency: currency,
                dueDate: selectedDate,
                client,
                type: type,
                status: status
            },))
            navigate(`/invoice/${invoice._id}`)
        } else {
            
            // const reminderDate = addDays(new Date(), 10);
            // schedule.scheduleJob(reminderDate, () => {
            //     toast.info('Reminder: Follow up on your invoice!', {
            //         position: "top-right",
            //         autoClose: 5000,
            //         hideProgressBar: false,
            //         closeOnClick: true,
            //         pauseOnHover: true,
            //         draggable: true,
            //         progress: undefined,
            //     });
            // });
            dispatch(createInvoice(
                {
                    ...invoiceData,
                    subTotal: subTotal,
                    total: total,
                    vat: vat,
                    rates: rates,
                    currency: currency,
                    dueDate: selectedDate,
                    invoiceNumber: `${invoiceData.invoiceNumber < 100 ?
                        (Number(invoiceData.invoiceNumber)).toString().padStart(3, '0')
                        : Number(invoiceData.invoiceNumber)
                        }`,
                    client,
                    type: type,
                    status: status,
                    paymentRecords: [],
                    creator: [user?.result?._id || user?.result?.googleId],
                    notification: {date: reminderDate, note: reminderNote, read: false}
                },

            ))
                .then(() => window.location.href = "/invoices")
        }
    }

    const classes = useStyles()
    const [open, setOpen] = useState(false);

    const CustomPaper = (props) => {
        return <Paper elevation={3} {...props} />;
    };


    if (!user) {
        navigate('/login')
    }


    if (user && paidUser === 'none') {
        navigate('/my-plan')
    }


    return (

        <div className={styles.invoiceLayout}>
            <form onSubmit={handleSubmit} className="mu-form">
                <AddClient setOpen={setOpen} open={open} />
                <Container className={classes.headerContainer}>

                    <Grid container justifyContent="space-between" >
                        <Grid item>
                            {/* <Avatar alt="Logo" variant='square' src="" className={classes.large} /> */}
                        </Grid>
                        <Grid item>
                            <InvoiceType type={type} setType={setType} />
                            {t('invoiceNumber')} #:
                            <div style={{
                                marginTop: '15px',
                                width: '100px',
                                padding: '8px',
                                display: 'inline-block',
                                backgroundColor: '#f4f4f4',
                                outline: '0px solid transparent'
                            }}
                                contenteditable="true"
                                onInput={e => setInvoiceData({
                                    ...invoiceData, invoiceNumber: e.currentTarget.textContent
                                })
                                }
                            >
                                <span style={{
                                    width: '40px',
                                    color: 'black',
                                    padding: '15px',
                                }}
                                    contenteditable="false"> {invoiceData.invoiceNumber}</span>
                                <br />
                            </div>
                        </Grid>
                    </Grid >
                </Container>
                <Divider />
                <Container>
                    <Grid container justifyContent="space-between" style={{ marginTop: '40px' }} >
                        <Grid item style={{ width: '50%' }}>
                            <Container>
                                <Typography variant="overline" style={{ color: 'gray', paddingRight: '3px' }} gutterBottom>{t('billTo')}</Typography>


                                {client && (
                                    <>
                                        <Typography variant="subtitle2" gutterBottom>{client.name}</Typography>
                                        <Typography variant="body2" >{client.email}</Typography>
                                        <Typography variant="body2" >{client.phone}</Typography>
                                        <Typography variant="body2">{client.address}</Typography>
                                        <Button color="primary" size="small" style={{ textTransform: 'none' }} onClick={() => setClient(null)}>Cambiar</Button>
                                    </>
                                )}
                                <div style={client ? { display: 'none' } : { display: 'block' }}>
                                    <Autocomplete
                                        {...clientsProps}
                                        PaperComponent={CustomPaper}
                                        renderInput={(params) => <TextField {...params}
                                            required={!invoice && true}
                                            label={t('selectCustomer')}
                                            margin="normal"
                                            variant="outlined"
                                        />}
                                        value={clients?.name}
                                        onChange={(event, value) => setClient(value)}

                                    />

                                </div>
                                {!client &&
                                    <>
                                        <Grid item style={{ paddingBottom: '10px' }}>
                                            <Chip
                                                avatar={<Avatar>+</Avatar>}
                                                label={t('selectNewCustomer')}
                                                onClick={() => setOpen(true)}
                                                variant="outlined"
                                            />
                                        </Grid>
                                    </>
                                }
                            </Container>
                        </Grid>

                        <Grid item style={{ marginRight: 20, textAlign: 'right' }}>
                            <Typography variant="overline" style={{ color: 'gray' }} gutterBottom>{t('status')}</Typography>
                            <Typography variant="h6" gutterBottom style={{ color: (type === t('receipt') ? 'green' : 'red') }}>{(type === t('receipt') ? t('paid') : t('unpaid'))}</Typography>
                            <Typography variant="overline" style={{ color: 'gray' }} gutterBottom>{t('date')}</Typography>
                            <Typography variant="body2" gutterBottom>{moment().format("MMM Do YYYY")}</Typography>
                            <Typography variant="overline" style={{ color: 'gray' }} gutterBottom>{t('dueDate')}</Typography>
                            <Typography variant="body2" gutterBottom>{selectedDate ? moment(selectedDate).format("MMM Do YYYY") : ''}</Typography>
                            <Typography variant="overline" gutterBottom>{t('priceAmount')}</Typography>
                            <Typography variant="h6" gutterBottom>{currency} {toCommas(total)}</Typography>
                        </Grid>
                    </Grid>
                </Container>


                <div>
                    <TableContainer component={Paper} className="tb-container">
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('item')}</TableCell>
                                    <TableCell>{t('quantity')}</TableCell>
                                    <TableCell >{t('price')}</TableCell>
                                    <TableCell >{t('discount')}</TableCell>
                                    <TableCell >{t('totalPrice')}</TableCell>
                                    <TableCell >{t('action')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {invoiceData.items.map((itemField, index) => (
                                    <TableRow key={index}>
                                        <TableCell scope="row" style={{ width: '40%' }}>
                                            <Autocomplete
                                                options={stocks}
                                                getOptionLabel={(option) => option.name}
                                                onChange={(event, newValue) => handleAutocompleteChange(index, newValue)}
                                                onInputChange={(event, value) => handleInputChange(index, event, value)}
                                                renderInput={(params) => (
                                                    <TextField {...params} label='Elegir Producto' variant="outlined" />
                                                )}
                                                freeSolo
                                                style={{ marginTop: '20px' }}
                                            />

                                            {/* <InputBase style={{ width: '100%' }} outline="none" sx={{ ml: 1, flex: 1 }} type="text" name="itemName" onChange={e => handleChange(index, e)} value={itemField.itemName} placeholder={t('itemDesc')} /> */}
                                        </TableCell>
                                        <TableCell align="right"> <InputBase sx={{ ml: 1, flex: 1 }} type="number" name="quantity" onChange={e => handleChange(index, e)} value={itemField.quantity} placeholder="0" /> </TableCell>
                                        <TableCell align="right"> <InputBase sx={{ ml: 1, flex: 1 }} type="number" name="unitPrice" onChange={e => handleChange(index, e)} value={itemField.unitPrice} placeholder="0" /> </TableCell>
                                        <TableCell align="right"> <InputBase sx={{ ml: 1, flex: 1 }} type="number" name="discount" onChange={e => handleChange(index, e)} value={itemField.discount} placeholder="0" /> </TableCell>
                                        <TableCell align="right"> <InputBase sx={{ ml: 1, flex: 1 }} type="number" name="amount" onChange={e => handleChange(index, e)} value={(itemField.quantity * itemField.unitPrice) - (itemField.quantity * itemField.unitPrice) * itemField.discount / 100} disabled /> </TableCell>
                                        <TableCell align="right">
                                            <IconButton onClick={() => handleRemoveField(index)}>
                                                <DeleteOutlineRoundedIcon style={{ width: '20px', height: '20px' }} />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div className={styles.addButton}>
                        <button onClick={handleAddField}>+</button>
                    </div>
                </div>

                <div className={styles.invoiceSummary}>
                    <div className={styles.summary}>{t('invoiceSummary')}</div>
                    <div className={styles.summaryItem}>
                        <p>{t('subTotal')}:</p>
                        <h4>{subTotal}</h4>
                    </div>
                    <div className={styles.summaryItem}>
                        <p>{t('vat')}:</p>
                        <h4>{vat}</h4>
                    </div>
                    <div className={styles.summaryItem}>
                        <p>{t('finalPrice')}</p>
                        <h4 style={{ color: "black", fontSize: "18px", lineHeight: "8px" }}>{currency} {toCommas(total)}</h4>
                    </div>

                </div>


                <div className={styles.toolBar}>
                    <Container >
                        <Grid container >
                            <Grid item style={{ marginTop: '16px', marginRight: 10 }}>
                                <TextField
                                    type="text"
                                    step="any"
                                    name="rates"
                                    id="rates"
                                    value={rates}
                                    onChange={handleRates}
                                    placeholder="e.g 10"
                                    label={t('taxRates')}

                                />
                            </Grid>

                            <Grid item style={{ marginRight: 10, marginTop: '17px' }} >
                                <Input
                                    type='date'
                                    id="date"
                                    label={t('dueDate')}
                                    value={selectedDate}
                                    onChange={(e) => handleDateChange(e.target.value)}
                                    style={{ border: '1px solid gray', borderRadius: '3px', height: '53px', padding: '5px' }}
                                />
                            </Grid>

                            <Grid item style={{ width: 270, marginRight: 10 }}>
                                <Autocomplete
                                    {...defaultProps}
                                    id="debug"
                                    debug
                                    renderInput={(params) => <TextField {...params}
                                        label={t('selectCurrency')}
                                        margin="normal"
                                    />}
                                    value={currency.value}
                                    onChange={(event, value) => setCurrency(value.value)}


                                />
                            </Grid>
                        </Grid>
                    </Container>
                </div>
                {/* <Container>
                    <Grid container spacing={3}>
                       <Grid item xs={12} sm={6}>
                            <Input
                                type='date'
                                id="date"
                                label='Reminder Date'
                                value={reminderDate}
                                onChange={(e) => setReminderDate(e.target.value)}
                                style={{ border: '1px solid gray', borderRadius: '3px', height: '53px', padding: '5px' }}
                            />
                       </Grid>
                       <Grid item xs={12} sm={6}>
                           <TextField
                               label="Reminder Note"
                               value={reminderNote}
                               onChange={(e) => setReminderNote(e.target.value)}
                               fullWidth
                           />
                       </Grid>
                   </Grid>
                </Container> */}
                <div className={styles.note}>
                    <h4 className='text-gray-500'>{t('paymentInfo')}</h4>
                    <textarea
                        style={{ border: 'solid 1px #d6d6d6', padding: '10px' }}
                        placeholder={t('paymentInfoPlaceholder')}
                        onChange={(e) => setInvoiceData({ ...invoiceData, notes: e.target.value })}
                        value={invoiceData.notes}
                        className='bg-white'
                    />
                </div>

                {/* <button className={styles.submitButton} type="submit">Save and continue</button> */}
                <Grid container justifyContent="center">
                    <Button
                        variant="contained"
                        style={{ justifyContentContent: 'center', marginTop: '25px' }}
                        type="submit"
                        color="primary"
                        size="large"
                        className={classes.button}
                        startIcon={<SaveIcon />}
                    >
                        {t('submitButton')}
                    </Button>
                </Grid>
            </form>
        </div>
    )
}

export default Invoice
