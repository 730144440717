
export const FETCH_ALL = "FETCH_ALL"
export const ADD_NEW = "ADD_NEW"
export const UPDATE = "UPDATE"
export const DELETE = "DELETE"
export const GET_INVOICE = "GET_INVOICE"
export const FETCH_INVOICE_BY_USER = "FETCH_INVOICE_BY_USER"
export const FETCH_INVOICE_BY_CASHBOX = "FETCH_INVOICE_BY_CASHBOX"


export const ALL_CLIENTS = "ALL_CLIENTS"
export const UPDATE_CLIENT = "UPDATE_CLIENT"
export const DELETE_CLIENT = "DELETE_CLIENT"
export const ADD_NEW_CLIENT = "ADD_NEW_CLIENT"
export const FETCH_CLIENTS_BY_USER = 'FETCH_PROFILE_USER';
export const FETCH_CLIENT = "FETCH_CLIENT"

export const AUTH = "AUTH"
export const LOGOUT = "LOGOUT"
export const UPDATE_USER = "UPDATE_USER"
export const FETCH_USERS = "FETCH_USERS"
export const START_LOADING = "START_LOADING"
export const END_LOADING = "END_LOADING"

export const CREATE_PROFILE = 'CREATE_PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const DELETE_PROFILE = 'DELETE_PROFILE';
export const FETCH_PROFILES = 'FETCH_PROFILES';
export const FETCH_PROFILE = 'FETCH_PROFILE';
export const FETCH_PROFILE_BY_USER = 'FETCH_PROFILE_USER';
export const FETCH_PROFILES_BY_USER = 'FETCH_PROFILES_BY_USER';

export const ALL_PROVIDERS = "ALL_PROVIDERS"
export const UPDATE_PROVIDER = "UPDATE_PROVIDER"
export const DELETE_PROVIDER = "DELETE_PROVIDER"
export const ADD_NEW_PROVIDER = "ADD_NEW_PROVIDER"
export const FETCH_PROVIDERS_BY_USER = 'FETCH_PROFILE_USER';
export const FETCH_PROVIDER = "FETCH_PROVIDER"

export const ALL_STOCKS = "ALL_STOCKS"
export const UPDATE_STOCK = "UPDATE_STOCK"
export const DELETE_STOCK = "DELETE_STOCK"
export const ADD_NEW_STOCK = "ADD_NEW_STOCK"
export const FETCH_STOCKS_BY_USER = 'FETCH_STOCKS_BY_USER';
export const FETCH_STOCK = "FETCH_STOCK"

export const ALL_EXPENSES = "ALL_EXPENSES"
export const UPDATE_EXPENSE = "UPDATE_EXPENSE"
export const DELETE_EXPENSE = "DELETE_EXPENSE"
export const ADD_NEW_EXPENSE = "ADD_NEW_EXPENSE"
export const FETCH_EXPENSES_BY_USER = 'FETCH_EXPENSES_USER';
export const FETCH_EXPENSE = "FETCH_EXPENSE"

export const ALL_CASHBOXES = "ALL_CASHBOXES"
export const UPDATE_CASHBOX = "UPDATE_CASHBOX"
export const DELETE_CASHBOX = "DELETE_CASHBOX"
export const ADD_NEW_CASHBOX = "ADD_NEW_CASHBOX"
export const FETCH_CASHBOXES_BY_USER = 'FETCH_CASHBOXES_USER';
export const FETCH_CASHBOX = "FETCH_CASHBOX"
export const CLOSE_CASHBOX = "CLOSE_CASHBOX"
export const START_CASHBOX_LOADING = "START_CASHBOX_LOADING"
export const END_CASHBOX_LOADING = "END_CASHBOX_LOADING"
