import { Container } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

const MpSuccess = () => {
    const user = JSON.parse(localStorage.getItem('profile'));
    const navigate = useNavigate();

    if (!user) {
        navigate('/login')
    }

    return (
        <Container className='w-full h-[100vh]'>
            <div className='flex flex-column text-gray-700'>
                <div className='p-48 m-auto'>
                    <h1 className='mb-2'>Tu cuenta de MercadoPago fue vinculada con éxito. Te recomendamos cerrar tu sesión e iniciar sesión de nuevo para ver los cambios. </h1>
                    <p className='text-center font-bold'>Ir al <Link to="/dashboard" className='text-blue-600'>dashboard</Link></p>
                </div>
            </div>
        </Container>
    )
}

export default MpSuccess