import React, { useState, useEffect } from 'react'
// import "../../../node_modules/react-progress-button/react-progress-button.css"
// import { useSnackbar } from 'react-simple-snackbar'
import { useLocation, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { initialState } from '../../initialState'
import { getInvoice } from '../../actions/invoiceActions'
import { toCommas } from '../../utils/utils'
import styles from './InvoiceDetails.module.css'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
// import { makeStyles } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import { Container, Grid } from '@mui/material';
import Divider from '@mui/material/Divider';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import Spinner from '../Spinner/Spinner'

import ProgressButton from 'react-progress-button'
import axios from 'axios';
import { saveAs } from 'file-saver';
import Modal from '../Payments/Modal'
import PaymentHistory from './PaymentHistory'
import { useTranslation } from 'react-i18next';

const InvoiceDetails = () => {

  const { t } = useTranslation();
  const location = useLocation()
  const [invoiceData, setInvoiceData] = useState(initialState)
  const [rates, setRates] = useState(0)
  const [vat, setVat] = useState(0)
  const [currency, setCurrency] = useState('')
  const [subTotal, setSubTotal] = useState(0)
  const [total, setTotal] = useState(0)
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [client, setClient] = useState([])
  const [type, setType] = React.useState('')
  const [status, setStatus] = useState('')
  const [company, setCompany] = useState({})
  const { id } = useParams()
  const { invoice } = useSelector((state) => state.invoices)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [sendStatus, setSendStatus] = useState(null)
  const [downloadStatus, setDownloadStatus] = useState(null)
  // eslint-disable-next-line
  // const [openSnackbar, closeSnackbar] = useSnackbar()
  const user = JSON.parse(localStorage.getItem('profile'))

  const useStyles = (theme) => ({
    root: {
      display: 'flex',
      '& > *': {
        // margin: theme.spacing(1),
      },
    },
    large: {
      // width: theme.spacing(12),
      // height: theme.spacing(12),
    },
    table: {
      minWidth: 650,
    },

    headerContainer: {
      // display: 'flex'
      // paddingTop: theme.spacing(1),
      // paddingLeft: theme.spacing(5),
      // paddingRight: theme.spacing(1),
      backgroundColor: '#f2f2f2',
      borderRadius: '10px 10px 0px 0px'
    }
  });


  const classes = useStyles()

  useEffect(() => {
    dispatch(getInvoice(id));
  }, [id, dispatch, location]);

  useEffect(() => {
    if (invoice) {
      //Automatically set the default invoice values as the ones in the invoice to be updated
      setInvoiceData(invoice)
      setRates(invoice.rates)
      setClient(invoice.client)
      setType(invoice.type)
      setStatus(invoice.status)
      setSelectedDate(invoice.dueDate)
      setVat(invoice.vat)
      setCurrency(invoice.currency)
      setSubTotal(invoice.subTotal)
      setTotal(invoice.total)
      setCompany(invoice?.client)

    }
  }, [invoice])

  //Get the total amount paid
  let totalAmountReceived = 0
  for (var i = 0; i < invoice?.paymentRecords?.length; i++) {
    totalAmountReceived += Number(invoice?.paymentRecords[i]?.amountPaid)
  }


  const editInvoice = (id) => {
    navigate(`/edit/invoice/${id}`)
  }

  async function createAndDownloadPdf() {
    const api_key = "a86eMjAxNzk6MTcyOTA6b1hrNU4zSGFyQnhZdk82dQ=";
    const template_id = "09a77b23b70662ac";

    const payloadData = {
      company: user?.userProfile?.businessName,
      company_logo: company.logo,
      email: company.email,
      company_bill_to: invoice.client.name,
      address: user?.userProfile?.contactAddress,
      invoice_no: invoice.invoiceNumber,
      invoice_date: moment(invoice.createdAt).format('DD/MM/YYYY'),
      invoice_due_date: moment(invoice.dueDate).format('DD/MM/YYYY'),
      items: invoice.items,
      total: invoice.total,

      // type: invoice.type,
      // status: invoice.status,

      // phone: invoice.client.phone,
      // notes: invoice.notes,
      // subTotal: toCommas(invoice.subTotal),
      // vat: invoice.vat,
      // totalAmountReceived: toCommas(totalAmountReceived),
      // balanceDue: toCommas(total - totalAmountReceived),
    };

    try {
      const response = await axios.post(
        `https://rest.apitemplate.io/v2/create-pdf?template_id=${template_id}`,
        payloadData,
        {
          headers: {
            "X-API-KEY": api_key,
            "Content-Type": "application/json",
          }
        }
      );
      const pdfUrl = response.data.download_url;
      window.open(pdfUrl, '_blank');

    } catch (error) {
      console.error('Error:', error);
    }
  }


  //SEND PDF INVOICE VIA EMAIL
  const sendPdf = (e) => {
    e.preventDefault()
    setSendStatus('loading')
    axios.post(`${process.env.REACT_APP_API}/send-pdf`,
      {
        name: invoice.client.name,
        address: invoice.client.address,
        phone: invoice.client.phone,
        email: invoice.client.email,
        dueDate: invoice.dueDate,
        date: invoice.createdAt,
        id: invoice.invoiceNumber,
        notes: invoice.notes,
        subTotal: toCommas(invoice.subTotal),
        total: toCommas(invoice.total),
        type: invoice.type,
        vat: invoice.vat,
        items: invoice.items,
        status: invoice.status,
        totalAmountReceived: toCommas(totalAmountReceived),
        balanceDue: toCommas(total - totalAmountReceived),
        link: `${process.env.REACT_APP_URL}/invoice/${invoice._id}`,
        company: company,
      })
      // .then(() => console.log("invoice sent successfully"))
      .then(() => setSendStatus('success'))
      .catch((error) => {
        console.log(error)
        setSendStatus('error')
      })
  }


  const iconSize = { height: '18px', width: '18px', marginRight: '10px', color: 'gray' }
  const [open, setOpen] = useState(false)


  function checkStatus() {
    return totalAmountReceived >= total ? "green"
      : status === "Partial" ? "#1976d2"
        : status === "Paid" ? "green"
          : status === "Unpaid" ? "red"
            : "red";
  }


  if (!invoice) {
    return (
      <Spinner />
    )
  }


  return (
    <div className={styles.PageLayout}>
      {invoice?.creator?.includes(user?.result?._id || user?.result?.googleId) && (
        <div className={styles.buttons} style={{ maxWidth: '1050px', gap:'10px' }}>
          {/* <ProgressButton 
                  onClick={sendPdf} 
                  state={sendStatus}
                  onSuccess={()=> openSnackbar("Invoice sent successfully")}
                >
                {t('sendInvoice')}
                </ProgressButton> */}

          <button
            onClick={createAndDownloadPdf}
            className="btn" >
            {t('downloadPdf')}
          </button>

          <button
            className="btn"
            onClick={() => editInvoice(invoiceData._id)}
            style={{ maxWidth: '300px' }}
          >
            <BorderColorIcon style={iconSize}
            />
            {t('edit')}
          </button>

          <button
            // disabled={status === 'Paid' ? true : false}
            className="btn"
            onClick={() => setOpen((prev) => !prev)}
            style={{ maxWidth: '300px' }}
          >
            <MonetizationOnIcon style={iconSize} />
            {t('recordPayment')}
          </button>
        </div>
      )}

      {/* {invoice?.paymentRecords.length !== 0 && (
        <PaymentHistory paymentRecords={invoiceData?.paymentRecords} />
      )} */}

      <Modal open={open} setOpen={setOpen} invoice={invoice} />
      <div className={styles.invoiceLayout}>
        <Container className={classes.headerContainer}>

          <Grid container justifyContent="space-between" style={{ padding: '30px 0px' }}>
            {!invoice?.creator?.includes(user?.result._id || user?.result?.googleId) ?
              (
                <Grid item>
                </Grid>
              )
              : (
                <Grid item onClick={() => navigate('/settings')} style={{ cursor: 'pointer' }}>
                  {company?.logo ? <img src={company?.logo} alt="Logo" className={styles.logo} />
                    :
                    <h2>{company?.name}</h2>
                  }
                </Grid>
              )}
            <Grid item style={{ marginRight: 40, textAlign: 'right' }}>
              <Typography style={{ lineSpacing: 1, fontSize: 45, fontWeight: 700, color: 'gray' }} >{Number(total - totalAmountReceived) <= 0 ? t('receipt') : t('invoice')}</Typography>
              <Typography variant="overline" style={{ color: 'gray' }} >No: </Typography>
              <Typography variant="body2">{invoiceData?.invoiceNumber}</Typography>
            </Grid>
          </Grid >
        </Container>
        <Divider />
        <Container>
          <Grid container justifyContent="space-between" style={{ marginTop: '40px' }} >
            <Grid item>
              {invoice?.creator?.includes(user?.result._id) && (
                <Container style={{ marginBottom: '20px' }}>
                  <Typography variant="overline" style={{ color: 'gray' }} gutterBottom>{t('from')}</Typography>
                  <Typography variant="subtitle2">{invoice?.businessDetails?.data?.data?.businessName}</Typography>
                  <Typography variant="body2">{invoice?.businessDetails?.data?.data?.email}</Typography>
                  <Typography variant="body2">{invoice?.businessDetails?.data?.data?.phoneNumber}</Typography>
                  <Typography variant="body2" gutterBottom>{user?.userProfile?.contactAddress}</Typography>
                </Container>
              )}
              <Container>
                <Typography variant="overline" style={{ color: 'gray', paddingRight: '3px' }} gutterBottom>{t('billTo')}</Typography>
                <Typography variant="subtitle2" gutterBottom>{client.name}</Typography>
                <Typography variant="body2" >{client?.email}</Typography>
                <Typography variant="body2" >{client?.phone}</Typography>
                <Typography variant="body2">{client?.address}</Typography>
              </Container>
            </Grid>

            <Grid item style={{ marginRight: 20, textAlign: 'right' }}>
              <Typography variant="overline" style={{ color: 'gray' }} gutterBottom>{t('status')}</Typography>
              <Typography variant="h6" gutterBottom style={{ color: checkStatus() }}>{totalAmountReceived >= total ? t('paid') : t('unpaid')}</Typography>
              <Typography variant="overline" style={{ color: 'gray' }} gutterBottom>{t('date')}</Typography>
              <Typography variant="body2" gutterBottom>{moment().format("MMM Do YYYY")}</Typography>
              <Typography variant="overline" style={{ color: 'gray' }} gutterBottom>{t('dueDate')}</Typography>
              <Typography variant="body2" gutterBottom>{selectedDate ? moment(selectedDate).utc().format("MMM Do YYYY") : ''}</Typography>
              <Typography variant="overline" gutterBottom>{t('priceAmount')}</Typography>
              <Typography variant="h6" gutterBottom>{currency} {toCommas(total)}</Typography>
            </Grid>
          </Grid>
        </Container>

        <form>
          <div>

            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>{t('item')}</TableCell>
                    <TableCell >{t('quantity')}</TableCell>
                    <TableCell>{t('price')}</TableCell>
                    <TableCell >{t('discount')}</TableCell>
                    <TableCell >{t('totalPrice')}</TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {invoiceData?.items?.map((itemField, index) => (
                    <TableRow key={index}>
                      <TableCell scope="row" style={{ width: '40%' }}> <InputBase style={{ width: '100%' }} outline="none" sx={{ ml: 1, flex: 1 }} type="text" name="itemName" value={itemField.itemName} placeholder={t('itemDesc')} readOnly /> </TableCell>
                      <TableCell align="right"> <InputBase sx={{ ml: 1, flex: 1 }} type="number" name="quantity" value={itemField?.quantity} placeholder="0" readOnly /> </TableCell>
                      <TableCell align="right"> <InputBase sx={{ ml: 1, flex: 1 }} type="number" name="unitPrice" value={itemField?.unitPrice} placeholder="0" readOnly /> </TableCell>
                      <TableCell align="right"> <InputBase sx={{ ml: 1, flex: 1 }} type="number" name="discount" value={itemField?.discount} readOnly /> </TableCell>
                      <TableCell align="right"> <InputBase sx={{ ml: 1, flex: 1 }} type="number" name="amount" value={(itemField?.quantity * itemField.unitPrice) - (itemField.quantity * itemField.unitPrice) * itemField.discount / 100} readOnly /> </TableCell>


                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div className={styles.addButton}>
            </div>
          </div>

          <div className={styles.invoiceSummary}>
            <div className={styles.summary}>{t('invoiceSummary')}</div>
            <div className={styles.summaryItem}>
              <p>Subtotal:</p>
              <h4>{subTotal}</h4>
            </div>
            <div className={styles.summaryItem}>
              <p>{`VA(${rates}%):`}</p>
              <h4>{vat}</h4>
            </div>
            <div className={styles.summaryItem}>
              <p>Total</p>
              <h4>{currency} {toCommas(total)}</h4>
            </div>

            <div className={styles.summaryItem}>
              <p>{t('amountPaid')}</p>
              <h4>{currency} {toCommas(totalAmountReceived)}</h4>
            </div>

            <div className={styles.summaryItem}>
              <p>Balance Final</p>
              <h4 style={{ color: "black", fontSize: "18px", lineHeight: "8px" }}>{currency} {toCommas(total - totalAmountReceived)}</h4>
            </div>

          </div>

          <div className={styles.note}>
            <h4 style={{ marginLeft: '-10px' }}>{t('note')}</h4>
            <p style={{ fontSize: '14px' }}>{invoiceData.notes}</p>
          </div>

          {/* <button className={styles.submitButton} type="submit">Save and continue</button> */}
        </form>
      </div>
    </div>

  )
}

export default InvoiceDetails
