import React, { useEffect, useState } from 'react'
import { useLocation, Link } from 'react-router-dom'
import { Button, Container } from '@mui/material'

const Wallets = () => {

    const location = useLocation()
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')))

    useEffect(() => {
        setUser(JSON.parse(localStorage.getItem('profile')))
    }, [location])

    const userId = user?.result?._id
    const mpAccessToken = user?.result?.mpAccessToken
    const plan = user?.result?.paid

    return (
        <Container className='w-full h-[80vh]'>
            <div className='flex flex-column text-gray-700 p-10'>
                <div className=''>
                    <h1 className='text-xl font-bold mt-5 mb-2'>Vincula tus billeteras virtuales </h1>
                    {plan === 'pro' || plan === 'business' ? 
                        <div class="grid gap-5 p-5 mt-4 overflow-hidden border md:grid-cols-2 rounded-3xl lg:grid-cols-2">
                            <div class="flex flex-col justify-between h-full p-8 bg-white border shadow-2xl rounded-2xl">
                                    <p className='text-lg font-bold'>Vincula tu cuenta de MercadoPago (Argentina)</p>
                                    {mpAccessToken.length > 5 ? 
                                        <button className="btn btn-wide flex m-auto mt-10 text-lg text-white rounded-lg h-[60px]">
                                            Cuenta Vinculada
                                        </button>
                                        : 
                                        
                                        <a href={`https://auth.mercadopago.com/authorization?client_id=593433557715680&response_type=code&platform_id=mp&state=${userId}&redirect_uri=https://buk-server.fly.dev/mp/auth`} >
                                            <button className="btn btn-wide flex m-auto mt-10 text-lg text-white rounded-lg h-[60px]">
                                                Vincular
                                            </button>
                                        </a>
                                    }
                            </div>

                            {/* <div class="flex flex-col justify-between h-full p-8 bg-white border shadow-2xl rounded-2xl">
                                    <p className='text-lg font-bold'>Vincula tu cuenta de MercadoPago (Uruguay)</p>
                                    <a href="#" >
                                        <button disabled className="btn btn-wide flex m-auto mt-10 text-lg text-white rounded-lg h-[60px]">
                                            Pronto
                                        </button>
                                    </a>
                            </div> */}

                            <div class="flex flex-col justify-between h-full p-8 bg-white border shadow-2xl rounded-2xl">
                                    <p className='text-lg font-bold'>Vincula tu cuenta de banco con Modo (Argentina)</p>
                                    {/* <a href="#" > */}
                                        <button disabled className="btn btn-wide flex m-auto mt-10 text-lg text-white rounded-lg h-[60px]">
                                            <p className='text-gray-400'>Pronto </p>
                                        </button>
                                    {/* </a> */}
                            </div>
                        </div>

                        :
                        <div>Si querés vincular tu cuenta de Mercado Pago por favor actualiza tu plan a Pro o Business.</div>
                    }
                </div>
            </div>
        </Container>
    )
}

export default Wallets