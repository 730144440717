import React, { useState } from 'react'
import { useLocation } from 'react-router-dom';
import { Fab, Action } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';
import AddIcon from '@mui/icons-material/Add';
import CreateIcon from '@mui/icons-material/Create';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AddClient from '../Invoice/AddClient';
import { useTranslation } from 'react-i18next';
import AddProvider from '../Providers/AddProvider';
import AddStock from '../Stocks/AddStock';


const FabButton = () => {

  const location = useLocation()
  const mainButtonStyles = { backgroundColor: '#1976D2', marginBottom: '70px', marginRight: '-15px' }
  const [open, setOpen] = useState(false)
  const [openProviderForm, setOpenProviderForm] = useState(false)
  const [openStockForm, setOpenStockForm] = useState(false)
  const { t } = useTranslation();

  return (
    <div>
      <AddClient setOpen={setOpen} open={open} />
      <AddProvider setOpenProviderForm={setOpenProviderForm} openProviderForm={openProviderForm} />
      <AddStock setOpenStockForm={setOpenStockForm} openStockForm={openStockForm} />
      <Fab
        mainButtonStyles={mainButtonStyles}
        icon={<AddIcon />}
        alwaysShowTitle={true}
      >

        {location.pathname !== '/invoice' && (
          <Action
            text={t('newInvoice')}
            onClick={() => window.location.href = '/invoice'}
          >
            <CreateIcon />
          </Action>
        )}

        <Action
          text={t('selectNewCustomer')}
          onClick={() => setOpen((prev) => !prev)}
        >
          <PersonAddIcon />
        </Action>

        <Action
          text={t('newProvider')}
          onClick={() => setOpenProviderForm((prev) => !prev)}
        >
          <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#ebe5e5"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M20 15.45c-1.25 0-2.45-.2-3.57-.57-.1-.03-.21-.05-.31-.05-.26 0-.51.1-.71.29l-2.2 2.2c-2.83-1.44-5.15-3.75-6.59-6.59l2.2-2.21c.28-.26.36-.65.25-1C8.7 6.4 8.5 5.2 8.5 3.95c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1 0 9.39 7.61 17 17 17 .55 0 1-.45 1-1v-3.5c0-.55-.45-1-1-1zM5.03 4.95h1.5c.07.88.22 1.75.45 2.58l-1.2 1.21c-.4-1.21-.66-2.47-.75-3.79zM19 18.92c-1.32-.09-2.6-.35-3.8-.76l1.2-1.2c.85.24 1.72.39 2.6.45v1.51zM18 5.95v-3h-2v3h-3v2h3v3h2v-3h3v-2z" /></svg>
        </Action>

        <Action
          text={t('newStock')}
          onClick={() => setOpenStockForm((prev) => !prev)}
        >
          <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#ebe5e5"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M11 9h2V6h3V4h-3V1h-2v3H8v2h3v3zm-4 9c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zm10 0c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2zm-8.9-5h7.45c.75 0 1.41-.41 1.75-1.03l3.86-7.01L19.42 4l-3.87 7H8.53L4.27 2H1v2h2l3.6 7.59-1.35 2.44C4.52 15.37 5.48 17 7 17h12v-2H7l1.1-2z" /></svg>
        </Action>

      </Fab>
    </div>
  )
}

export default FabButton
