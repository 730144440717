import React, { useState, useEffect } from 'react'
// import "../../../node_modules/react-progress-button/react-progress-button.css"
// import { useSnackbar } from 'react-simple-snackbar'
import { useLocation, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { initialState } from '../../initialState'
import { getExpense } from '../../actions/expenseActions'
import { toCommas } from '../../utils/utils'
import styles from './ExpenseDetails.module.css'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
// import { makeStyles } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import { Container, Grid } from '@mui/material';
import Divider from '@mui/material/Divider';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import Spinner from '../Spinner/Spinner'

import ProgressButton from 'react-progress-button'
import axios from 'axios';
import { saveAs } from 'file-saver';
import ExpenseHistory from './ExpenseHistory'
import { useTranslation } from 'react-i18next';
import ExpenseModal from '../Payments/ExpenseModal'

const ExpenseDetails = () => {

  const { t } = useTranslation();
  const location = useLocation()
  const [expenseData, setExpenseData] = useState(initialState)
  const [rates, setRates] = useState(0)
  const [vat, setVat] = useState(0)
  const [currency, setCurrency] = useState('')
  const [subTotal, setSubTotal] = useState(0)
  const [total, setTotal] = useState(0)
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [provider, setProvider] = useState([])
  const [type, setType] = React.useState('')
  const [status, setStatus] = useState('')
  const [company, setCompany] = useState({})
  const { id } = useParams()
  const { expense } = useSelector((state) => state.expenses)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [sendStatus, setSendStatus] = useState(null)
  const [downloadStatus, setDownloadStatus] = useState(null)
  // eslint-disable-next-line
  // const [openSnackbar, closeSnackbar] = useSnackbar()
  const user = JSON.parse(localStorage.getItem('profile'))

  const useStyles = (theme) => ({
    root: {
      display: 'flex',
      '& > *': {
        // margin: theme.spacing(1),
      },
    },
    large: {
      // width: theme.spacing(12),
      // height: theme.spacing(12),
    },
    table: {
      minWidth: 650,
    },

    headerContainer: {
      // display: 'flex'
      // paddingTop: theme.spacing(1),
      // paddingLeft: theme.spacing(5),
      // paddingRight: theme.spacing(1),
      backgroundColor: '#f2f2f2',
      borderRadius: '10px 10px 0px 0px'
    }
  });


  const classes = useStyles()
  useEffect(() => {
    dispatch(getExpense(id));
  }, [id, dispatch, location]);

  useEffect(() => {
    if (expense) {
      //Automatically set the default expense values as the ones in the expense to be updated
      setExpenseData(expense)
      setRates(expense.rates)
      setProvider(expense.client)
      setType(expense.type)
      setStatus(expense.status)
      setSelectedDate(expense.dueDate)
      setVat(expense.vat)
      setCurrency(expense.currency)
      setSubTotal(expense.subTotal)
      setTotal(expense.total)
      setCompany(expense?.provider)

    }
  }, [expense])

  //Get the total amount paid
  let totalAmountReceived = 0
  for (var i = 0; i < expense?.paymentRecords?.length; i++) {
    totalAmountReceived += Number(expense?.paymentRecords[i]?.amountPaid)
  }


  const editExpense = (id) => {
    navigate(`/edit/expense/${id}`)
  }

  async function createAndDownloadPdf() {
    const api_key = "a86eMjAxNzk6MTcyOTA6b1hrNU4zSGFyQnhZdk82dQ=";
    const template_id = "12e77b23b76419f4";

    const payloadData = {
      company: company.name,
      // company_logo: company.logo,
      email: company.email,
      company_bill_to: user?.userProfile?.businessName || user?.result?.name,
      address: company.address,
      expense_no: expense.invoiceNumber,
      expense_date: moment(expense.createdAt).format('DD/MM/YYYY'),
      expense_due_date: expense.dueDate,
      items: expense.items,
      total: expense.total,

      // type: expense.type,
      // status: expense.status,

      // phone: expense.client.phone,
      // notes: expense.notes,
      // subTotal: toCommas(expense.subTotal),
      // vat: expense.vat,
      // totalAmountReceived: toCommas(totalAmountReceived),
      // balanceDue: toCommas(total - totalAmountReceived),
    };

    try {
      const response = await axios.post(
        `https://rest.apitemplate.io/v2/create-pdf?template_id=${template_id}`,
        payloadData,
        {
          headers: {
            "X-API-KEY": api_key,
            "Content-Type": "application/json",
          }
        }
      );

      const pdfUrl = response.data.download_url;
      window.open(pdfUrl, '_blank');

    } catch (error) {
      console.error('Error:', error);
    }
  }


  //SEND PDF expense VIA EMAIL
  const sendPdf = (e) => {
    e.preventDefault()
    setSendStatus('loading')
    axios.post(`${process.env.REACT_APP_API}/send-pdf`,
      {
        name: expense.client.name,
        address: expense.client.address,
        phone: expense.client.phone,
        email: expense.client.email,
        dueDate: expense.dueDate,
        date: expense.createdAt,
        id: expense.expenseNumber,
        notes: expense.notes,
        subTotal: toCommas(expense.subTotal),
        total: toCommas(expense.total),
        type: expense.type,
        vat: expense.vat,
        items: expense.items,
        status: expense.status,
        totalAmountReceived: toCommas(totalAmountReceived),
        balanceDue: toCommas(total - totalAmountReceived),
        link: `${process.env.REACT_APP_URL}/expense/${expense._id}`,
        company: company,
      })
      // .then(() => console.log("expense sent successfully"))
      .then(() => setSendStatus('success'))
      .catch((error) => {
        console.log(error)
        setSendStatus('error')
      })
  }


  const iconSize = { height: '18px', width: '18px', marginRight: '10px', color: 'gray' }
  const [open, setOpen] = useState(false)


  function checkStatus() {
    return totalAmountReceived >= total ? "green"
      : status === "Partial" ? "#1976d2"
        : status === "Paid" ? "green"
          : status === "Unpaid" ? "red"
            : "red";
  }


  if (!expense) {
    return (
      <Spinner />
    )
  }


  return (
    <div className={styles.PageLayout}>
      {expense?.creator?.includes(user?.result?._id || user?.result?.googleId) && (
        <div className={styles.buttons} style={{ maxWidth: '1050px' }}>
          {/* <ProgressButton 
                  onClick={sendPdf} 
                  state={sendStatus}
                  onSuccess={()=> openSnackbar("expense sent successfully")}
                >
                {t('sendExpense')}
                </ProgressButton> */}

          <ProgressButton
            onClick={createAndDownloadPdf}
            state={downloadStatus} style={{ maxWidth: '300px' }}>
            {t('downloadPdf')}
          </ProgressButton>

          <button
            className={styles.btn}
            onClick={() => editExpense(expenseData?._id)}
            style={{ maxWidth: '300px' }}
          >
            <BorderColorIcon style={iconSize}
            />
            {t('edit')}
          </button>

          <button
            // disabled={status === 'Paid' ? true : false}
            className={styles.btn}
            onClick={() => setOpen((prev) => !prev)}
            style={{ maxWidth: '300px' }}
          >
            <MonetizationOnIcon style={iconSize} />
            {t('recordPayment')}
          </button>
        </div>
      )}

      {/* {expense?.paymentRecords.length !== 0 && (
        <expensehistory expenseRecords={expenseData?.paymentRecords} />
      )} */}

      <ExpenseModal open={open} setOpen={setOpen} expense={expense} />
      <div className={styles.expenseLayout}>
        <Container className={classes.headerContainer}>

          <Grid container justifyContent="space-between" style={{ padding: '30px 0px' }}>
            {!expense?.creator?.includes(user?.result?._id || user?.result?.googleId) ?
              (
                <Grid item>
                </Grid>
              )
              : (
                <Grid item onClick={() => navigate('/settings')} style={{ cursor: 'pointer' }}>
                  {company?.logo ? <img src={company?.logo} alt="Logo" className={styles.logo} />
                    :
                    <h2>{company?.name}</h2>
                  }
                </Grid>
              )}
            <Grid item style={{ marginRight: 40, textAlign: 'right' }}>
              <Typography style={{ lineSpacing: 1, fontSize: 45, fontWeight: 700, color: 'gray' }} >{Number(total - totalAmountReceived) <= 0 ? t('receipt') : t('invoice')}</Typography>
              <Typography variant="overline" style={{ color: 'gray' }} >No: </Typography>
              <Typography variant="body2">{expenseData?.invoiceNumber}</Typography>
            </Grid>
          </Grid >
        </Container>
        <Divider />
        <Container>
          <Grid container justifyContent="space-between" style={{ marginTop: '40px' }} >
            <Grid item>
              <Container>
                <Typography variant="overline" style={{ color: 'gray' }} gutterBottom>{t('from')}</Typography>
                <Typography variant="subtitle2" gutterBottom>{expense?.provider.name}</Typography>
                {/* <Typography variant="body2" >{expense?.provider?.email}</Typography> */}
                <Typography variant="body2" >{expense?.provider?.phone}</Typography>
                <Typography variant="body2">{expense?.provider?.address}</Typography>
              </Container>
              {/* {expense?.creator?.includes(user?.result._id) && ( */}
              <Container style={{ marginBottom: '20px' }}>
                <Typography variant="overline" style={{ color: 'gray', paddingRight: '3px' }} gutterBottom>{t('billTo')}</Typography>
                <Typography variant="subtitle2">{user?.userProfile?.businessName || user?.result?.name}</Typography>
                {/* <Typography variant="body2">{user?.userProfile?.email}</Typography> */}
                <Typography variant="body2">{user?.userProfile?.phoneNumber}</Typography>
                <Typography variant="body2" gutterBottom>{user?.userProfile?.contactAddress}</Typography>
              </Container>
              {/* )} */}
            </Grid>

            <Grid item style={{ marginRight: 20, textAlign: 'right' }}>
              <Typography variant="overline" style={{ color: 'gray' }} gutterBottom>{t('status')}</Typography>
              <Typography variant="h6" gutterBottom style={{ color: checkStatus() }}>{totalAmountReceived >= total ? t('paid') : t('unpaid')}</Typography>
              <Typography variant="overline" style={{ color: 'gray' }} gutterBottom>{t('date')}</Typography>
              <Typography variant="body2" gutterBottom>{moment().format("MMM Do YYYY")}</Typography>
              <Typography variant="overline" style={{ color: 'gray' }} gutterBottom>{t('dueDate')}</Typography>
              <Typography variant="body2" gutterBottom>{selectedDate ? moment(selectedDate).utc().format("MMM Do YYYY") : ''}</Typography>
              <Typography variant="overline" gutterBottom>{t('priceAmount')}</Typography>
              <Typography variant="h6" gutterBottom>{currency} {toCommas(total)}</Typography>
            </Grid>
          </Grid>
        </Container>

        <form>
          <div>

            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>{t('item')}</TableCell>
                    <TableCell >{t('quantity')}</TableCell>
                    <TableCell>{t('price')}</TableCell>
                    <TableCell >{t('discount')}</TableCell>
                    <TableCell >{t('totalPrice')}</TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {expenseData?.items?.map((itemField, index) => (
                    <TableRow key={index}>
                      <TableCell scope="row" style={{ width: '40%' }}> <InputBase style={{ width: '100%' }} outline="none" sx={{ ml: 1, flex: 1 }} type="text" name="itemName" value={itemField.itemName} placeholder={t('itemDesc')} readOnly /> </TableCell>
                      <TableCell align="right"> <InputBase sx={{ ml: 1, flex: 1 }} type="number" name="quantity" value={itemField?.quantity} placeholder="0" readOnly /> </TableCell>
                      <TableCell align="right"> <InputBase sx={{ ml: 1, flex: 1 }} type="number" name="unitPrice" value={itemField?.unitPrice} placeholder="0" readOnly /> </TableCell>
                      <TableCell align="right"> <InputBase sx={{ ml: 1, flex: 1 }} type="number" name="discount" value={itemField?.discount} readOnly /> </TableCell>
                      <TableCell align="right"> <InputBase sx={{ ml: 1, flex: 1 }} type="number" name="amount" value={(itemField?.quantity * itemField.unitPrice) - (itemField.quantity * itemField.unitPrice) * itemField.discount / 100} readOnly /> </TableCell>


                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div className={styles.addButton}>
            </div>
          </div>

          <div className={styles.expenseSummary}>
            <div className={styles.summary}>{t('invoiceSummary')}</div>
            <div className={styles.summaryItem}>
              <p>Subtotal:</p>
              <h4>{subTotal}</h4>
            </div>
            <div className={styles.summaryItem}>
              <p>{`VA(${rates}%):`}</p>
              <h4>{vat}</h4>
            </div>
            <div className={styles.summaryItem}>
              <p>Total</p>
              <h4>{currency} {toCommas(total)}</h4>
            </div>

            <div className={styles.summaryItem}>
              <p>{t('amountPaid')}</p>
              <h4>{currency} {toCommas(totalAmountReceived)}</h4>
            </div>

            <div className={styles.summaryItem}>
              <p>Balance Final</p>
              <h4 style={{ color: "black", fontSize: "18px", lineHeight: "8px" }}>{currency} {toCommas(total - totalAmountReceived)}</h4>
            </div>

          </div>

          <div className={styles.note}>
            <h4 style={{ marginLeft: '-10px' }}>{t('note')}</h4>
            <p style={{ fontSize: '14px' }}>{expenseData.notes}</p>
          </div>

          {/* <button className={styles.submitButton} type="submit">Save and continue</button> */}
        </form>
      </div>
    </div>

  )
}

export default ExpenseDetails